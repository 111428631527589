import React, {Component} from 'react';
import {connect} from 'react-redux';
import Navigation from "../components/Navigation";
import SidebarNavigation from "./Sidebar-Navigation";
import {Col, Container, Row} from "react-bootstrap";
import {removeActiveSideMenu} from "../helpers";
import SettingSidebar from "../Sidebar/SettingSidebar";
import ProfileSidebar from "../Sidebar/ProfileSidebar";
import NotificationSidebar from "../Sidebar/NotificationSidebar";

class App extends Component {
    constructor() {
        super()
        this.removeOverlay = this.removeOverlay.bind(this);
    }

    async removeOverlay() {
        removeActiveSideMenu()
    }

    render() {
        return (
            <div className="App">
                <Navigation/>
                <section className="main_page">
                    <Container fluid className="padding-50">
                        <Row>
                            <Col md={2} lg={3} xl={3} className="col-xxl-2 p-0">
                                <SidebarNavigation pathname={this.props.location.pathname}/>
                            </Col>
                            <Col md={10} lg={9} xl={9} className="col-xxl-10">
                                {this.props.children}
                            </Col>
                        </Row>
                    </Container>
                </section>
                <SettingSidebar/>
                <ProfileSidebar/>
                <NotificationSidebar/>
                <div className="overlay" onClick={this.removeOverlay}></div>
            </div>
        );
    }
}


export default connect(
    (state, ownProps) => {
        return {...ownProps, user: state.user};
    }
)(App);
