import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {Col, Container, Row} from "react-bootstrap";
import Loader from "../components/Loader";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {
    markRead,
    markAllRead,
    getAllNotifications,
    getNotificationUnreadCount,
} from "../actions/general";
import moment from "moment";
import {removeActiveSideMenu} from "../helpers";

class NotificationSidebar extends Component {
    constructor() {
        super();
    }

    async handleremovelink() {
        await removeActiveSideMenu();
    }

    markAllRead(token) {
        this.props.markAllRead(token);
        //   this.props.getAllNotifications(token);
        //   this.props.getNotificationUnreadCount(token);
    }

    markNotificationRead(token, id) {
        this.props.markRead(token, id);
        this.props.getAllNotifications(token);
        this.props.getNotificationUnreadCount(token);
        this.handleremovelink();
    }

    render() {
        let notification = this.props.notifications || null;
        let user = this.props.user || null;
        if (user == null || notification == null) {
            return (
                <section className="main_container ">
                    <ScrollToTopOnMount/>
                    <Container fluid className="bg_color paddingr-0 pt-lg-5">
                        <Row>
                            <Col xs={12} md={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }

        let notificationElement = notification.data.map((item, index) => {
            return (
                <li className={item.read_at != null ? "" : "active"} key={index}>
                    <div className="title">{item.title}</div>
                    <p>{item.message}</p>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="mintus">{moment(item.created_at).fromNow()}</div>
                        <span
                            onClick={() =>
                                this.markNotificationRead(user.access.token, item.notifiable_id)
                            }
                        >
              Mark Read
            </span>
                    </div>
                </li>
            );
        });

        return (
            <div>
                <section
                    className="sidebar_panel notification_list"
                    id="notification_panel"
                >
                    <div className="header-box d-flex flex-row justify-content-between">
                        <div>
                            <h3>Notification</h3>
                        </div>
                        <div>
              <span onClick={() => this.markAllRead(user.access.token)}>
                Mark All as Read
              </span>
                        </div>
                    </div>
                    <ul>{notification.data.length > 0 ? notificationElement : <li>No notifications</li>}</ul>
                </section>
            </div>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            notifications: state.general.notifications,
            unread_notifications: state.general.unread_notifications,
        };
    },
    {markAllRead, getAllNotifications, getNotificationUnreadCount, markRead}
)(NotificationSidebar);
