import React, {Component} from 'react';
import CanvasJSReact from '../../assets/canvasjs.react';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import {Col, Container, Row} from "react-bootstrap";
import Loader from '../components/Loader';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class DoughnutChart extends Component {
    constructor(){
        super();
    }
    render() {

        let chartData = this.props.chartData || null;

        if(chartData == null){
            return(
                <section className="main_container ">
                <ScrollToTopOnMount/>
                <Container fluid className="paddingr-0 pt-lg-5">
                    <Row>
                        <Col xs={12} md={12} className="m-auto">
                            <Loader/>
                        </Col>
                    </Row>
                </Container>
            </section>
            )
        }

        let dataPointElement = chartData.map((offer,key)=>{
            return(
                {name: `${offer.title}`, y: offer.percentage, color: `${(offer.background_color == `undefined`) ? `#1E70C6` : offer.background_color}`}
            )
        })
        const options = {
            animationEnabled: true,
            subtitles: [{
                text: `${this.props.offerName}`,
                verticalAlign: "center",
                fontSize: 22,
                dockInsidePlotArea: true,
                fontColor: "#174D9F",
                fontFamily: 'Poppins',
                fontWeight: 600,
                maxWidth: 100
            }],
            data: [{
                type: "doughnut",
                indexLabelPlacement: 'inside',
                radius: "100%",
                innerRadius: "50%",
                indexLabelFontFamily: 'Poppins',
                indexLabelFontWeight: 400,
                indexLabelOrientation: "vertical",
                indexLabelTextAlign: 'center',
                showInLegend: false,
                indexLabelFontSize: 14,
                indexLabelFontColor: '#ffffff',
                indexLabel: "{y}",
                yValueFormatString: "###'%'",
                dataPoints: dataPointElement
            }]
        }

        return (
            <div className="resposive_issue">
                <CanvasJSChart options={options}/>
            </div>
        );
    }
}

export default DoughnutChart;