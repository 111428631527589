import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { deleteOffer } from "../actions/offer";

class DeletePopup extends Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete(e) {
    e.preventDefault();
    this.props.deleteOffer(
      this.props.id,
      this.props.user.access.token,
      this.props.draft
    );
  }

  render() {
    return (
      <Modal show={this.props.showHide} centered>
        <Modal.Body className="bg_white">
          <h3>Delete Offer</h3>
          <p>Are you sure you want to delete this Offer?</p>
          <div className="btn-save">
            <NavLink to="#" onClick={this.handleDelete}>
              Yes
            </NavLink>
          </div>
          <div className="cancel_style">
            <NavLink
              to="#"
              onClick={(e) => {
                e.preventDefault();
                this.props.handleModalShowHide();
              }}
            >
              No
            </NavLink>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      user: state.user,
      deleteOfferFailed: state.offer.deleteOfferFailed,
      deleteOfferRequest: state.offer.deleteOfferRequest,
      deleteOfferSuccess: state.offer.deleteOfferSuccess,
      deleteOfferSuccessMsg: state.offer.deleteOfferSuccessMsg,
      error: state.offer.error,
    };
  },
  { deleteOffer }
)(DeletePopup);
