import {
    requestSuccessAction,
    requestFailureAction,
    curl_get,
    curl_post,
    curl_delete,
    curl_put,
} from "./http";
import {BASE_URL, ENDPOINTS, OFFER_STATUSES} from "../constants";
import {gotoDraftOffers, gotoOffers, gotoSearch} from "./router";

// Action Types
const types = {
    CREATE_OFFER: "CREATE_OFFER",
    CREATE_OFFER_REQUEST: "CREATE_OFFER_REQUEST",
    CREATE_OFFER_FAILED: "CREATE_OFFER_FAILED",

    GET_OFFERS: "GET_OFFERS",
    GET_OFFERS_REQUEST: "GET_OFFERS_REQUEST",
    GET_OFFERS_FAILED: "GET_OFFERS_FAILED",

    GET_OFFER: "GET_OFFER",
    GET_OFFER_REQUEST: "GET_OFFER_REQUEST",
    GET_OFFER_FAILED: "GET_OFFER_FAILED",

    DELETE_OFFER: "DELETE_OFFER",
    DELETE_OFFER_REQUEST: "DELETE_OFFER_REQUEST",
    DELETE_OFFER_FAILED: "DELETE_OFFER_FAILED",

    UPDATE_OFFER: "UPDATE_OFFER",
    UPDATE_OFFER_REQUEST: "UPDATE_OFFER_REQUEST",
    UPDATE_OFFER_FAILED: "UPDATE_OFFER_FAILED",

    REPUBLISH_OFFER: "REPUBLISH_OFFER",
    REPUBLISH_OFFER_REQUEST: "REPUBLISH_OFFER_REQUEST",
    REPUBLISH_OFFER_FAILED: "REPUBLISH_OFFER_FAILED",
};
export default types;

export function createOffer(payload, token) {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    return (dispatch) => {
        dispatch({type: types.CREATE_OFFER_REQUEST});
        curl_post(
            dispatch,
            BASE_URL + ENDPOINTS.OFFER,
            payload,
            types.CREATE_OFFER_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.CREATE_OFFER));
                if (data.data.status == OFFER_STATUSES.PUBLISHED) {
                    dispatch(gotoOffers());
                } else {
                    dispatch(gotoDraftOffers());
                }
            },
            config
        );
    };
}

export function getOffers(
    payload = {},
    token,
    pagination = false,
    search = false,
    fresh_call = false
) {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    return (dispatch) => {
        if (search) {
            dispatch(gotoSearch());
        }
        dispatch({type: types.GET_OFFERS_REQUEST, fresh_call: fresh_call});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.BARBER_OFFER,
            payload,
            types.GET_OFFERS_FAILED,
            (data) => {
                dispatch(
                    requestSuccessAction(
                        {pagination: pagination, data: data},
                        types.GET_OFFERS
                    )
                );
            },
            config
        );
    };
}

export function getOffer(id, token) {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    return (dispatch) => {
        dispatch({type: types.GET_OFFER_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.OFFER + "/" + id,
            {},
            types.GET_OFFER_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.GET_OFFER));
            },
            config
        );
    };
}

export function deleteOffer(id, token, draft) {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    return (dispatch) => {
        dispatch({type: types.DELETE_OFFER_REQUEST});
        curl_delete(
            dispatch,
            BASE_URL + ENDPOINTS.OFFER + "/" + id,
            {},
            types.DELETE_OFFER_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.DELETE_OFFER));
                if (draft) {
                    dispatch(gotoDraftOffers());
                } else {
                    dispatch(gotoOffers());
                }
            },
            config
        );
    };
}

export function updateOffer(id, payload, token, published = false, draft = false) {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    return (dispatch) => {
        dispatch({type: types.UPDATE_OFFER_REQUEST});
        curl_put(
            dispatch,
            BASE_URL + ENDPOINTS.OFFER + "/" + id,
            payload,
            types.UPDATE_OFFER_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.UPDATE_OFFER));
                if (draft) {
                    dispatch(gotoDraftOffers());
                } else {
                    dispatch(gotoOffers());
                }

                // if (published) {
                //     dispatch(gotoOffers());
                // }
            },
            config
        );
    };
}

export function republishOffer(payload, token) {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    return (dispatch) => {
        dispatch({type: types.REPUBLISH_OFFER_REQUEST});
        curl_post(
            dispatch,
            BASE_URL + ENDPOINTS.REPUBLISH_OFFER,
            payload,
            types.REPUBLISH_OFFER_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.REPUBLISH_OFFER));
                dispatch(gotoOffers());
            },
            config
        );
    };
}
