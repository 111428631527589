import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ChangePassword from "../modal/ChangePassword";
import { removeActiveSideMenu } from "../helpers";
import LogoutPopup from "../modal/LogoutPopup";

class ProfileSidebar extends Component {
  constructor() {
    super();
    this.state = {
      showHide: false,
      logoutModal: false,
    };
    this.handleModalShowHide = this.handleModalShowHide.bind(this);
    this.handleLogoutModal = this.handleLogoutModal.bind(this);
  }

  async handleModalShowHide() {
    await removeActiveSideMenu();
    this.setState({ showHide: !this.state.showHide });
  }

  async handleremovelink() {
    await removeActiveSideMenu();
  }

  async handleLogoutModal(e) {
    if (e) {
      e.preventDefault();
    }
    await removeActiveSideMenu();
    this.setState({ logoutModal: !this.state.logoutModal });
  }

  render() {
    return (
      <div>
        <section className="sidebar_panel" id="profile_panel">
          <div className="header-box">
            <h3>Profile</h3>
          </div>
          <ul>
            <li>
              <NavLink to="/profile" onClick={() => this.handleremovelink()}>
                <div className="title">My Profile</div>
                <div className="action">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </NavLink>
            </li>
            <li onClick={() => this.handleModalShowHide()}>
              <div className="title">Change Password</div>
              <div className="action">
                <i className="fas fa-chevron-right"></i>
              </div>
            </li>
            <li>
              <NavLink to="/" onClick={this.handleLogoutModal}>
                <div className="title">Log out</div>
                <div className="action">
                  <i className="fas fa-chevron-right"></i>
                </div>
              </NavLink>
            </li>
          </ul>
        </section>
        <ChangePassword
          showHide={this.state.showHide}
          handleModalShowHide={this.handleModalShowHide}
        />
        <LogoutPopup
          showHide={this.state.logoutModal}
          handleLogoutModal={this.handleLogoutModal}
        />
      </div>
    );
  }
}

export default ProfileSidebar;
