import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form'
import Button from "react-bootstrap/Button";

class ContactUs extends Component {
    render() {
        return (
            <div>
                Contact Us
            </div>
        );
    }
}

export default ContactUs;