import {combineReducers} from 'redux-loop';
import {connectRouter} from 'connected-react-router'

import user from "./user";
import general from "./general";
import offer from "./offer";
import dashboard from './dashboard';


const reducer = (history) => combineReducers({
    router: connectRouter(history),
    // ...add Other Reducers Below
    user,
    general,
    offer,
    dashboard
});

export default reducer;