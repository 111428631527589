import React, {Component} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Loader from "../components/Loader";
import {OFFER_STATUSES} from "../constants";
import connect from "react-redux/es/connect/connect";
import {getOffers} from "../actions/offer";
import {
    getAllNotifications,
    getNotificationUnreadCount,
} from "../actions/general";
import {getFormatedDate} from "../helpers";

class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 1,
            load_more: true,
        };
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    componentDidMount() {
        document.addEventListener("scroll", this.trackScrolling);
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.trackScrolling);
    }

    componentWillMount() {
        this.props.getAllNotifications(this.props.user.access.token);
        this.props.getNotificationUnreadCount(this.props.user.access.token);
    }

    trackScrolling = () => {
        const wrappedElement = document.getElementById("offer-listing");
        if (wrappedElement && this.isBottom(wrappedElement)) {
            let offers = this.props.offers || null;
            if (offers && this.state.load_more) {
                this.props.getOffers(
                    {
                        offset: this.state.offset,
                    },
                    this.props.user.access.token,
                    true
                );
            }
        }
    };

    componentDidMount() {
        let field = document.getElementById("search-field");
        if (field && field.value == "") {
            this.props.history.push("/");
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let offers = nextProps.offers || null;
        if (!this.props.getOffersSuccess && nextProps.getOffersSuccess) {
            document.addEventListener("scroll", this.trackScrolling);
            if (this.state.offset < offers.lastPage) {
                this.setState({offset: this.state.offset + 1});
            } else {
                this.setState({load_more: false});
            }
        }
    }

    render() {
        let offers = this.props.offers || null;
        if (offers == null) {
            return (
                <section className="main_container">
                    <ScrollToTopOnMount/>
                    <Container fluid className="bg_color paddingr-0">
                        <Row>
                            <Col md={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
        offers = offers.data;
        let offerElement = offers.map((offer, key) => {
            let className =
                offer.is_expire == 0
                    ? "offfer_items drafts"
                    : "offfer_items drafts disable-offer";
            let link =
                offer.status == OFFER_STATUSES.PUBLISHED
                    ? "/offer/" + offer.id
                    : "/draft-offer/" + offer.id;
            return (
                <Col md={6} lg={6} xl={6} className="col-xxl-4" key={key}>
                    <NavLink to={link}>
                        <div
                            className={className}
                            style={{
                                background: "#1d6cc1"
                            }}

                        >

                            <div className="offer_content">
                                <h4>{offer.product_name}</h4>
                                <h3>{offer.title}</h3>
                                <h5>{offer.expire_at ? 'Valid Till ' + getFormatedDate(offer.expire_at) : '&nbsp;'}</h5>
                            </div>
                            <div className="offer_imges">
                                <Image
                                    src={offer.image_url}
                                    alt={offer.title}
                                    className="img-fluid"
                                ></Image>
                            </div>

                            {offer.is_expire == 0 ? null : (
                                <div className="disable-text">Inactive Offers</div>
                            )}
                        </div>
                    </NavLink>
                </Col>
            );
        });
        return (
            <section className="main_container">
                <Container fluid className="bg_color paddingr-0">
                    <Row>
                        <Col md={12} className="m-auto">
                            <h2>Search Result</h2>
                        </Col>
                    </Row>
                </Container>
                <Container
                    fluid
                    className="scroll-container padding-top-20 paddingr-0"
                    id="offer-listing"
                >
                    <Row>
                        <Col md={12}>
                            <Row>
                                {offers.length > 0 ? (
                                    offerElement
                                ) : (
                                    <Col md={12} lg={12} xl={12} className="text-center centered">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="150"
                                            height="150"
                                            viewBox="0 0 27.538 25.244"
                                        >
                                            <g
                                                id="Group_50816"
                                                data-name="Group 50816"
                                                transform="translate(-0.008 -21.33)"
                                            >
                                                <path
                                                    id="Path_8477"
                                                    data-name="Path 8477"
                                                    d="M26.972,35.1a.573.573,0,0,0,.574-.574V31.657a2.3,2.3,0,0,0-2.295-2.295H20.933a2.778,2.778,0,0,1-.271-1.147,2.864,2.864,0,0,1,1.5-2.506.575.575,0,0,0,.223-.789l-1.4-2.442a2.271,2.271,0,0,0-3.115-.839L1.145,31.379a2.3,2.3,0,0,0-.834,3.129l1.446,2.527a.574.574,0,0,0,.794.207,2.819,2.819,0,0,1,1.472-.421,2.856,2.856,0,0,1,2.719,2.019,2.863,2.863,0,0,1-2.719,2,.573.573,0,0,0-.574.574v2.869a2.3,2.3,0,0,0,2.295,2.295H25.251a2.3,2.3,0,0,0,2.295-2.295V41.41a.573.573,0,0,0-.574-.574,2.869,2.869,0,0,1,0-5.737ZM18.454,22.631a1.108,1.108,0,0,1,.852-.115,1.125,1.125,0,0,1,.689.533l1.128,1.97a4,4,0,0,0-1.609,3.2,3.945,3.945,0,0,0,.177,1.147H6.89ZM4.023,35.673a3.939,3.939,0,0,0-1.541.318L1.308,33.938a1.153,1.153,0,0,1,.415-1.567l1.759-1.024a2.266,2.266,0,0,0-.031.31v2.869a.573.573,0,0,0,.574.574,2.859,2.859,0,0,1,2.468,1.431A3.985,3.985,0,0,0,4.023,35.673ZM26.4,41.943v2.336a1.149,1.149,0,0,1-1.147,1.147H11.482v-1a.574.574,0,0,0-1.147,0v1H5.745A1.149,1.149,0,0,1,4.6,44.279V41.943a4.016,4.016,0,0,0,0-7.95V31.657A1.149,1.149,0,0,1,5.745,30.51h4.59V31.8a.574.574,0,0,0,1.147,0V30.51H25.251A1.149,1.149,0,0,1,26.4,31.657v2.336a4.016,4.016,0,0,0,0,7.95Z"
                                                    transform="translate(0)"
                                                    fill="#8492a7"
                                                />
                                                <path
                                                    id="Path_8478"
                                                    data-name="Path 8478"
                                                    d="M192.566,269.337a.573.573,0,0,0-.574.574v1.147a.574.574,0,1,0,1.147,0v-1.147A.573.573,0,0,0,192.566,269.337Z"
                                                    transform="translate(-181.658 -234.668)"
                                                    fill="#8492a7"
                                                />
                                                <path
                                                    id="Path_8479"
                                                    data-name="Path 8479"
                                                    d="M192.566,354.67a.573.573,0,0,0-.574.574v1.147a.574.574,0,1,0,1.147,0v-1.147A.573.573,0,0,0,192.566,354.67Z"
                                                    transform="translate(-181.658 -315.411)"
                                                    fill="#8492a7"
                                                />
                                                <path
                                                    id="Path_8480"
                                                    data-name="Path 8480"
                                                    d="M263.792,234.8a.575.575,0,0,0-.8.115l-6.885,9.18a.574.574,0,1,0,.918.688l6.885-9.18A.573.573,0,0,0,263.792,234.8Z"
                                                    transform="translate(-242.213 -201.879)"
                                                    fill="#8492a7"
                                                />
                                                <path
                                                    id="Path_8481"
                                                    data-name="Path 8481"
                                                    d="M257.713,239.26c.965,0,1.721-1.008,1.721-2.295s-.756-2.295-1.721-2.295-1.721,1.008-1.721,2.295S256.748,239.26,257.713,239.26Zm0-3.442c.234,0,.574.447.574,1.147s-.34,1.147-.574,1.147-.574-.447-.574-1.147S257.479,235.817,257.713,235.817Z"
                                                    transform="translate(-242.215 -201.865)"
                                                    fill="#8492a7"
                                                />
                                                <path
                                                    id="Path_8482"
                                                    data-name="Path 8482"
                                                    d="M343.046,341.337c-.965,0-1.721,1.008-1.721,2.295s.756,2.295,1.721,2.295,1.721-1.008,1.721-2.295S344.012,341.337,343.046,341.337Zm0,3.442c-.234,0-.574-.447-.574-1.147s.339-1.147.574-1.147.574.447.574,1.147S343.28,344.779,343.046,344.779Z"
                                                    transform="translate(-322.959 -302.795)"
                                                    fill="#8492a7"
                                                />
                                            </g>
                                        </svg>
                                        <h2>No results found</h2>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            offers: state.offer.offers,
            getOffersSuccess: state.offer.getOffersSuccess,
            getOffersRequest: state.offer.getOffersRequest,
            error: state.offer.error,
            notifications: state.general.notifications,
            unread_notifications: state.general.unread_notifications,
        };
    },
    {getOffers, getAllNotifications, getNotificationUnreadCount}
)(SearchResult);
