import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import {removeActiveSideMenu} from "../helpers";
import {NavLink} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {getOffers} from "../actions/offer";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import Loader from "react-spinners/PropagateLoader";
import placeholderImg from "../../img/avatar.png";

class Navigation extends Component {
    constructor() {
        super();
        this.state = {
            query: "",
            showClear: false,
        };
        this.SearchClick = this.SearchClick.bind(this);
        this.SideMenuClick = this.SideMenuClick.bind(this);
        this.handleSetting = this.handleSetting.bind(this);
        this.handleProfile = this.handleProfile.bind(this);
        this.handleNotification = this.handleNotification.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.SearchBlur = this.SearchBlur.bind(this);
    }

    handleSearch(e) {
        // if(!)
        e.preventDefault();
        // console.log("value is", e);
        // console.log("value is", e.target);
        this.setState({showClear: true})
        this.props.getOffers(
            {query: this.state.query},
            this.props.user.access.token,
            false,
            true
        );
        document.getElementById("form-search").classList.toggle("active");
    }

    async handleSetting() {
        await removeActiveSideMenu();
        document.body.classList.toggle("body_class");
        document.getElementById("setting_panel").classList.toggle("active");
    }

    async handleProfile() {
        await removeActiveSideMenu();
        document.body.classList.toggle("body_class");
        document.getElementById("profile_panel").classList.toggle("active");
    }

    async handleNotification() {
        await removeActiveSideMenu();
        document.body.classList.toggle("body_class");
        document.getElementById("notification_panel").classList.toggle("active");
    }


    async SearchClick(e) {
        e.preventDefault();
        document.getElementById("form-search").classList.toggle("active");
        document.getElementById("form-search1").classList.toggle("active");
        document.getElementById("div-search").classList.toggle("active");
        document.getElementById("div-search1").classList.toggle("active");
        document.getElementById("search-field").focus();

    }

    async SearchBlur(e) {
        e.preventDefault();
        //document.getElementById("form-search").classList.toggle("active");
        document.getElementById("form-search1").classList.toggle("active");
        document.getElementById("div-search").classList.toggle("active");
        document.getElementById("div-search1").classList.toggle("active");
    }

    async SideMenuClick() {
        await removeActiveSideMenu();
        document.getElementById("sidebar").classList.toggle("active");
        document.body.classList.toggle("body_class");
    }

    render() {
        let user = this.props.user || null;
        let notificationCount = this.props.unread_notifications || null;

        if (user == null || notificationCount == null) {
            return (
                <section className="main_container ">
                    <ScrollToTopOnMount/>
                    <Container fluid className="bg_color paddingr-0 pt-lg-5">
                        <Row>
                            <Col xs={12} md={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }

        return (
            <section className="header">
                <Container fluid>
                    <Row>
                        <Col xs={6} md={4} lg={4}>
                            <div className="figure logo">
                                <NavLink to="/">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 188.695 71.713"
                                    >
                                        <g id="Logo" transform="translate(0 0)">
                                            <g
                                                id="Logo-2"
                                                data-name="Logo"
                                                transform="translate(0 0)"
                                            >
                                                <path
                                                    id="Path_7911"
                                                    data-name="Path 7911"
                                                    d="M454.058,181.356A5.578,5.578,0,0,0,452.2,179a5.66,5.66,0,0,0-3.518-1.138c-2.038,0-3.781,1.2-5.246,3.608l-9.491,15.422-9.455-15.422q-2.236-3.611-5.283-3.608A5.69,5.69,0,0,0,415.7,179a5.206,5.206,0,0,0-2,2.813l-6.784,23.218-16.428-23.344a8.582,8.582,0,0,0-2.581-2.407,6.752,6.752,0,0,0-3.566-1.116,6.926,6.926,0,0,0-3.566,1.116,8.478,8.478,0,0,0-2.617,2.407l-19.852,27.537h11.9l13.963-19.162,8.032,11.172H379.28l-5.8,7.99,32.188.09H416.24l4.04-16.371L429,206.907a5.58,5.58,0,0,0,2.191,1.933,6.33,6.33,0,0,0,3.007.774,5.335,5.335,0,0,0,4.725-2.707l4.113-6.621a84.932,84.932,0,0,1,11.019-18.93"
                                                    transform="translate(-358.312 -157.882)"
                                                    fill="#174d9f"
                                                />
                                                <path
                                                    id="Path_7912"
                                                    data-name="Path 7912"
                                                    d="M508.4,218.658h10.524l-4.709-16.2a65.775,65.775,0,0,0-6.637,12.915Z"
                                                    transform="translate(-415.022 -167.226)"
                                                    fill="#2491eb"
                                                />
                                                <path
                                                    id="Path_7913"
                                                    data-name="Path 7913"
                                                    d="M565.383,181.577a18,18,0,0,0-10.7-3.092H533.4l-6.859,7.99h29.044a5.624,5.624,0,0,1,3.329.959,3.031,3.031,0,0,1,1.354,2.607,3.145,3.145,0,0,1-1.354,2.655,5.427,5.427,0,0,1-3.329,1H528.861v15.765h9.623v-7.774h16.175a18.023,18.023,0,0,0,10.73-3.092,10.287,10.287,0,0,0-.006-17.018"
                                                    transform="translate(-422.227 -158.118)"
                                                    fill="#2491eb"
                                                />
                                                <path
                                                    id="Path_7914"
                                                    data-name="Path 7914"
                                                    d="M633.218,182.7q-5.238-4.211-13.447-4.214H605.118l-6.1,7.99h20.752a9.991,9.991,0,0,1,6.552,2.133,6.74,6.74,0,0,1,2.6,5.452,6.527,6.527,0,0,1-2.576,5.367,10.239,10.239,0,0,1-6.573,2.043h-11.13V190.13h-9.622V209.46h20.752q8.074,0,13.384-4.487a14.164,14.164,0,0,0,5.3-11.277,13.443,13.443,0,0,0-5.24-11"
                                                    transform="translate(-449.764 -158.118)"
                                                    fill="#2491eb"
                                                />
                                                <path
                                                    id="Path_7915"
                                                    data-name="Path 7915"
                                                    d="M500.871,211.429c-6.695-5.525-1.433-22.959,11.756-38.94s29.3-24.455,36-18.93a6.708,6.708,0,0,1,1,1.029,7.143,7.143,0,0,1,1.267,2.655,23.228,23.228,0,0,1,.589,6.565v.1s.511-6.554-1.76-11.792a10.009,10.009,0,0,0-3.243-4.476,9.151,9.151,0,0,0-1-.657,8.773,8.773,0,0,0-2.1-.889l-.088-.025c-.02-.006-.039-.012-.059-.017h0c-9.418-2.591-25.389,7.047-38.023,23.635-14.237,18.7-18.84,39.135-10.286,45.651s27.036-3.35,41.273-22.038c-13.058,15.453-28.743,23.55-35.321,18.124"
                                                    transform="translate(-408.579 -145.639)"
                                                    fill="#2491eb"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </NavLink>
                            </div>
                        </Col>
                        <Col xs={6} md={8} className="mobile_show">
                            <ul className="menu_list">
                                <li>
                                    <div
                                        className="div-search"
                                        id="div-search1"
                                        onClick={this.SearchClick}
                                    >
                                        <i className="fas fa-search"></i>
                                        <i className="fas fa-times"></i>
                                    </div>
                                    <Form
                                        className="form-search"
                                        id="form-search1"
                                        onSubmit={this.handleSearch}
                                        method="GET"
                                    >
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                id="search-field"
                                                type="text"
                                                className="input_style"
                                                name="query"
                                                value={this.state.query}
                                                onChange={(e) => {
                                                    this.setState({
                                                        query: e.target.value,
                                                    });
                                                }}
                                                required
                                            />
                                            <span className="floating-label">Search</span>
                                            <Form.Control
                                                type="submit"
                                                className="submit_style"
                                                value="send"
                                            />
                                        </Form.Group>
                                    </Form>
                                </li>
                                <li className="mobile_show">
                                    <div
                                        className="setting mobile_arrow"
                                        onClick={this.SideMenuClick}
                                    >
                                        <i className="fas fa-bars"></i>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={12} md={8} lg={8} className="padding-50 dekstop_show">
                            <ul className="menu_list">
                                <li>
                                    <div
                                        className="div-search"
                                        id="div-search"
                                        onClick={this.SearchClick}
                                    >
                                        <i className="fas fa-search"></i>
                                    </div>
                                    <Form
                                        className="form-search"
                                        id="form-search"
                                        onSubmit={this.handleSearch}
                                    >
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                id="search-field"
                                                type="text"
                                                className="input_style"
                                                name="query"
                                                value={this.state.query}
                                                onBlur={this.SearchBlur}
                                                onChange={(e) => {
                                                    this.setState({
                                                        query: e.target.value,
                                                        showClear: true,
                                                    });
                                                }}
                                                required
                                            />
                                            <span className="floating-label">Search</span>
                                            <div className="d-flex flex-row justify-content-space-between absolute-fix">
                                                {/*{this.state.query != "" && this.state.showClear && (*/}
                                                {/*<button*/}
                                                {/*className="setting"*/}
                                                {/*style={{background: "transparent"}}*/}
                                                {/*onClick={() => this.setState({query: ""})}*/}
                                                {/*>*/}
                                                {/*<i className="far fa-window-close"></i>*/}
                                                {/*</button>*/}
                                                {/*)}*/}

                                                <Form.Control
                                                    type="submit"
                                                    className="submit_style"
                                                    value="send"/>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </li>
                                <li>
                                    <div
                                        className={
                                            notificationCount && notificationCount.count != 0
                                                ? "notification hasNotifications"
                                                : "notification"
                                        }
                                        onClick={this.handleNotification}
                                    >
                    <span className="notifCount">
                      {notificationCount.count}
                    </span>
                                        <i className="fas fa-bell"></i>
                                    </div>
                                </li>
                                <li>
                                    <div className="setting" onClick={this.handleSetting}>
                                        <i className="fas fa-cog"></i>
                                    </div>
                                </li>
                                <li className="mobile_show">
                                    <div
                                        className="setting mobile_arrow"
                                        onClick={this.SideMenuClick}
                                    >
                                        <i className="fas fa-bars"></i>
                                    </div>
                                </li>
                                <li>
                                    <div className="profile" onClick={this.handleProfile}>
                                        <div className="img_profile">
                                            <Image
                                                src={user.details.image_url}
                                                alt="Logo"
                                                className="img-fluid"
                                                onError={({currentTarget}) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = placeholderImg;
                                                }}
                                            ></Image>
                                        </div>
                                        <div className="profile_name">
                                            {
                                                user.details.full_name.slice(0, 15)
                                            }
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            offers: state.offer.offers,
            unread_notifications: state.general.unread_notifications,
            getOffersSuccess: state.offer.getOffersSuccess,
            getOffersRequest: state.offer.getOffersRequest,
            error: state.offer.error,
        };
    },
    {getOffers}
)(Navigation);
