import React, {Component} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import avatar from "../../img/avatar.png";
import {NavLink} from "react-router-dom";
import {validate} from "../helpers";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Loader from "../components/Loader";
import connect from "react-redux/es/connect/connect";
import {updateStore} from "../actions/user";
import {getBusinessTypes} from "../actions/general";
import {store} from "react-notifications-component";
import {DEFAULT_NOTIFICATION_SETTINGS, ADMIN_EMAIL} from "../constants";
import NumberFormat from "react-number-format";
import {
    getAllNotifications,
    getNotificationUnreadCount,
} from "../actions/general";

class EditProfile extends Component {
    constructor() {
        super();
        this.state = {
            file: null,
            input: {},
            errors: {},
            imageType: false,
            discardBtn: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.updateStoreSuccess && nextProps.updateStoreSuccess) {
            this.setState({showHide: false});
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Success",
                message: nextProps.updateStoreSuccessMsg,
                type: "success",
            });
        }

        if (!this.props.updateStoreFailed && nextProps.updateStoreFailed) {
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Error",
                message: nextProps.error.message,
                type: "danger",
            });
        }
    }

    componentWillMount() {
        this.props.getBusinessTypes();
        this.props.getAllNotifications(this.props.user.access.token);
        this.props.getNotificationUnreadCount(this.props.user.access.token);
    }

    handleChange(event) {
        if (!event.target.files[0].type.match(/image.*/)) {
            this.setState({imageType: true});
        } else {
            this.setState({
                file: URL.createObjectURL(event.target.files[0]),
                imageType: false,
            });
        }
    }

    handleRemove(event) {
        this.setState({
            file: null,
        });
    }

    handleChangeText(event) {
        let input = this.state.input;
        if (
            (event.target.name == "phone" && event.target.value.length > 15) ||
            (event.target.name == "phone" && event.target.value == "e")
        ) {
            return false;
        }
        input[event.target.name] = event.target.value;

        this.setState({
            input,
        });
        let validated = validate(this.state.input);
        this.setState({errors: validated.errors});
    }

    handleSubmit(event) {
        event.preventDefault();
        console.log('phone:', this.state.phone_validation)
        if (!this.state.phone_validation.is_valid) {
            return false;
        }
        const formData = new FormData(event.currentTarget);
        formData.append("phone", this.state.phone);
        this.props.updateStore(
            this.props.user.details.store.id,
            formData,
            this.props.user.access.token
        );
    }

    convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(" ");

        let [hours, minutes] = time.split(":");

        if (hours === "12") {
            hours = "00";
        }

        if (modifier === "PM") {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    };

    render() {

        let user = this.props.user || null;
        // let store_name = this.props.user.details.store.name || null
        let business_types = this.props.business_types || null;

        if (user == null || business_types == null) {
            return (
                <section className="main_container ">
                    <ScrollToTopOnMount/>
                    <Container fluid className="bg_color paddingr-0 pt-lg-5">
                        <Row>
                            <Col xs={12} md={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
        let businessTypeElement = business_types.map((business_type, key) => {
            return (
                <option value={business_type.id} key={key}
                        selected={user.details.hasOwnProperty('store') && user.details.store.business_type_id == business_type.id ? true : false}>
                    {business_type.name}
                </option>
            );
        });
        if (!this.state.phone) {
            let validated = validate({phone: user.details.phone})
            this.setState({phone: user.details.phone, phone_validation: validated})
        }

        return (
            <section className="main_container editProfile-container">
                <Container fluid className="bg_color paddingr-0">
                    <Row>
                        <Col md={12} className="m-auto">
                            <h2>Edit Profile</h2>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="scroll-container padding-top-20 paddingr-0">
                    <Form onSubmit={this.handleSubmit} id="store-form">
                        <Row>
                            <Col md={4} lg={4} xl={4} className="col-xxl-3">
                                <div className="profile_imges">
                                    <div className="input_filess">
                                        <Form.Control
                                            type="file"
                                            accept="image/png, image/gif, image/jpeg"
                                            className="input_file"
                                            name="image"
                                            onChange={this.handleChange}
                                        />
                                        <span>Upload Image</span>
                                    </div>
                                    {this.state.file == null ? (
                                        <Image
                                            src={user.details.image_url}
                                            onError={avatar}
                                            alt="Logo"
                                            className="img-fluid"
                                        ></Image>
                                    ) : (
                                        <Image
                                            src={this.state.file}
                                            alt="Logo"
                                            className="img-fluid"
                                        ></Image>
                                    )}
                                </div>
                                {this.state.imageType && (
                                    <span className="image-error">Please select image only.</span>
                                )}
                            </Col>
                            <Col md={6} lg={8} xl={8} className="col-xxl-7">
                                <Row>
                                    <Col xs={12} md={4}>
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                type="text"
                                                className="input_style"
                                                required
                                                minLength={4}
                                                name="store_name"
                                                defaultValue={user.details.store.name}
                                                maxLength={25}
                                                onChange={() => this.setState({discardBtn: true})}
                                            />
                                            <span className="floating-label">Store Name</span>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                type="text"
                                                className="input_style"
                                                name="name"
                                                required
                                                minLength={4}
                                                defaultValue={user.details.first_name}
                                                maxLength={25}
                                                onChange={() => this.setState({discardBtn: true})}
                                            />
                                            <span className="floating-label">Full Name</span>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Group className="div-form select_div">
                                            <Form.Control
                                                as="select"
                                                required="required"
                                                className="input_style"
                                                // defaultValue={user.details.job_title}
                                                name="job_title"
                                                //  onChange={(e) => this.selectValue(e)}
                                            >

                                                {["lead", "owner", "employee", "manager"].map(
                                                    (title, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                style={{textTransform: "capitalize"}}
                                                                value={title}
                                                                selected={user.details.job_title == title ? true : false}
                                                            >
                                                                {title}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Form.Control>
                                            <span className="floating-label">Job Title</span>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <div className="div-form floating-label">
                      <textarea
                          className="floating-input div-text"
                          name="about"
                          minLength={2}
                          maxLength={500}
                          onChange={() => this.setState({discardBtn: true})}
                      >
                        {user.details.store.about}
                      </textarea>
                                            <span className="highlight"></span>
                                            <label>About Store (500 characters.)</label>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <div className="div-form disabled">
                                            <Form.Control
                                                type="text"
                                                className="input_style"
                                                readonly
                                                value={user.details.address}
                                            />
                                            <span className="floating-label">Location/Address</span>
                                            <a
                                                className="contact-admin"
                                                href={`mailto: ${ADMIN_EMAIL.email}`}
                                            >
                                                Contact Admin for Support
                                            </a>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className="div-form disabled">
                                            <Form.Control
                                                type="text"
                                                className="input_style"
                                                readonly
                                                value={user.details.country}
                                            />
                                            <span className="floating-label">Country</span>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className="div-form disabled">
                                            <Form.Control
                                                type="text"
                                                className="input_style"
                                                readonly
                                                value={user.details.state}
                                            />
                                            <span className="floating-label">State</span>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className="div-form disabled">
                                            <Form.Control
                                                type="text"
                                                className="input_style"
                                                readonly
                                                value={user.details.city}
                                            />
                                            <span className="floating-label">City</span>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <div className="div-form disabled">
                                            <Form.Control
                                                type="text"
                                                className="input_style"
                                                readonly
                                                value={user.details.store.timezone}
                                            />
                                            <span className="floating-label">TimeZone</span>
                                        </div>
                                    </Col>

                                    <Col xs={12} md={6} className="time_pickers">
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="div-form new_csss">
                                                    <Form.Control
                                                        type="time"
                                                        name="opening_time"
                                                        onChange={() => this.setState({discardBtn: true})}
                                                        className="input_style"
                                                        defaultValue={this.convertTime12to24(
                                                            user.details.store.opening_time
                                                        )}
                                                        required
                                                    />
                                                    <span className="floating-label">Opening Time</span>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="div-form new_csss">
                                                    <Form.Control
                                                        type="time"
                                                        onChange={() => this.setState({discardBtn: true})}
                                                        className="input_style"
                                                        name="closing_time"
                                                        defaultValue={this.convertTime12to24(
                                                            user.details.store.closing_time
                                                        )}
                                                        required
                                                    />
                                                    <span className="floating-label">Closing Time</span>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <NumberFormat
                                                className="form-control input_style phone-number"
                                                required
                                                format="(###) ###-####"
                                                defaultValue={
                                                    this.state.phone
                                                        ? this.state.phone
                                                        : user.details.phone
                                                }
                                                onValueChange={(values) => {
                                                    let validated = validate({phone: values.value})

                                                    this.setState({
                                                        phone: values.value,
                                                        discardBtn: true,
                                                        show_phone: values.formattedValue,
                                                        phone_validation: validated
                                                    });
                                                }}
                                            />
                                            <span className="floating-label">Contact Number</span>
                                            <div
                                                className="text-danger">{this.state.hasOwnProperty('phone_validation') ? this.state.phone_validation.errors.phone : ""}</div>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        {/*<Form.Group className="div-form select_div">*/}
                                        {/*<select*/}
                                        {/*className="input_style"*/}
                                        {/*value={user.details.store.business_type.name}*/}
                                        {/*name="business_type_id"*/}
                                        {/*onChange={() => this.setState({discardBtn: true})}*/}
                                        {/*>*/}
                                        {/*{businessTypeElement}*/}
                                        {/*</select>*/}
                                        {/*<span className="floating-label">Type of Business</span>*/}
                                        {/*</Form.Group>*/}

                                        <Form.Group className="div-form select_div">
                                            <Form.Control
                                                as="select"
                                                required="required"
                                                className="input_style"
                                                name="business_type_id"
                                                //  onChange={(e) => this.selectValue(e)}
                                            >
                                                {businessTypeElement}
                                            </Form.Control>
                                            <span className="floating-label">Type of Business</span>
                                        </Form.Group>

                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form mt-md-3">
                                            <Form.Control
                                                onChange={() => this.setState({discardBtn: true})}
                                                type="submit"
                                                //disabled={!this.state.discardBtn ? true : false}
                                                className={`submit_style ${!this.state.discardBtn ? "disabled" : ''}`}
                                                value="Update Profile"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <div
                                            className={
                                                this.state.discardBtn
                                                    ? "div-form mt-md-3 discard_style_active"
                                                    : "div-form mt-md-3 discard_style_in_active"
                                            }
                                        >
                                            <NavLink
                                                style={{textDecoration: "none"}}
                                                to={this.state.discardBtn ? "profile" : "#"}
                                            >
                                                Discard Changes
                                            </NavLink>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            business_types: state.general.business_types,
            error: state.user.error,
            updateStoreRequest: state.user.updateStoreRequest,
            updateStoreFailed: state.user.updateStoreFailed,
            updateStoreSuccess: state.user.updateStoreSuccess,
            updateStoreSuccessMsg: state.user.updateStoreSuccessMsg,
            notifications: state.general.notifications,
            unread_notifications: state.general.unread_notifications,
        };
    },
    {
        getBusinessTypes,
        updateStore,
        getAllNotifications,
        getNotificationUnreadCount,
    }
)(EditProfile);
