import {
    requestSuccessAction,
    requestFailureAction,
    curl_get,
    curl_post,
} from "./http";
import {BASE_URL, ENDPOINTS, PAGES} from "../constants";
import {gotoOffers} from "./router";

// Action Types
const types = {
    CONTACT_US: "CONTACT_US",
    CONTACT_US_REQUEST: "CONTACT_US_REQUEST",
    CONTACT_US_FAILED: "CONTACT_US_FAILED",

    GET_SETTINGS: "GET_SETTINGS",
    GET_SETTINGS_REQUEST: "GET_SETTINGS_REQUEST",
    GET_SETTINGS_FAILED: "GET_SETTINGS_FAILED",

    GET_PAGE: "GET_PAGE",
    GET_PAGE_REQUEST: "GET_PAGE_REQUEST",
    GET_PAGE_FAILED: "GET_PAGE_FAILED",

    RESET_REQUEST_RESPONSE: "RESET_REQUEST_RESPONSE",
    REQUEST_REVIEW_POSTING: "REQUEST_REVIEW_POSTING",

    RESET_DETAILS: "RESET_DETAILS",
    GAME_DETAILS: "GAME_DETAILS",
    NEWS_DETAILS: "NEWS_DETAILS",

    GET_BUSINESS_TYPES: "GET_BUSINESS_TYPES",
    GET_BUSINESS_TYPES_REQUEST: "GET_BUSINESS_TYPES_REQUEST",
    GET_BUSINESS_TYPES_FAILED: "GET_BUSINESS_TYPES_FAILED",

    GET_TIMEZONES: "GET_TIMEZONES",
    GET_TIMEZONES_REQUEST: "GET_TIMEZONES_REQUEST",
    GET_TIMEZONES_FAILED: "GET_TIMEZONES_FAILED",

    GET_COLORS: "GET_COLORS",
    GET_COLORS_REQUEST: "GET_COLORS_REQUEST",
    GET_COLORS_FAILED: "GET_COLORS_FAILED",

    GET_COUNTRIES: "GET_COUNTRIES",
    GET_COUNTRIES_REQUEST: "GET_COUNTRIES_REQUEST",
    GET_COUNTRIES_FAILED: "GET_COUNTRIES_FAILED",

    GET_CITIES: "GET_CITIES",
    GET_CITIES_REQUEST: "GET_CITIES_REQUEST",
    GET_CITIES_FAILED: "GET_CITIES_FAILED",

    GET_STATES: "GET_STATES",
    GET_STATES_REQUEST: "GET_STATES_REQUEST",
    GET_STATES_FAILED: "GET_STATES_FAILED",

    GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
    GET_NOTIFICATIONS_REQUEST: "GET_NOTIFICATIONS_REQUEST",
    GET_NOTIFICATIONS_FAILED: "GET_NOTIFICATIONS_FAILED",

    GET_NOTIFICATION_UNREAD: "GET_NOTIFICATION_UNREAD",
    GET_NOTIFICATION_UNREAD_REQUEST: "GET_NOTIFICATION_UNREAD_REQUEST",
    GET_NOTIFICATION_UNREAD_FAILED: "GET_NOTIFICATION_UNREAD_FAILED",

    MARK_ALL_READ: "MARK_ALL_READ",
    MARK_ALL_READ_REQUEST: "MARK_ALL_READ_REQUEST",
    MARK_ALL_READ_FAILED: "MARK_ALL_READ_FAILED",

    MARK_READ: "MARK_READ",
    MARK_READ_REQUEST: "MARK_READ_REQUEST",
    MARK_READ_FAILED: "MARK_READ_FAILED",
};
export default types;

export function contact(name, email, subject, message) {
    return (dispatch) => {
        dispatch({type: types.CONTACT_US_REQUEST});
        let data = {
            name,
            email,
            subject,
            message,
        };
        curl_post(
            dispatch,
            BASE_URL + ENDPOINTS.CONTACT_US,
            data,
            types.CONTACT_US_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.CONTACT_US));
            }
        );
    };
}

export function getSettings() {
    return (dispatch) => {
        dispatch({type: types.GET_SETTINGS_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.GET_SETTINGS,
            {},
            types.GET_SETTINGS_FAILED,
            (data) => {
                dispatch(requestSuccessAction({data}, types.GET_SETTINGS));
            }
        );
    };
}

export function getPage(slug) {
    return (dispatch) => {
        dispatch({type: types.GET_PAGE_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.GET_PAGE + "/" + slug,
            {},
            types.GET_PAGE_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.GET_PAGE));
            }
        );
    };
}

export function resetRequest(request_type) {
    return (dispatch) => {
        dispatch({type: types.RESET_REQUEST_RESPONSE, request_type});
    };
}

export function resetDetails(details_type, prop) {
    return (dispatch) => {
        dispatch({type: types.RESET_DETAILS, details_type, prop});
    };
}

export function getBusinessTypes() {
    return (dispatch) => {
        dispatch({type: types.GET_BUSINESS_TYPES_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.GET_BUSINESS_TYPES,
            {},
            types.GET_BUSINESS_TYPES_FAILED,
            (data) => {
                dispatch(requestSuccessAction({data}, types.GET_BUSINESS_TYPES));
            }
        );
    };
}

export function getTimezones() {
    return (dispatch) => {
        dispatch({type: types.GET_TIMEZONES_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.GET_TIMEZONES,
            {},
            types.GET_TIMEZONES_FAILED,
            (data) => {
                dispatch(requestSuccessAction({data}, types.GET_TIMEZONES));
            }
        );
    };
}

export function getAllNotifications(token) {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    return (dispatch) => {
        dispatch({type: types.GET_NOTIFICATIONS_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.NOTIFICATIONS,
            "",
            types.GET_NOTIFICATIONS_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.GET_NOTIFICATIONS));
            },
            config
        );
    };
}

export function getNotificationUnreadCount(token) {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    return (dispatch) => {
        dispatch({type: types.GET_NOTIFICATION_UNREAD_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.UNREAD_COUNT,
            "",
            types.GET_NOTIFICATION_UNREAD_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.GET_NOTIFICATION_UNREAD));
            },
            config
        );
    };
}

export function markAllRead(token) {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    return (dispatch) => {
        dispatch({type: types.MARK_ALL_READ_REQUEST});
        curl_post(
            dispatch,
            BASE_URL + ENDPOINTS.MARK_ALL_READ,
            "",
            types.MARK_ALL_READ_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.MARK_ALL_READ));
                curl_get(
                    dispatch,
                    BASE_URL + ENDPOINTS.UNREAD_COUNT,
                    "",
                    types.GET_NOTIFICATION_UNREAD_FAILED,
                    (data) => {
                        dispatch(requestSuccessAction(data, types.GET_NOTIFICATION_UNREAD));
                    },
                    config
                );
                curl_get(
                    dispatch,
                    BASE_URL + ENDPOINTS.NOTIFICATIONS,
                    "",
                    types.GET_NOTIFICATIONS_FAILED,
                    (data) => {
                        dispatch(requestSuccessAction(data, types.GET_NOTIFICATIONS));
                    },
                    config
                );
            },
            config
        );
    };
}

export function markRead(token, id) {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    return (dispatch) => {
        dispatch({type: types.MARK_READ_REQUEST});
        curl_post(
            dispatch,
            BASE_URL + ENDPOINTS.MARK_READ + `/${id}`,
            "",
            types.MARK_READ_FAILED,
            (data) => {
                dispatch(requestSuccessAction(data, types.MARK_READ));
                setTimeout(() => {
                    dispatch(gotoOffers());
                }, 1000);
            },
            config
        );
    };
}

export function getCountries() {
    return (dispatch) => {
        dispatch({type: types.GET_COUNTRIES_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.COUNTRIES,
            {},
            types.GET_COUNTRIES_FAILED,
            (data) => {
                dispatch(requestSuccessAction({data}, types.GET_COUNTRIES));
            }
        );
    };
}

export function getStates(id) {
    return (dispatch) => {
        dispatch({type: types.GET_STATES_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.STATES + id,
            {},
            types.GET_STATES_FAILED,
            (data) => {
                dispatch(requestSuccessAction({data}, types.GET_STATES));
            }
        );
    };
}

export function getCities(id) {
    return (dispatch) => {
        dispatch({type: types.GET_CITIES_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.CITIES + id,
            {},
            types.GET_CITIES_FAILED,
            (data) => {
                dispatch(requestSuccessAction({data}, types.GET_CITIES));
            }
        );
    };
}

export function getColors() {
    return (dispatch) => {
        dispatch({type: types.GET_COLORS_REQUEST});
        curl_get(
            dispatch,
            BASE_URL + ENDPOINTS.GET_COLORS,
            {},
            types.GET_COLORS_FAILED,
            (data) => {
                dispatch(requestSuccessAction({data}, types.GET_COLORS));
            }
        );
    };
}
