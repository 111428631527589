import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {Form, Modal} from "react-bootstrap";
import {validate} from "../helpers";
import connect from "react-redux/es/connect/connect";
import {changePassword, updateStore} from "../actions/user";
import {store} from "react-notifications-component";
import {getBusinessTypes} from "../actions/general";
import {DEFAULT_NOTIFICATION_SETTINGS, VALIDATIONS} from "../constants";
import Loader from "../components/Loader";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_password: "",
            new_password: "",
            confirmation_password: "",
            show_password: false,
            show_password1: false,
            show_password2: false,
            input: {},
            errors: {},
        };
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.handleShowPassword1 = this.handleShowPassword1.bind(this);
        this.handleShowPassword2 = this.handleShowPassword2.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleShowPassword(e) {
        this.setState({show_password: this.state.show_password ? false : true});
    }

    handleShowPassword1(e) {
        this.setState({show_password1: this.state.show_password1 ? false : true});
    }

    handleShowPassword2(e) {
        this.setState({show_password2: this.state.show_password2 ? false : true});
    }

    handleChangeText(event) {
        // alert();
        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            input,
        });
        let validated = validate(this.state.input);

        console.log(validated);
        this.setState({errors: validated.errors});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.passwordSucces && nextProps.passwordSucces) {
            this.props.handleModalShowHide();
            this.setState({showHide: false});
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Success",
                message: nextProps.passwordSuccessMsg,
                type: "success",
            });
        }

        if (!this.props.passwordRequestFailed && nextProps.passwordRequestFailed) {
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Error",
                message: nextProps.error.message,
                type: "danger",
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        this.props.changePassword(formData, this.props.user.access.token);
    }

    render() {
        return (
            <Modal show={this.props.showHide} centered>
                <Modal.Body className="bg_white">
                    <h3>Change Password</h3>
                    <p>
                        Set the new password for your account so you can login and access
                        all the features.
                    </p>
                    <Form id="change-password" onSubmit={this.handleSubmit}>


                        <Form.Group className="div-form">
                            <Form.Control
                                type={this.state.show_password ? "text" : "password"}
                                className={
                                    this.state.errors.current_password
                                        ? "error_danger input_style"
                                        : "input_style"
                                }
                                required
                                name="current_password"
                                id="current_password"
                                maxLength={VALIDATIONS.PASSWORD_MAX}
                                onChange={this.handleChangeText}
                            />
                            <span className="floating-label">Current Password</span>
                            <span className="error">
                {this.state.show_password ? (
                    <i
                        className="far fa-eye"
                        onClick={this.handleShowPassword}
                    ></i>
                ) : (
                    <i
                        className="far fa-eye-slash"
                        onClick={this.handleShowPassword}
                    ></i>
                )}
              </span>
                            <div className="text-danger">{this.state.errors.current_password}</div>
                        </Form.Group>


                        <Form.Group className="div-form">
                            <Form.Control
                                type={this.state.show_password1 ? "text" : "password"}
                                className={
                                    this.state.errors.password
                                        ? "error_danger input_style"
                                        : "input_style"
                                }
                                required
                                name="password"
                                id="password"
                                maxLength={VALIDATIONS.PASSWORD_MAX}
                                onChange={this.handleChangeText}
                            />
                            <span className="floating-label">New Password</span>
                            <span className="error">
                {this.state.show_password1 ? (
                    <i
                        className="far fa-eye"
                        onClick={this.handleShowPassword1}
                    ></i>
                ) : (
                    <i
                        className="far fa-eye-slash"
                        onClick={this.handleShowPassword1}
                    ></i>
                )}
              </span>
                            <div className="text-danger">{this.state.errors.password}</div>
                        </Form.Group>


                        <Form.Group className="div-form">
                            <Form.Control
                                type={this.state.show_password2 ? "text" : "password"}
                                className={
                                    this.state.errors.rpassword
                                        ? "error_danger input_style"
                                        : "input_style"
                                }
                                required
                                name="rpassword"
                                id="rpassword"
                                onChange={this.handleChangeText}
                                maxLength={VALIDATIONS.PASSWORD_MAX}
                            />
                            <span className="floating-label">Re-enter Password</span>
                            <span className="error">
                {this.state.show_password2 ? (
                    <i
                        className="far fa-eye"
                        onClick={this.handleShowPassword2}
                    ></i>
                ) : (
                    <i
                        className="far fa-eye-slash"
                        onClick={this.handleShowPassword2}
                    ></i>
                )}
              </span>
                            <div className="text-danger">{this.state.errors.rpassword}</div>
                        </Form.Group>
                        <Form.Group className="div-form form-submit">
                            {this.props.passwordRequest ? (
                                <Loader/>
                            ) : (
                                <Form.Control
                                    type="submit"
                                    className="submit_style"
                                    value="Change Password"
                                />
                            )}
                        </Form.Group>
                    </Form>

                    <div className="cancel_style">
                        <NavLink to="#" onClick={this.props.handleModalShowHide}>
                            Cancel
                        </NavLink>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            error: state.user.error,
            passwordRequest: state.user.passwordRequest,
            passwordRequestFailed: state.user.passwordRequestFailed,
            passwordSucces: state.user.passwordSucces,
            passwordSuccessMsg: state.user.passwordSuccessMsg,
        };
    },
    {changePassword}
)(ChangePassword);
