import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {NavLink} from "react-router-dom";

class DiscardPopup extends Component {

    render() {
        return (
            <Modal show={this.props.showHide} centered>
                <Modal.Body className="bg_white">
                    <h3>Discard Offer</h3>
                    <p>Are you sure you want to discard this Offer?</p>
                    <div className="btn-save">
                        <NavLink to="/my-offers" onClick={(e) => {
                            e.preventDefault()
                            this.props.handleModalShowHide(true)
                        }}>Yes</NavLink>
                    </div>
                    <div className="cancel_style">
                        <NavLink to="#" onClick={(e) => {
                            e.preventDefault()
                            this.props.handleModalShowHide(false)
                        }}>No</NavLink>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default DiscardPopup;