import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import store from "./app/store";
import App from './App';

import * as serviceWorker from './serviceWorker';
import ReactNotification from "react-notifications-component";
import 'react-notifications-component/dist/theme.css'

const rootElement = document.getElementById('root')
ReactDOM.render(
    <Provider store={store}>
        <App/>
        <div className="app-container">
            <ReactNotification/>
        </div>
    </Provider>,
    rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
