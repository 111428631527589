import {requestSuccessAction, curl_get} from "./http";
import {BASE_URL, ENDPOINTS} from "../constants";

// Action Types
const types = {
    DASHBOARD: "DASHBOARD",
    DASHBOARD_REQUEST: "DASHBOARD_REQUEST",
    DASHBOARD_FAILED: "DASHBOARD_FAILED",
};
export default types;

//GET DASHBOARD DATA
export function dashboardDetails(token) {
    let config = {
        headers: {
            'Authorization': "Bearer " + token,
        }
    };
    return (dispatch) => {
        dispatch({type: types.DASHBOARD_REQUEST});
        curl_get(dispatch, BASE_URL + ENDPOINTS.DASHBOARD_DATA,'', types.DASHBOARD_FAILED, (data) => {
            dispatch(requestSuccessAction(data, types.DASHBOARD))
        }, config);
    }
}

