import React, {Component} from "react";
import {Col, Container, Dropdown, Image, Row} from "react-bootstrap";
import Timer from "./Timer";
import {NavLink} from "react-router-dom";
import DeletePopup from "../modal/DeletePopup";
import Loader from "../components/Loader";
import connect from "react-redux/es/connect/connect";
import {getOffer, updateOffer} from "../actions/offer";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {
    DEFAULT_NOTIFICATION_SETTINGS,
    OFFER_TYPES,
    OFFER_ACTIVITY_STATUS, OFFER_STATUSES,
} from "../constants";
import {
    getAllNotifications,
    getNotificationUnreadCount,
} from "../actions/general";
import {getFormatedDate} from "../helpers";
import {store} from "react-notifications-component";
import moment from "moment";
import RepublishPopup from "../modal/RepublishPopup";

class OffersDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHide: false,
            republishModal: false,
        };
        this.handleModalShowHide = this.handleModalShowHide.bind(this);
        this.handleModalRepublish = this.handleModalRepublish.bind(this);
    }

    componentWillMount() {
        let id = this.props.match.params.id;
        this.props.getOffer(id, this.props.user.access.token);
        this.props.getAllNotifications(this.props.user.access.token);
        this.props.getNotificationUnreadCount(this.props.user.access.token);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.updateOfferSuccess && nextProps.updateOfferSuccess) {
            this.setState({showHide: false});
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Success",
                message: nextProps.updateOfferSuccessMsg,
                type: "success",
            });
        }
        if (!this.props.updateOfferFailed && nextProps.updateOfferFailed) {
            this.setState({showHide: false});
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Error",
                message: nextProps.error.message,
                type: "danger",
            });
        }
        if (!this.props.deleteOfferSuccess && nextProps.deleteOfferSuccess) {
            this.setState({showHide: false});
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Success",
                message: nextProps.deleteOfferSuccessMsg,
                type: "success",
            });
        }
        if (!this.props.deleteOfferFailed && nextProps.deleteOfferFailed) {
            this.setState({showHide: false});
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Error",
                message: nextProps.error.message,
                type: "danger",
            });
        }
    }

    handleModalShowHide() {
        this.setState({showHide: !this.state.showHide});
    }

    handleModalRepublish() {
        this.setState({republishModal: !this.state.republishModal});
    }

    activeStatusUpdate(event) {
        this.props.updateOffer(
            this.props.match.params.id,
            {is_active: event.target.value},
            this.props.user.access.token
        );
    }

    render() {
        let offer = this.props.offer_details || null;
        if (offer == null) {
            return (
                <section className="main_container">
                    <ScrollToTopOnMount/>
                    <Container fluid className="bg_color paddingr-0">
                        <Row>
                            <Col md={12} lg={12} xl={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
        var dateFormat = 'YYYY-MM-DD';
        var testDateUtc = moment.utc(offer.expire_at);
        var localDate = testDateUtc.local();
        let expire_date = localDate.format(dateFormat)
        if (!offer.hasOwnProperty("id")) {
            return (
                <section className="main_container">
                    <ScrollToTopOnMount/>
                    <Container fluid className="bg_color paddingr-0">
                        <Row>
                            <Col
                                md={12}
                                lg={12}
                                xl={12}
                                className="m-auto text-center centered"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="150"
                                    height="150"
                                    viewBox="0 0 27.538 25.244"
                                >
                                    <g
                                        id="Group_50816"
                                        data-name="Group 50816"
                                        transform="translate(-0.008 -21.33)"
                                    >
                                        <path
                                            id="Path_8477"
                                            data-name="Path 8477"
                                            d="M26.972,35.1a.573.573,0,0,0,.574-.574V31.657a2.3,2.3,0,0,0-2.295-2.295H20.933a2.778,2.778,0,0,1-.271-1.147,2.864,2.864,0,0,1,1.5-2.506.575.575,0,0,0,.223-.789l-1.4-2.442a2.271,2.271,0,0,0-3.115-.839L1.145,31.379a2.3,2.3,0,0,0-.834,3.129l1.446,2.527a.574.574,0,0,0,.794.207,2.819,2.819,0,0,1,1.472-.421,2.856,2.856,0,0,1,2.719,2.019,2.863,2.863,0,0,1-2.719,2,.573.573,0,0,0-.574.574v2.869a2.3,2.3,0,0,0,2.295,2.295H25.251a2.3,2.3,0,0,0,2.295-2.295V41.41a.573.573,0,0,0-.574-.574,2.869,2.869,0,0,1,0-5.737ZM18.454,22.631a1.108,1.108,0,0,1,.852-.115,1.125,1.125,0,0,1,.689.533l1.128,1.97a4,4,0,0,0-1.609,3.2,3.945,3.945,0,0,0,.177,1.147H6.89ZM4.023,35.673a3.939,3.939,0,0,0-1.541.318L1.308,33.938a1.153,1.153,0,0,1,.415-1.567l1.759-1.024a2.266,2.266,0,0,0-.031.31v2.869a.573.573,0,0,0,.574.574,2.859,2.859,0,0,1,2.468,1.431A3.985,3.985,0,0,0,4.023,35.673ZM26.4,41.943v2.336a1.149,1.149,0,0,1-1.147,1.147H11.482v-1a.574.574,0,0,0-1.147,0v1H5.745A1.149,1.149,0,0,1,4.6,44.279V41.943a4.016,4.016,0,0,0,0-7.95V31.657A1.149,1.149,0,0,1,5.745,30.51h4.59V31.8a.574.574,0,0,0,1.147,0V30.51H25.251A1.149,1.149,0,0,1,26.4,31.657v2.336a4.016,4.016,0,0,0,0,7.95Z"
                                            transform="translate(0)"
                                            fill="#8492a7"
                                        />
                                        <path
                                            id="Path_8478"
                                            data-name="Path 8478"
                                            d="M192.566,269.337a.573.573,0,0,0-.574.574v1.147a.574.574,0,1,0,1.147,0v-1.147A.573.573,0,0,0,192.566,269.337Z"
                                            transform="translate(-181.658 -234.668)"
                                            fill="#8492a7"
                                        />
                                        <path
                                            id="Path_8479"
                                            data-name="Path 8479"
                                            d="M192.566,354.67a.573.573,0,0,0-.574.574v1.147a.574.574,0,1,0,1.147,0v-1.147A.573.573,0,0,0,192.566,354.67Z"
                                            transform="translate(-181.658 -315.411)"
                                            fill="#8492a7"
                                        />
                                        <path
                                            id="Path_8480"
                                            data-name="Path 8480"
                                            d="M263.792,234.8a.575.575,0,0,0-.8.115l-6.885,9.18a.574.574,0,1,0,.918.688l6.885-9.18A.573.573,0,0,0,263.792,234.8Z"
                                            transform="translate(-242.213 -201.879)"
                                            fill="#8492a7"
                                        />
                                        <path
                                            id="Path_8481"
                                            data-name="Path 8481"
                                            d="M257.713,239.26c.965,0,1.721-1.008,1.721-2.295s-.756-2.295-1.721-2.295-1.721,1.008-1.721,2.295S256.748,239.26,257.713,239.26Zm0-3.442c.234,0,.574.447.574,1.147s-.34,1.147-.574,1.147-.574-.447-.574-1.147S257.479,235.817,257.713,235.817Z"
                                            transform="translate(-242.215 -201.865)"
                                            fill="#8492a7"
                                        />
                                        <path
                                            id="Path_8482"
                                            data-name="Path 8482"
                                            d="M343.046,341.337c-.965,0-1.721,1.008-1.721,2.295s.756,2.295,1.721,2.295,1.721-1.008,1.721-2.295S344.012,341.337,343.046,341.337Zm0,3.442c-.234,0-.574-.447-.574-1.147s.339-1.147.574-1.147.574.447.574,1.147S343.28,344.779,343.046,344.779Z"
                                            transform="translate(-322.959 -302.795)"
                                            fill="#8492a7"
                                        />
                                    </g>
                                </svg>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
        return (
            <section className="main_container">
                <ScrollToTopOnMount/>
                <Container fluid className="bg_color paddingr-0">
                    <Row>
                        <Col md={6} lg={6} xl={4} className="m-auto position-relative">
                            <h2 className="offer-title-bg">{offer.title}</h2>
                        </Col>
                        <Col md={6} lg={6} xl={8} className="m-auto">
                            <div className="dash_btn">
                                {/* <label className="dropdown">Offer Status:</label>
                                <select
                                    className="offer-activity"
                                    value={offer.is_active}
                                    onChange={(e) => this.activeStatusUpdate(e)}
                                >
                                    Offer Status
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </select>*/}
                                {offer.status == OFFER_STATUSES.EXPIRED || offer.is_admin_deleted ?
                                    <NavLink to="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.handleModalRepublish()
                                    }}>
                                        Republish Offer
                                    </NavLink>
                                    : null}
                                {offer.is_active == 0 && (
                                    <NavLink to={"/edit-offer/" + offer.id} className=" edit">
                                        Edit Offer
                                    </NavLink>
                                )}

                                {offer.is_expire == 1 && offer.is_active == 0 && (
                                    <div
                                        className="delete"
                                        onClick={() => this.handleModalShowHide()}
                                    >
                                        <i className=" fas fa-trash-alt"></i>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container
                    fluid
                    className=" scroll-container padding-top-20 paddingr-0 "
                >
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className=" offer_banner">
                                <Image
                                    src={offer.image_url}
                                    alt={offer.title}
                                    className=" img-fluid"
                                ></Image>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div className=" content_details">
                                <h3>{offer.product_name}</h3>
                                <h5>
                                    Offer{" "}
                                    {offer.type == OFFER_TYPES.PERCENTAGE
                                        ? offer.value + "%"
                                        : "$" + offer.value}{" "}
                                    Off
                                </h5>
                                <ul className=" list_details">
                                    <li>
                                        Number of uses:<span>{offer.number_of_uses} Uses</span>
                                    </li>
                                    <li>
                                        Expiration date:{" "}
                                        <span>{moment(offer.expire_at).format("MMM Do, YYYY")}</span>
                                    </li>
                                    {/* <li>Dislike
                                        counter: <span>{offer.dislike_time ? offer.dislike_time + " Hours" : "N/A"}</span>
                                    </li> */}
                                    <li>
                                        Offer reoccurrence:{" "}
                                        <span>
                      {offer.recurrence_time
                          ? offer.recurrence_time + " Hours"
                          : "N/A"}
                    </span>
                                    </li>
                                    <li>
                                        Offer avail time:{" "}
                                        <span>
                      {offer.avail_time ? offer.avail_time + " Hours" : "N/A"}
                    </span>
                                    </li>
                                    <li className="new_offer">
                                        Offer Description:
                                        <span>{offer.description ? offer.description : 'N/A'}</span>
                                    </li>
                                </ul>
                            </div>

                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Timer date={expire_date}/>
                            {offer.qr_url ? (
                                <div className="qr_view">
                                    <h5>QR Code</h5>
                                    <Image
                                        src={offer.qr_url}
                                        alt={offer.title}
                                        className=" img-fluid"
                                    ></Image>
                                </div>
                            ) : (
                                <div className="new_offer1">
                                    <h5>Redeem message</h5>
                                    <p>{offer.redeem_message}</p>
                                </div>
                            )}
                        </Col>
                        <Col xs={12} sm={12} md={{span: 8, offset: 4}} lg={{span: 8, offset: 4}}
                             xl={{span: 8, offset: 4}}>
                            <hr/>
                            <Row>
                                <Col lg={12} md={12}>
                                    <Row className="mb-5">
                                        <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                                            <div className="stat-box green-color mx-auto text-center">
                                                <i className=" fas fa-heart"></i>
                                                <div className=" values">{offer.total_likes}</div>
                                                <div className=" namee">Marked Favorite</div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                                            <div className="stat-box blue-color mx-auto text-center">
                                                <i className=" fas fa-users"></i>
                                                <div className=" values">{offer.total_uses}</div>
                                                <div className=" namee">Uses</div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                                            <div className="stat-box orange-color mx-auto text-center">
                                                <i className=" fas fa-thumbs-down"></i>
                                                <div className=" values">{offer.total_dislikes}</div>
                                                <div className=" namee">Thumbs Down</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <DeletePopup
                        id={offer.id}
                        draft={false}
                        showHide={this.state.showHide}
                        handleModalShowHide={this.handleModalShowHide}
                    />
                    <RepublishPopup
                        showHide={this.state.republishModal}
                        offerId={offer.id}
                        handleModalShowHide={this.handleModalRepublish}
                    />
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            offer_details: state.offer.offer_details,
            getOfferSuccess: state.offer.getOfferSuccess,
            getOfferRequest: state.offer.getOfferRequest,
            getOfferFailed: state.offer.getOfferFailed,
            error: state.offer.error,
            notifications: state.general.notifications,
            unread_notifications: state.general.unread_notifications,
            deleteOfferFailed: state.offer.deleteOfferFailed,
            deleteOfferRequest: state.offer.deleteOfferRequest,
            deleteOfferSuccess: state.offer.deleteOfferSuccess,
            deleteOfferSuccessMsg: state.offer.deleteOfferSuccessMsg,
            updateOfferRequest: state.offer.updateOfferRequest,
            updateOfferFailed: state.offer.updateOfferFailed,
            updateOfferSuccess: state.offer.updateOfferSuccess,
            updateOfferSuccessMsg: state.offer.updateOfferSuccessMsg,
        };
    },
    {getOffer, updateOffer, getAllNotifications, getNotificationUnreadCount}
)(OffersDetails);
