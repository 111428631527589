import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {NavLink} from "react-router-dom";

class PublishPopup extends Component {

    render() {
        return (
            <Modal show={this.props.showHide} centered>
                <Modal.Body className="bg_white">
                    <h3>Are you sure to Publish Offer</h3>
                    <p>It can not be removed until the expiration date</p>
                    <div className="btn-save">
                        <NavLink to="#" onClick={(e) => {
                            e.preventDefault()
                            this.props.submitFormClick()
                        }}>Publish</NavLink>
                    </div>
                    <div className="cancel_style">
                        <NavLink to="#" onClick={(e) => {
                            e.preventDefault()
                            this.props.handleModalShowHide()
                        }}>Cancel</NavLink>
                    </div>
                </Modal.Body>
            </Modal>

        );
    }
}

export default PublishPopup;