import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import DoughnutChart from "../charts/Doughnut Chart";
import {NavLink} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {dashboardDetails} from "../actions/dashboard";
import {
    getAllNotifications,
    getNotificationUnreadCount,
} from "../actions/general";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Loader from "../components/Loader";

class Home extends Component {
    constructor() {
        super();
        this.state = {
            active_offers: [],
        };
    }

    componentWillMount() {
        this.props.dashboardDetails(this.props.user.access.token);
        this.props.getAllNotifications(this.props.user.access.token);
        this.props.getNotificationUnreadCount(this.props.user.access.token);
    }

    render() {
        let loader = true;
        let dashboardData = this.props.dashboard || null;

        if (dashboardData == null) {
            return (
                <section className="main_container ">
                    <ScrollToTopOnMount/>
                    <Container fluid className="bg_color paddingr-0 pt-lg-5">
                        <Row>
                            <Col xs={12} md={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
        let data = dashboardData.dashboard || null;
        if (data == null) {
            return (
                <section className="main_container ">
                    <ScrollToTopOnMount/>
                    <Container fluid className="bg_color paddingr-0 pt-lg-5">
                        <Row>
                            <Col xs={12} md={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
        let active_offers = data.active_offers || "";
        let inactive_offers = data.inactive_offers;
        let top_offers = data.top_offers;

        let activeOfferElement = active_offers.map((offer, key) => {
            return (
                <li key={key}>
                    <i
                        className="fas fa-circle"
                        style={{color: `${offer.background_color}`}}
                    ></i>
                    {offer.title}
                </li>
            );
        });
        let inactiveOfferElement = inactive_offers.map((offer, key) => {
            return (
                <li key={key}>
                    <i
                        className="fas fa-circle"
                        style={{color: `${offer.background_color}`}}
                    ></i>
                    {offer.title}
                </li>
            );
        });
        let topOfferElement = top_offers.map((offer, key) => {
            return (
                <li key={key}>
                    <i
                        className="fas fa-circle"
                        style={{color: `${offer.background_color}`}}
                    ></i>
                    {offer.title}
                </li>
            );
        });

        return (
            <section className="main_container">
                <Container fluid className="bg_color paddingr-0">
                    <Row>
                        <Col xs={6} md={6} className="m-auto">
                            <h2 className="dash-title">Dashboard</h2>
                        </Col>
                        <Col xs={6} md={6} className="m-auto">
                            <div className="dash_btn">
                                <NavLink to="create-offer">
                                    +<span> Create a New Offer</span>
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="scroll-container padding-top-20 home">
                    <Row>
                        {top_offers && top_offers == "" ? (
                            <Col md={12}>
                                <div className="dash_bg_no_offer">
                                    <h3>My Top Offers</h3>
                                    <Row>
                                        <Col
                                            md={12}
                                            className="m-auto d-flex flex-row justify-content-center"
                                        >
                                            <h2 className="d-flex justify-content-center mt-5">
                                                No Top Offers
                                            </h2>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        ) : (
                            <Col md={12}>
                                <div className="dash_bg">
                                    <h3>My Top Offers</h3>
                                    <Row>
                                        <Col md={6} className="m-auto">
                                            <ul className="chart_namelist">{topOfferElement}</ul>
                                        </Col>
                                        <Col md={6}>
                                            <DoughnutChart
                                                chartData={top_offers}
                                                offerName="Top Offers"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col md={12} lg={12} xl={6} className="col-100">
                            {active_offers && active_offers == "" ? (
                                <div className="dash_bg_no_offer active_offer">
                                    <h3>Active Offers</h3>
                                    <Row>
                                        <Col md={12} className="m-auto">
                                            <h2 className="d-flex justify-content-center mt-5">
                                                No Active Offers
                                            </h2>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div className="dash_bg active_offer">
                                    <h3>Active Offers</h3>
                                    <Row>
                                        <Col md={6} lg={6} xl={5} className="col-xxl-5 m-auto">
                                            <ul className="chart_namelist">{activeOfferElement}</ul>
                                        </Col>
                                        <Col md={6} lg={6} xl={6} className="col-xxl-7">
                                            <DoughnutChart
                                                chartData={active_offers}
                                                offerName="Active Offers"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>

                        <Col md={12} lg={12} xl={6} className="col-100">
                            {inactive_offers && inactive_offers == "" ? (
                                <div className="dash_bg_no_offer active_offer">
                                    <h3>InActive Offers</h3>
                                    <Row>
                                        <Col md={12} className="m-auto">
                                            <h2 className="d-flex justify-content-center mt-5">
                                                No InActive Offers
                                            </h2>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div className="dash_bg active_offer">
                                    <h3>InActive Offers</h3>
                                    <Row>
                                        <Col md={6} lg={6} xl={5} className="col-xxl-5 m-auto">
                                            <ul className="chart_namelist">{inactiveOfferElement}</ul>
                                        </Col>
                                        <Col md={6} lg={6} xl={6} className="col-xxl-7">
                                            <DoughnutChart
                                                chartData={inactive_offers}
                                                offerName="InActive Offers"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            dashboard: state.dashboard,
            error: state.dashboard.error,
            user: state.user,
            notifications: state.general.notifications,
            unread_notifications: state.general.unread_notifications,
            dashboardRequest: state.dashboard.dashboardRequest,
            dashboardFailed: state.dashboard.dashboardFailed,
            dashboardSuccess: state.dashboard.dashboardSuccess,
            dashboardMsg: state.dashboard.dashboardMsg,
        };
    },
    {dashboardDetails, getAllNotifications, getNotificationUnreadCount}
)(Home);
