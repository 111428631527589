import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {NavLink} from "react-router-dom";
import OtpInput from "react-otp-input";
import connect from "react-redux/es/connect/connect";
import {verifyEmail, resendOtp} from "../actions/user";
import {getCountries} from "../actions/general";
import {store} from "react-notifications-component";
import {DEFAULT_NOTIFICATION_SETTINGS} from "../constants";
import Loader from "../components/Loader";

class VerifyOtp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: "",
            errors: {},
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (otp) => this.setState({otp});

    componentWillMount() {
        if (!this.props.user.tempEmail) {
            let route = "/login";
            window.location.assign(route);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            !this.props.verifyEmailCodeSuccess &&
            nextProps.verifyEmailCodeSuccess
        ) {
            this.setState({showHide: false});
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Success",
                message: nextProps.verifyEmailCodeMsg,
                type: "success",
            });
        }

        if (!this.props.verifyEmailCodeFailed && nextProps.verifyEmailCodeFailed) {
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Error",
                message: nextProps.error.message,
                type: "danger",
            });
        }
        if (!this.props.resendOtpSuccess && nextProps.resendOtpSuccess) {
            this.setState({showHide: false});
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Success",
                message: nextProps.resendOtpMsg,
                type: "success",
            });
            var element = document.getElementById("optbtn");
            element.classList.remove('active')
        }

        if (!this.props.resendOtpFailed && nextProps.resendOtpFailed) {
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Error",
                message: nextProps.error.message,
                type: "danger",
            });
        }
    }

    resendOtp() {
        var element = document.getElementById("optbtn");
        element.classList.add("active");
        this.props.resendOtp(this.props.user.tempEmail);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.verifyEmail(
            this.state.otp,
            this.props.user.tempEmail,
            this.props.user.fromForgotScreen
        );
    }


    render() {
        return (
            <section className="account_page">
                <Container fluid>
                    <Row>
                        <Col xs={12} md={7} lg={5} xl={5} className="text-center m-auto">
                            <div className="bg_white thanks ">
                                <div className="figure mb-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="188.695"
                                        height="71.713"
                                        viewBox="0 0 188.695 71.713"
                                    >
                                        <g id="Logo" transform="translate(0 0)">
                                            <g
                                                id="Logo-2"
                                                data-name="Logo"
                                                transform="translate(0 0)"
                                            >
                                                <path
                                                    id="Path_7911"
                                                    data-name="Path 7911"
                                                    d="M454.058,181.356A5.578,5.578,0,0,0,452.2,179a5.66,5.66,0,0,0-3.518-1.138c-2.038,0-3.781,1.2-5.246,3.608l-9.491,15.422-9.455-15.422q-2.236-3.611-5.283-3.608A5.69,5.69,0,0,0,415.7,179a5.206,5.206,0,0,0-2,2.813l-6.784,23.218-16.428-23.344a8.582,8.582,0,0,0-2.581-2.407,6.752,6.752,0,0,0-3.566-1.116,6.926,6.926,0,0,0-3.566,1.116,8.478,8.478,0,0,0-2.617,2.407l-19.852,27.537h11.9l13.963-19.162,8.032,11.172H379.28l-5.8,7.99,32.188.09H416.24l4.04-16.371L429,206.907a5.58,5.58,0,0,0,2.191,1.933,6.33,6.33,0,0,0,3.007.774,5.335,5.335,0,0,0,4.725-2.707l4.113-6.621a84.932,84.932,0,0,1,11.019-18.93"
                                                    transform="translate(-358.312 -157.882)"
                                                    fill="#174d9f"
                                                />
                                                <path
                                                    id="Path_7912"
                                                    data-name="Path 7912"
                                                    d="M508.4,218.658h10.524l-4.709-16.2a65.775,65.775,0,0,0-6.637,12.915Z"
                                                    transform="translate(-415.022 -167.226)"
                                                    fill="#2491eb"
                                                />
                                                <path
                                                    id="Path_7913"
                                                    data-name="Path 7913"
                                                    d="M565.383,181.577a18,18,0,0,0-10.7-3.092H533.4l-6.859,7.99h29.044a5.624,5.624,0,0,1,3.329.959,3.031,3.031,0,0,1,1.354,2.607,3.145,3.145,0,0,1-1.354,2.655,5.427,5.427,0,0,1-3.329,1H528.861v15.765h9.623v-7.774h16.175a18.023,18.023,0,0,0,10.73-3.092,10.287,10.287,0,0,0-.006-17.018"
                                                    transform="translate(-422.227 -158.118)"
                                                    fill="#2491eb"
                                                />
                                                <path
                                                    id="Path_7914"
                                                    data-name="Path 7914"
                                                    d="M633.218,182.7q-5.238-4.211-13.447-4.214H605.118l-6.1,7.99h20.752a9.991,9.991,0,0,1,6.552,2.133,6.74,6.74,0,0,1,2.6,5.452,6.527,6.527,0,0,1-2.576,5.367,10.239,10.239,0,0,1-6.573,2.043h-11.13V190.13h-9.622V209.46h20.752q8.074,0,13.384-4.487a14.164,14.164,0,0,0,5.3-11.277,13.443,13.443,0,0,0-5.24-11"
                                                    transform="translate(-449.764 -158.118)"
                                                    fill="#2491eb"
                                                />
                                                <path
                                                    id="Path_7915"
                                                    data-name="Path 7915"
                                                    d="M500.871,211.429c-6.695-5.525-1.433-22.959,11.756-38.94s29.3-24.455,36-18.93a6.708,6.708,0,0,1,1,1.029,7.143,7.143,0,0,1,1.267,2.655,23.228,23.228,0,0,1,.589,6.565v.1s.511-6.554-1.76-11.792a10.009,10.009,0,0,0-3.243-4.476,9.151,9.151,0,0,0-1-.657,8.773,8.773,0,0,0-2.1-.889l-.088-.025c-.02-.006-.039-.012-.059-.017h0c-9.418-2.591-25.389,7.047-38.023,23.635-14.237,18.7-18.84,39.135-10.286,45.651s27.036-3.35,41.273-22.038c-13.058,15.453-28.743,23.55-35.321,18.124"
                                                    transform="translate(-408.579 -145.639)"
                                                    fill="#2491eb"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h3>Enter 4 Digits Code</h3>
                                <p>Enter the 4 digit code sent to your Email Address.</p>
                                <Form id="verify-otp" onSubmit={this.handleSubmit}>
                                    <Form.Group className="div-form" id="otp">
                                        <OtpInput
                                            value={this.state.otp}
                                            onChange={this.handleChange}
                                            numInputs={4}
                                        />
                                        <div className="text-danger">{this.state.errors.otp}</div>
                                    </Form.Group>
                                    {this.props.verifyEmailCodeRequest ? (
                                        <Loader/>
                                    ) : (
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                type="submit"
                                                className="submit_style"
                                                value="Verify Now"
                                            />
                                        </Form.Group>
                                    )}
                                </Form>
                                <div className="account-links">
                                    Didn't receive the code?{" "}
                                    <button className="btn" onClick={() => this.resendOtp()} id="optbtn">
                                        Resend
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            error: state.user.error,
            countries: state.general.countries,
            verifyEmailCodeRequest: state.user.verifyEmailCodeRequest,
            verifyEmailCodeFailed: state.user.verifyEmailCodeFailed,
            verifyEmailCodeSuccess: state.user.verifyEmailCodeSuccess,
            verifyEmailCodeMsg: state.user.verifyEmailCodeMsg,
            fromForgotScreen: state.user.fromForgotScreen,
            resendOtpRequest: state.user.resendOtpRequest,
            resendOtpFailed: state.user.resendOtpFailed,
            resendOtpSuccess: state.user.resendOtpSuccess,
            resendOtpMsg: state.user.resendOtpMsg,
        };
    },
    {verifyEmail, resendOtp, getCountries}
)(VerifyOtp);
