import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import {IoMdClose} from "react-icons/io";
import {secondsToTime, validate} from "../helpers";
import connect from "react-redux/es/connect/connect";
import {
    getBusinessTypes,
    getTimezones,
    getCountries,
    getStates,
    getCities,
} from "../actions/general";
import Loader from "../components/Loader";
import {DEFAULT_NOTIFICATION_SETTINGS, VALIDATIONS} from "../constants";
import NumberFormat from "react-number-format";
import {registration} from "../actions/user";
import {store} from "react-notifications-component";
import TimePicker from 'react-bootstrap-time-picker';

class CreateAccount extends Component {
    constructor() {
        super();
        this.state = {
            file: null,
            imageType: false,
            input: {},
            errors: {},
            imgError: false,
            opening_time: "00:00",
            closing_time: "00:00",
            closing_time_error: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleOpeningTime = this.handleOpeningTime.bind(this);
        this.handleClosingTime = this.handleClosingTime.bind(this);
    }

    async handleOpeningTime(time) {
        time = await secondsToTime(time)
        this.setState({opening_time: time});
    }

    async handleClosingTime(time) {
        time = await secondsToTime(time)
        this.setState({closing_time: time});
    }

    handleChange(event) {
        if (!event.target.files[0].type.match(/image.*/)) {
            this.setState({imageType: true});
        } else {
            this.setState({
                file: URL.createObjectURL(event.target.files[0]),
                imageType: false,
                imgError: false,
            });
        }
    }

    handleRemove(event) {
        this.setState({
            file: null,
        });
    }

    handleChangeText(event) {
        let input = this.state.input;
        if (
            (event.target.name == "phone" && event.target.value.length > 15) ||
            (event.target.name == "phone" && event.target.value == "e")
        ) {
            return false;
        }
        input[event.target.name] = event.target.value;

        this.setState({
            input,
        });
        let validated = validate(this.state.input);
        this.setState({errors: validated.errors});
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.state.file) {
            this.setState({imgError: true});
            this.nameInput.focus();

        }
        console.log('phone:', this.state.phone_validation)
        if (!this.state.phone_validation.is_valid) {
            return false;
        }
        if (this.state.file) {
            this.setState({imgError: false});
            const formData = new FormData(event.currentTarget);
            formData.append("phone", this.state.phone);
            formData.append("latitude", this.state.latitude);
            formData.append("longitude", this.state.longitude);
            formData.append("opening_time", this.state.opening_time);
            formData.append("closing_time", this.state.closing_time);
            this.props.registration(formData);
        }
    }

    componentWillMount() {
        this.props.getBusinessTypes();
        this.props.getTimezones();
        this.props.getCountries();
    }

    handleKeyPress(event) {
        if (event.key == "Enter") {
            // event.preventDefault();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            !this.props.userRegistrationSuccess &&
            nextProps.userRegistrationSuccess
        ) {
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Success",
                message: nextProps.userRegistrationMsg,
                type: "success",
            });
        }
        if (
            !this.props.userRegistrationFailed &&
            nextProps.userRegistrationFailed
        ) {
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Error",
                message: nextProps.error.message,
                type: "danger",
            });
        }
    }

    selectValue = (event) => {
        if (event.target.name == "country") {
            this.props.getStates(event.target.value);
        }

        if (event.target.name == "state") {
            this.props.getCities(event.target.value);
        }
    };

    // onPlaceSelected = (place) => {
    //     const addressArray = place.address_components,
    //         address = getAddress(addressArray),
    //         city = getCity(addressArray),
    //         country = getCountry(addressArray),
    //         state = getStateLong(addressArray),
    //         //state = getState(addressArray),
    //         zipcode = getPostalCode(addressArray),
    //         latValue = place.geometry.location.lat(),
    //         lngValue = place.geometry.location.lng();
    //     this.setState({
    //         address: address && address != '' ? address : place.formatted_address,
    //         placeholder_address: address && address != '' ? address : place.formatted_address,
    //         //country: (country) ? country : '',
    //         country: (country) ? country : '',
    //         city: (city) ? city : '',
    //         state_long: (state) ? state : '',
    //         state: (state) ? state : '',
    //         zipcode: (zipcode) ? zipcode : '',
    //         latitude: (latValue) ? latValue : '',
    //         longitude: (lngValue) ? lngValue : '',
    //     })
    // };

    render() {
        let timezones = this.props.timezones || null;
        let business_types = this.props.business_types || null;
        let countries = this.props.countries || null;
        let states = this.props.states || null;
        if (timezones == null || business_types == null || countries == null) {
            return (
                <section className="account_page">
                    <Container fluid>
                        <Row>
                            <Col
                                xs={12}
                                md={12}
                                lg={10}
                                xl={10}
                                className="m-auto text-center"
                            >
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
        let timeZoneElement = timezones.map((timezone, key) => {
            return (
                <option value={timezone} key={key}>
                    {timezone}
                </option>
            );
        });
        let businessTypeElement = business_types.map((business_type, key) => {
            return (
                <option value={business_type.id} key={key}>
                    {business_type.name}
                </option>
            );
        });

        let countryElement = countries.map((country, key) => {
            return (
                <option value={country.id} key={key}>
                    {country.name}
                </option>
            );
        });

        return (
            <section className="account_page createAccountContainer">
                <Container fluid>
                    <Row>
                        <Col xs={12} md={12} lg={10} xl={10} className="m-auto text-center">
                            <div className="bg_white">
                                <div className="figure mb-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="188.695"
                                        height="71.713"
                                        viewBox="0 0 188.695 71.713"
                                    >
                                        <g id="Logo" transform="translate(0 0)">
                                            <g
                                                id="Logo-2"
                                                data-name="Logo"
                                                transform="translate(0 0)"
                                            >
                                                <path
                                                    id="Path_7911"
                                                    data-name="Path 7911"
                                                    d="M454.058,181.356A5.578,5.578,0,0,0,452.2,179a5.66,5.66,0,0,0-3.518-1.138c-2.038,0-3.781,1.2-5.246,3.608l-9.491,15.422-9.455-15.422q-2.236-3.611-5.283-3.608A5.69,5.69,0,0,0,415.7,179a5.206,5.206,0,0,0-2,2.813l-6.784,23.218-16.428-23.344a8.582,8.582,0,0,0-2.581-2.407,6.752,6.752,0,0,0-3.566-1.116,6.926,6.926,0,0,0-3.566,1.116,8.478,8.478,0,0,0-2.617,2.407l-19.852,27.537h11.9l13.963-19.162,8.032,11.172H379.28l-5.8,7.99,32.188.09H416.24l4.04-16.371L429,206.907a5.58,5.58,0,0,0,2.191,1.933,6.33,6.33,0,0,0,3.007.774,5.335,5.335,0,0,0,4.725-2.707l4.113-6.621a84.932,84.932,0,0,1,11.019-18.93"
                                                    transform="translate(-358.312 -157.882)"
                                                    fill="#174d9f"
                                                />
                                                <path
                                                    id="Path_7912"
                                                    data-name="Path 7912"
                                                    d="M508.4,218.658h10.524l-4.709-16.2a65.775,65.775,0,0,0-6.637,12.915Z"
                                                    transform="translate(-415.022 -167.226)"
                                                    fill="#2491eb"
                                                />
                                                <path
                                                    id="Path_7913"
                                                    data-name="Path 7913"
                                                    d="M565.383,181.577a18,18,0,0,0-10.7-3.092H533.4l-6.859,7.99h29.044a5.624,5.624,0,0,1,3.329.959,3.031,3.031,0,0,1,1.354,2.607,3.145,3.145,0,0,1-1.354,2.655,5.427,5.427,0,0,1-3.329,1H528.861v15.765h9.623v-7.774h16.175a18.023,18.023,0,0,0,10.73-3.092,10.287,10.287,0,0,0-.006-17.018"
                                                    transform="translate(-422.227 -158.118)"
                                                    fill="#2491eb"
                                                />
                                                <path
                                                    id="Path_7914"
                                                    data-name="Path 7914"
                                                    d="M633.218,182.7q-5.238-4.211-13.447-4.214H605.118l-6.1,7.99h20.752a9.991,9.991,0,0,1,6.552,2.133,6.74,6.74,0,0,1,2.6,5.452,6.527,6.527,0,0,1-2.576,5.367,10.239,10.239,0,0,1-6.573,2.043h-11.13V190.13h-9.622V209.46h20.752q8.074,0,13.384-4.487a14.164,14.164,0,0,0,5.3-11.277,13.443,13.443,0,0,0-5.24-11"
                                                    transform="translate(-449.764 -158.118)"
                                                    fill="#2491eb"
                                                />
                                                <path
                                                    id="Path_7915"
                                                    data-name="Path 7915"
                                                    d="M500.871,211.429c-6.695-5.525-1.433-22.959,11.756-38.94s29.3-24.455,36-18.93a6.708,6.708,0,0,1,1,1.029,7.143,7.143,0,0,1,1.267,2.655,23.228,23.228,0,0,1,.589,6.565v.1s.511-6.554-1.76-11.792a10.009,10.009,0,0,0-3.243-4.476,9.151,9.151,0,0,0-1-.657,8.773,8.773,0,0,0-2.1-.889l-.088-.025c-.02-.006-.039-.012-.059-.017h0c-9.418-2.591-25.389,7.047-38.023,23.635-14.237,18.7-18.84,39.135-10.286,45.651s27.036-3.35,41.273-22.038c-13.058,15.453-28.743,23.55-35.321,18.124"
                                                    transform="translate(-408.579 -145.639)"
                                                    fill="#2491eb"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h3>Store Registration</h3>
                                <p>
                                    Your information will be reviewed by one of our team members.
                                </p>
                                <Form
                                    onSubmit={this.handleSubmit}
                                    onKeyPress={this.handleKeyPress}
                                    id="signup-form"
                                >
                                    {/* <Tabs defaultActiveKey="first" id="uncontrolled-tab-example"
                                          className="m-auto tab_inter">
                                        <Tab eventKey="first" title="Store Info">
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="div-form">
                                                        <Form.Control type="text"
                                                                      className="input_style"
                                                                      required
                                                                      name="store_name" maxLength={VALIDATIONS.NAME}/>
                                                        <span className="floating-label">Store Name</span>
                                                    </Form.Group>
                                                    <Form.Group className="div-form">
                                                        <Form.Control type="text" name="name" className="input_style"
                                                                      required maxLength={VALIDATIONS.NAME}/>
                                                        <span className="floating-label">Full Name</span>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <div className="lable-form">
                                                        Upload Current Logo (if Any)
                                                    </div>
                                                    <div
                                                        className={this.state.file ? "div-form div-file active" : "div-form div-file "}>
                                                        <Form.Control type="file" className="input_file" name="image"
                                                                      required
                                                                      onChange={this.handleChange}/>
                                                        <div className="input_file_img">
                                                            <Image src={require('../../img/file.png')} alt="File Img"
                                                                   className="img-fluid"></Image>
                                                            <p>Upload Product Image <span>Browse</span></p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={this.state.file ? "bg_upload_file active" : "bg_upload_file"}
                                                        style={{background: this.state.color}}>
                                                        <Image src={this.state.file} alt="File Img"
                                                               className="img-fluid"></Image>
                                                        <div className="closed_btn" onClick={this.handleRemove}>
                                                            <IoMdClose/>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <div className="div-form floating-label">
                                                        <textarea className="floating-input div-text" name="about"
                                                                  maxLength={VALIDATIONS.DESCRIPTION}></textarea>
                                                        <span className="highlight"></span>
                                                        <label>About Store (500 characters.)</label>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={12}>
                                                    <div className="div-form">
                                                        <Autocomplete
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                            apiKey={GOOGLE_API_KEY}
                                                            onPlaceSelected={this.onPlaceSelected}
                                                            types={['establishment']}
                                                            class="form-control input_style autocomplete"
                                                            name="address"
                                                            id="autocomplete-address"
                                                            placeholder={this.state.placeholder_address}
                                                            onChange={(e) => {
                                                                this.setState({address: e.target.value})
                                                            }}
                                                            onClick={() => {
                                                                this.setState({placeholder_address: ""})
                                                            }}
                                                            onBlur={() => {
                                                                this.setState({placeholder_address: this.state.address})
                                                            }}
                                                        />
                                                        <span className="floating-label">Location/Address</span>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="div-form select_div">
                                                        <select className="input_style">
                                                            <option disabled selected>Select Your TimeZone</option>
                                                            {timeZoneElement}
                                                        </select>
                                                        <span className="floating-label">TimeZone</span>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} md={6} className="time_pickers">
                                                    <Row>
                                                        <Col xs={12} md={6}>
                                                            <Form.Group className="div-form">
                                                                <Form.Control type="time" name="opening_time"
                                                                              className="input_style"
                                                                              required/>
                                                                <span className="floating-label">Opening Time</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <Form.Group className="div-form">
                                                                <Form.Control type="time" name="closing_time"
                                                                              className="input_style"
                                                                              required/>
                                                                <span className="floating-label">Closing Time</span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="div-form">
                                                        <NumberFormat className="form-control input_style phone-number"
                                                                      required format="(###) ###-####"
                                                                      value={this.state.phone}
                                                                      name="phone"
                                                                      onValueChange={(values) => {
                                                                          this.setState({
                                                                              phone: values.value,
                                                                              show_phone: values.formattedValue,
                                                                          })
                                                                      }}/>

                                                        <span className="floating-label">Contact Number</span>
                                                        <div className="text-danger">{this.state.errors.phone}</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="div-form select_div">
                                                        <select className="input_style">
                                                            <option disabled selected>Type of Business</option>
                                                            {businessTypeElement}
                                                        </select>
                                                        <span className="floating-label">Type of Business</span>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6} className="m-auto">
                                                    <Form.Group className="div-form mt-3">
                                                        <Form.Control type="button" onClick={this.handleNext}
                                                                      className="submit_style"
                                                                      value="Next"/>
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </Tab>
                                        <Tab eventKey="sceond" title="Payment Info">
                                            <CheckoutForm/>
                                        </Tab>

                                    </Tabs>*/}
                                    <Row className="tab-content">
                                        <Col xs={12} md={6}>
                                            <Form.Group className="div-form">
                                                <Form.Control
                                                    type="text"
                                                    className="input_style"
                                                    required
                                                    name="store_name"
                                                    maxLength={VALIDATIONS.NAME}
                                                />
                                                <span className="floating-label">Store Name</span>
                                            </Form.Group>
                                            <Form.Group className="div-form">
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    className="input_style"
                                                    required
                                                    maxLength={VALIDATIONS.NAME}
                                                />
                                                <span className="floating-label">Full Name</span>
                                            </Form.Group>
                                            <Form.Group className="div-form">
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    className="input_style"
                                                    required
                                                    maxLength={VALIDATIONS.EMAIL}
                                                    onChange={this.handleChangeText}
                                                />
                                                <span className="floating-label">Email Address</span>
                                            </Form.Group>
                                            <Form.Group className="div-form select_div">
                                                <Form.Control
                                                    as="select"
                                                    required="required"
                                                    className="input_style"
                                                    name="job_title"
                                                    onChange={(e) => this.selectValue(e)}
                                                >
                                                    <option disabled selected value="">

                                                    </option>
                                                    {["lead", "owner", "employee", "manager"].map(
                                                        (title, index) => {
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    style={{textTransform: "capitalize"}}
                                                                    value={title}
                                                                >
                                                                    {title}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </Form.Control>

                                                <span className="floating-label">Job Title</span>
                                            </Form.Group>
                                            {/* <Form.Group className="div-form">
                        <Form.Control
                          type="text"
                          name="job_title"
                          className="input_style"
                          required
                          maxLength={VALIDATIONS.JOB_TITLE}
                        />
                        <span className="floating-label">Job Title</span>
                      </Form.Group> */}
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <div className="lable-form">
                                                Upload Current Logo (if Any)
                                            </div>
                                            <div
                                                className={
                                                    this.state.file
                                                        ? "div-form div-file active"
                                                        : "div-form div-file "
                                                }
                                            >
                                                <Form.Control ref={(input) => {
                                                    this.nameInput = input;
                                                }}
                                                              type="file"
                                                              className="input_file"
                                                              name="image"
                                                              accept="image/png, image/gif, image/jpeg"
                                                              onChange={this.handleChange}
                                                />
                                                <div
                                                    className={
                                                        this.state.imgError
                                                            ? "input_file_img_red"
                                                            : "input_file_img"
                                                    }
                                                >
                                                    <Image
                                                        src={require("../../img/file.png")}
                                                        alt="File Img"
                                                        className="img-fluid"
                                                    ></Image>
                                                    <p>
                                                        Upload Product Image <span>Browse</span>
                                                    </p>
                                                </div>
                                                <div className="text-danger text-center mt-2">
                                                    {this.state.imgError ? "Please Upload The Current Logo" : ""}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    this.state.file
                                                        ? "bg_upload_file active"
                                                        : "bg_upload_file"
                                                }
                                                style={{background: this.state.color}}
                                            >
                                                <Image
                                                    src={this.state.file}
                                                    alt="File Img"
                                                    className="img-fluid"
                                                ></Image>
                                                <div className="closed_btn" onClick={this.handleRemove}>
                                                    <IoMdClose/>
                                                </div>
                                            </div>
                                            {this.state.imageType && (
                                                <span className="image-error">
                          Please select image only.
                        </span>
                                            )}
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <div className="div-form floating-label">
                        <textarea
                            className="floating-input div-text"
                            name="about"
                            maxLength={VALIDATIONS.DESCRIPTION}
                        ></textarea>
                                                <span className="highlight"></span>
                                                <label>About Store (500 characters.)</label>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="div-form select_div">
                                                        <Form.Control
                                                            type="text"
                                                            name="address"
                                                            className="input_style"
                                                            required
                                                            maxLength={VALIDATIONS.ADDRESS}
                                                        />
                                                        <span className="floating-label">Address</span>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="div-form select_div">
                                                        <Form.Control
                                                            as="select"
                                                            required
                                                            className="input_style"
                                                            name="country"
                                                            onChange={(e) => this.selectValue(e)}
                                                        >
                                                            <option disabled selected value=''>

                                                            </option>
                                                            {countryElement}
                                                        </Form.Control>

                                                        <span className="floating-label">Country</span>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="div-form select_div">
                                                        <select
                                                            className="input_style"
                                                            required
                                                            name="state"
                                                            onChange={(e) => this.selectValue(e)}
                                                        >
                                                            <option disabled>Select Your State</option>
                                                            {this.props.states &&
                                                            this.props.states.map((state, key) => {
                                                                return (
                                                                    <option value={state.id} key={key}>
                                                                        {state.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <span className="floating-label">State</span>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group className="div-form select_div">
                                                        <select
                                                            required
                                                            className="input_style"
                                                            name="city"
                                                        >
                                                            <option disabled>Select Your City</option>
                                                            {this.props.cities &&
                                                            this.props.cities.map((city, key) => {
                                                                return (
                                                                    <option value={city.id} key={key}>
                                                                        {city.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <span className="floating-label">City</span>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {/* <div className="div-form">
                                                <Autocomplete
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    apiKey={GOOGLE_API_KEY}
                                                    onPlaceSelected={this.onPlaceSelected}
                                                    types={['establishment']}
                                                    className="form-control input_style autocomplete"
                                                    name="address"
                                                    id="autocomplete-address"
                                                    autoComplete="off"
                                                    placeholder={this.state.placeholder_address}
                                                    onChange={(e) => {
                                                        this.setState({address: e.target.value})
                                                    }}
                                                    onClick={() => {
                                                        this.setState({placeholder_address: ""})
                                                    }}
                                                    onBlur={() => {
                                                        this.setState({placeholder_address: this.state.address})
                                                    }}
                                                />
                                                <span className="floating-label">Location/Address</span>
                                            </div> */}
                                        </Col>
                                        {/* <Col xs={12} md={6}>
                                            <Form.Group className="div-form select_div">
                                                <select className="input_style" name="timezone">
                                                    <option disabled selected>Select Your TimeZone</option>
                                                    {timeZoneElement}
                                                </select>
                                                <span className="floating-label">TimeZone</span>
                                            </Form.Group>
                                        </Col> */}

                                        {/* <Col xs={12} md={6} className="time_pickers"> */}
                                        {/* <Row> */}
                                        <Col xs={12} md={6}>
                                            <Form.Group className="div-form timeser">
                                                {/* <Form.Control
                                                    type="time"
                                                    name="opening_time"
                                                    className="input_style"
                                                    required
                                                />*/}
                                                <TimePicker value={this.state.opening_time} start="00:00" end="23:30"
                                                            step={30}
                                                            onChange={this.handleOpeningTime}
                                                            style={{backgroundColor: "#f8f8fa"}}/>
                                                <span className="floating-label">Opening Time</span>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="div-form timeser">
                                                {/* <Form.Control
                                                    type="time"
                                                    name="closing_time"
                                                    className="input_style"
                                                    required
                                                />*/}
                                                <TimePicker value={this.state.closing_time} start="00:00" end="23:30"
                                                            step={30}
                                                            onChange={this.handleClosingTime}
                                                            style={{backgroundColor: "#f8f8fa"}}/>
                                                <span className="floating-label">Closing Time</span>
                                            </Form.Group>
                                        </Col>
                                        {/* </Row> */}
                                        {/* </Col> */}
                                        <Col xs={12} md={6}>
                                            <Form.Group className="div-form">
                                                <NumberFormat
                                                    className="form-control input_style phone-number"
                                                    required
                                                    format="(###) ###-####"
                                                    value={this.state.phone}
                                                    onValueChange={(values) => {
                                                        let validated = validate({phone: values.value})
                                                        this.setState({
                                                            phone: values.value,
                                                            show_phone: values.formattedValue,
                                                            phone_validation: validated
                                                        });
                                                    }}
                                                />

                                                <span className="floating-label">Contact Number</span>
                                                <div
                                                    className="text-danger">{this.state.hasOwnProperty('phone_validation') ? this.state.phone_validation.errors.phone : ""}</div>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="div-form select_div">
                                                <select className="input_style" name="business_type_id" required>
                                                    <option disabled selected value=''>

                                                    </option>
                                                    {businessTypeElement}
                                                </select>
                                                <span className="floating-label">Type of Business</span>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={3}></Col>
                                        <Col xs={12} md={6} className="m-auto">
                                            {this.props.userRegistrationRequest ? (
                                                <Loader/>
                                            ) : (
                                                <Form.Group className="div-form mt-3">
                                                    <Form.Control
                                                        type="submit"
                                                        className="submit_style"
                                                        value="Submit"
                                                    />
                                                </Form.Group>
                                            )}
                                        </Col>
                                        <Col xs={12} md={3}></Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
            ;
    }
}

export default connect(
    (state, ownProps) => {
        return {
            timezones: state.general.timezones,
            business_types: state.general.business_types,
            countries: state.general.countries,
            states: state.general.states,
            cities: state.general.cities,
            userRegistrationRequest: state.user.userRegistrationRequest,
            userRegistrationFailed: state.user.userRegistrationFailed,
            userRegistrationSuccess: state.user.userRegistrationSuccess,
            userRegistrationMsg: state.user.userRegistrationMsg,
            error: state.user.error,
        };
    },
    {
        getBusinessTypes,
        getTimezones,
        getCountries,
        getStates,
        getCities,
        registration,
    }
)(CreateAccount);
