import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {Form, Modal} from "react-bootstrap";
import {nextDate, validate} from "../helpers";
import connect from "react-redux/es/connect/connect";
import {changePassword, updateStore} from "../actions/user";
import {store} from "react-notifications-component";
import {getBusinessTypes} from "../actions/general";
import {DEFAULT_NOTIFICATION_SETTINGS, VALIDATIONS} from "../constants";
import Loader from "../components/Loader";
import {republishOffer} from "../actions/offer";

class RepublishPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expire_at: nextDate(),
            new_password: "",
            input: {},
            errors: {},
        };
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeText(event) {
        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            input,
        });
        let validated = validate(this.state.input);
        this.setState({errors: validated.errors});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.passwordSucces && nextProps.passwordSucces) {
            this.props.handleModalShowHide();
            this.setState({showHide: false});
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Success",
                message: nextProps.passwordSuccessMsg,
                type: "success",
            });
        }

        if (!this.props.passwordRequestFailed && nextProps.passwordRequestFailed) {
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Error",
                message: nextProps.error.message,
                type: "danger",
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        let formData = new FormData()
        formData.append('expire_at', this.state.expire_at)
        formData.append('offer_id', this.props.offerId)
        this.props.republishOffer(formData, this.props.user.access.token);
    }

    render() {
        return (
            <Modal show={this.props.showHide} centered>
                <Modal.Body className="bg_white">
                    <h3>Republish Offer</h3>
                    <p>
                        Set the new password for your account so you can login and access
                        all the features.
                    </p>
                    <Form id="change-password" onSubmit={this.handleSubmit}>
                        <Form.Group className="div-form new_csss">
                            <Form.Control
                                type="date"
                                className="input_style"
                                name="expire_at"
                                value={this.state.expire_at}
                                min={nextDate()}
                                onChange={(e) => {
                                    this.setState({expire_at: e.target.value});
                                }}
                                required
                            />
                            <span className="floating-label">Expire At</span>
                        </Form.Group>
                        <Form.Group className="div-form form-submit">
                            {this.props.passwordRequest ? (
                                <Loader/>
                            ) : (
                                <Form.Control
                                    type="submit"
                                    className="submit_style"
                                    value="Publish"
                                />
                            )}
                        </Form.Group>
                    </Form>

                    <div className="cancel_style">
                        <NavLink to="#" onClick={this.props.handleModalShowHide}>
                            Cancel
                        </NavLink>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            error: state.user.error,
            republishOfferRequest: state.offer.republishOfferRequest,
            republishOfferFailed: state.offer.republishOfferFailed,
            republishOfferSuccess: state.offer.republishOfferSuccess
        };
    },
    {republishOffer}
)(RepublishPopup);
