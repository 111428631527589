import {
  requestSuccessAction,
  requestFailureAction,
  curl_get,
  curl_post,
  curl_put,
} from "./http";
import { BASE_URL, ENDPOINTS } from "../constants";
import {
  gotoHome,
  gotoVerifyResetCode,
  gotoResetPassword,
  gotoLogin,
  gotoEditProfile,
  gotoThanks,
  gotoVerify,
  gotoProfile,
} from "./router";

// Action Types
const types = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_FAILED: "USER_LOGIN_FAILED",

  USER_FORGOT: "USER_FORGOT",
  USER_FORGOT_REQUEST: "USER_FORGOT_REQUEST",
  USER_FORGOT_FAILED: "USER_FORGOT_FAILED",

  VERIFY_CODE: "VERIFY_CODE",
  VERIFY_CODE_REQUEST: "VERIFY_CODE_REQUEST",
  VERIFY_CODE_FAILED: "VERIFY_CODE_FAILED",

  VERIFY_EMAIL_CODE: "VERIFY_EMAIL_CODE",
  VERIFY_EMAIL_CODE_REQUEST: "VERIFY_EMAIL_CODE_REQUEST",
  VERIFY_EMAIL_CODE_FAILED: "VERIFY_CODE_FAILED",

  RESEND_OTP: "RESEND_OTP",
  RESEND_OTP_REQUEST: "RESEND_OTP_REQUEST",
  RESEND_OTP_FAILED: "RESEND_OTP_FAILED",

  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",

  USER_REGISTRATION: "USER_REGISTRATION",
  USER_REGISTRATION_REQUEST: "USER_REGISTRATION_REQUEST",
  USER_REGISTRATION_FAILED: "USER_REGISTRATION_FAILED",

  SOCIAL_LOGIN: "SOCIAL_LOGIN",
  SOCIAL_LOGIN_REQUEST: "SOCIAL_LOGIN_REQUEST",
  SOCIAL_LOGIN_FAILED: "SOCIAL_LOGIN_FAILED",

  REFRESH_TOKEN: "REFRESH_TOKEN",
  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_FAILED: "REFRESH_TOKEN_FAILED",

  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",

  UPDATE_STORE: "UPDATE_STORE",
  UPDATE_STORE_REQUEST: "UPDATE_STORE_REQUEST",
  UPDATE_STORE_FAILED: "UPDATE_STORE_FAILED",

  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",

  ME: "ME",
  ME_REQUEST: "ME_REQUEST",
  ME_FAILED: "ME_FAILED",
};
export default types;

export function logout() {
  return (dispatch) => {
    dispatch(gotoLogin());
    setTimeout(() => {
      dispatch({
        type: types.USER_LOGOUT,
        payload: {},
      });
    }, 1000);
  };
  /* return {
       type: types.USER_LOGOUT,
       payload: {},
   }*/
}

export function login(payload) {
  return (dispatch) => {
    dispatch({ type: types.USER_LOGIN_REQUEST });
    curl_post(
      dispatch,
      BASE_URL + ENDPOINTS.LOGIN,
      payload,
      types.USER_LOGIN_FAILED,
      (data) => {
        data.email = payload.get("email");
        if (data.data.is_verified == 0) {
          dispatch(requestSuccessAction(data, types.USER_LOGIN));
          // Set Success Message then Redirect To Home
          curl_post(
            dispatch,
            BASE_URL + ENDPOINTS.RESEND_OTP,
            { email: data.data.email },
            types.RESEND_OTP_FAILED,
            () => {}
          );
          dispatch(gotoVerify());
        } else {
          dispatch(requestSuccessAction(data, types.USER_LOGIN));
          // Set Success Message then Redirect To Home
          dispatch(gotoHome());
        }
      }
    );
  };
}

// export function verifycode(code) {
//     return (dispatch) => {
//         dispatch({type: types.VERIFY_CODE_REQUEST, payload: {code}});
//         let data = {
//             verification_code: code,
//             device_type: "web"
//         };
//         curl_post(dispatch, BASE_URL + ENDPOINTS.VERIFY_CODE, data, types.VERIFY_CODE_FAILED, (data) => {
//             dispatch(requestSuccessAction(data, types.VERIFY_CODE))
//             // Set Success Message then Redirect To RESET PASSWORD SCREEN
//             setTimeout(() => {
//                 dispatch(gotoResetPassword());
//             }, 2000)

//         });
//     }
// }
export function verifyEmail(code, email, isForgot = false) {
  return (dispatch) => {
    dispatch({
      type: types.VERIFY_EMAIL_CODE_REQUEST,
      payload: { code, email },
    });
    let data = {
      verification_code: code,
      email: email,
    };
    curl_post(
      dispatch,
      BASE_URL + ENDPOINTS.VERIFY_CODE,
      data,
      types.VERIFY_EMAIL_CODE_FAILED,
      (data) => {
        data.email = email;
        data.verification_code = code;
        dispatch(requestSuccessAction(data, types.VERIFY_EMAIL_CODE));
        if (
          data.data.is_approved == 0 &&
          data.data.is_verified == 1 &&
          !isForgot
        ) {
          setTimeout(() => {
            dispatch(gotoThanks());
          }, 1000);
        }
        if (
          data.data.is_approved == 1 &&
          data.data.is_verified == 1 &&
          !isForgot
        ) {
          setTimeout(() => {
            dispatch(gotoHome());
          }, 1000);
        }
        if (isForgot) {
          setTimeout(() => {
            dispatch(gotoResetPassword());
          }, 1000);
        }
      }
    );
  };
}

export function resendOtp(email) {
  return (dispatch) => {
    dispatch({ type: types.RESEND_OTP_REQUEST, payload: { email } });
    let data = {
      email: email,
    };
    curl_post(
      dispatch,
      BASE_URL + ENDPOINTS.RESEND_OTP,
      data,
      types.RESEND_OTP_FAILED,
      (data) => {
        dispatch(requestSuccessAction(data, types.RESEND_OTP));
        // Set Success Message then Redirect To RESET PASSWORD SCREEN
      }
    );
  };
}

export function forgotPassword(email) {
  return (dispatch) => {
    dispatch({ type: types.USER_FORGOT_REQUEST, payload: { email } });
    let data = {
      email: email,
    };
    curl_post(
      dispatch,
      BASE_URL + ENDPOINTS.FORGOT_PASSWORD,
      data,
      types.USER_FORGOT_FAILED,
      (data) => {
        data.email = email;
        dispatch(requestSuccessAction(data, types.USER_FORGOT));
        setTimeout(() => {
          dispatch(gotoVerify());
        }, 2000);
      }
    );
  };
}

export function resetpassword(email, code, password) {
  return (dispatch) => {
    dispatch({ type: types.RESET_PASSWORD_REQUEST });
    let data = {
      email,
      verification_code: code,
      password,
      device_type: "web",
    };
    curl_post(
      dispatch,
      BASE_URL + ENDPOINTS.RESET_PASSWORD,
      data,
      types.RESET_PASSWORD_FAILED,
      (data) => {
        dispatch(requestSuccessAction(data, types.RESET_PASSWORD));
        // Set Success Message then Redirect To RESET PASSWORD SCREEN
        setTimeout(() => {
          dispatch(gotoLogin());
        }, 2000);
      }
    );
  };
}

export function registration(formdata) {
  return (dispatch) => {
    dispatch({ type: types.USER_REGISTRATION_REQUEST });
    curl_post(
      dispatch,
      BASE_URL + ENDPOINTS.REGISTER,
      formdata,
      types.USER_REGISTRATION_FAILED,
      (data) => {
        data.email = formdata.get("email");
        dispatch(requestSuccessAction(data, types.USER_REGISTRATION));
        // Set Success Message then Redirect To HOME SCREEN
        dispatch(gotoVerify());
      }
    );
  };
}

export function socailLogin(data) {
  return (dispatch) => {
    dispatch({ type: types.SOCIAL_LOGIN_REQUEST });
    curl_post(
      dispatch,
      BASE_URL + ENDPOINTS.SOCIAL_LOGIN,
      data,
      types.SOCIAL_LOGIN_FAILED,
      (data) => {
        dispatch(requestSuccessAction(data, types.SOCIAL_LOGIN));
        // Set Success Message then Redirect To HOME SCREEN
        setTimeout(() => {
          dispatch(gotoHome());
        }, 2000);
      }
    );
  };
}

//change password
export function changePassword(payload, token) {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch({ type: types.CHANGE_PASSWORD_REQUEST });
    curl_post(
      dispatch,
      BASE_URL + ENDPOINTS.CHANGE_PASSWORD,
      payload,
      types.CHANGE_PASSWORD_FAILED,
      (data) => {
        dispatch(requestSuccessAction(data, types.CHANGE_PASSWORD));
        setTimeout(() => {
          dispatch(gotoHome());
        }, 1000);
      },
      config
    );
  };
}

//email-notifications
export function updateUser(payload, token) {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch({ type: types.UPDATE_USER_REQUEST });
    curl_post(
      dispatch,
      BASE_URL + ENDPOINTS.UPDATE_USER,
      payload,
      types.UPDATE_USER_FAILED,
      (data) => {
        dispatch(requestSuccessAction(data, types.UPDATE_USER));
        setTimeout(() => {
          dispatch(gotoProfile());
        }, 1000);
      },
      config
    );
  };
}

export function updateStore(id, payload, token) {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch({ type: types.UPDATE_STORE_REQUEST });
    curl_put(
      dispatch,
      BASE_URL + "/stores" + "/" + id,
      payload,
      types.UPDATE_STORE_FAILED,
      (data) => {
        dispatch(requestSuccessAction(data, types.UPDATE_STORE));
        setTimeout(() => {
          dispatch(gotoProfile());
        }, 2000);
      },
      config
    );
  };
}

export function getMe(token) {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch({ type: types.ME_REQUEST });
    curl_get(
      dispatch,
      BASE_URL + "/me",
      "",
      types.ME_FAILED,
      (data) => {
        dispatch(requestSuccessAction(data, types.ME));
      },
      config
    );
  };
}
