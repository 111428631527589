import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import {NavLink} from "react-router-dom";
import {validate} from "../helpers";
import {DEFAULT_NOTIFICATION_SETTINGS, VALIDATIONS} from "../constants";
import connect from "react-redux/es/connect/connect";
import {login} from "../actions/user";
import Loader from "../components/Loader";
import {store} from "react-notifications-component";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_password: "",
            show_password: false,
            input: {},
            errors: {},
        };
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleShowPassword(e) {
        this.setState({show_password: this.state.show_password ? false : true});
    }

    handleChangeText(event) {
        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            input,
        });
        let validated = validate(this.state.input);
        this.setState({errors: validated.errors});
    }

    handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        this.props.login(formData);
        /*let validated = validate(this.state.input)
            if (validated.is_valid) {
                const formData = new FormData(event.currentTarget);
                this.props.login(formData)
            }
            event.preventDefault();
            this.setState({errors: validated.errors})
            return false*/
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.loginSuccess && nextProps.loginSuccess) {
            store.removeAllNotifications();
            /*store.addNotification({
                      ...DEFAULT_NOTIFICATION_SETTINGS,
                      title: "Success",
                      message: nextProps.loginSuccessMsg,
                      type: "success",
                  });*/
        }
        if (!this.props.loginFailed && nextProps.loginFailed) {
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Error",
                message: nextProps.error.message,
                type: "danger",
            });
        }
    }

    render() {
        return (
            <section className="credentials_page">
                <Container fluid>
                    <Row>
                        <Col xs={12} md={6} className="text-center z-index-9 m-auto">
                            <Row>
                                <Col xs={12} md={11} lg={9} className="m-auto">
                                    <h1>For Consumers</h1>
                                    <p style={{marginBottom: "5px"}}>Swipe. Scan. Save!</p>
                                    <p>
                                        Download Ampd today and get access to deals in your area! We believe finding
                                        savings
                                        shouldn't be cluttered and overwhelming so the team at Ampd has worked to
                                        simplify the
                                        process. With our unique swipe feature, we have made it easier than ever to save
                                        money
                                        whether you are looking to find a place to eat, shop, or even get a cup of
                                        coffee.
                                    </p>
                                    <div className="img_box">
                                        <Image
                                            src={require("../../img/img01.png")}
                                            alt="For Consumers"
                                            className="img-fluid"
                                        ></Image>
                                    </div>
                                    <div className="img_box1">
                                        <a href="https://apps.apple.com/sn/app/ampd-influence/id1644670180"
                                           target="_blank">
                                            <Image
                                                src={require("../../img/ios-app-store.png")}
                                                alt="ios-app-store"
                                                className="figure-img  img-fluid"
                                            ></Image>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className="text-center z-index-9 form-section">
                            <Row>
                                <Col xs={12} md={10} lg={8} xl={7} className="col-xxl-6 m-auto">
                                    <h1 className="blue">For Business</h1>
                                    <p>Already have an account, sign in to continue!</p>
                                    <Form action="/" method="GET" onSubmit={this.handleSubmit}>
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                type="email"
                                                className={
                                                    this.state.errors.email
                                                        ? "error_danger input_style"
                                                        : "input_style"
                                                }
                                                required
                                                id="email"
                                                onChange={this.handleChangeText}
                                                name="email"
                                                maxLength={VALIDATIONS.EMAIL}
                                            />
                                            <span className="floating-label">Your Email Address</span>
                                            {/* <div className="text-danger">{this.state.errors.email}</div>*/}
                                        </Form.Group>
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                type={this.state.show_password ? "text" : "password"}
                                                className={
                                                    this.state.errors.password
                                                        ? "error_danger input_style"
                                                        : "input_style"
                                                }
                                                required
                                                name="password"
                                                minLength={VALIDATIONS.PASSWORD_MIN}
                                                maxLength={VALIDATIONS.PASSWORD_MAX}
                                                id="password"
                                                onChange={this.handleChangeText}
                                            />
                                            <span className="floating-label">Your Password</span>
                                            <span className="error">
                        {this.state.show_password ? (
                            <i
                                className="far fa-eye"
                                onClick={this.handleShowPassword}
                            ></i>
                        ) : (
                            <i
                                className="far fa-eye-slash"
                                onClick={this.handleShowPassword}
                            ></i>
                        )}
                      </span>
                                            <div className="text-danger">{this.state.errors.password}</div>
                                        </Form.Group>
                                        <Form.Group className="div-form link-form">
                                            <NavLink to="forgot-password">Forgot password?</NavLink>
                                        </Form.Group>
                                        {this.props.loginRequest ? (
                                            <Loader/>
                                        ) : (
                                            <Form.Group className="div-form form-submit">
                                                <Form.Control
                                                    type="submit"
                                                    className="submit_style"
                                                    value="Sign In"
                                                />
                                            </Form.Group>
                                        )}
                                    </Form>
                                    <NavLink to="create-account">
                                        <Form.Group className="div-form form-submit">
                                            <Form.Control
                                                type="submit"
                                                className="submit_style"
                                                value="Create an Account"
                                            />
                                        </Form.Group>
                                    </NavLink>
                                    {/* <div className="btn-comman create-account">
                    </NavLink>
                  </div> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            loginRequest: state.user.loginRequest,
            loginFailed: state.user.loginFailed,
            loginSuccess: state.user.loginSuccess,
            loginSuccessMsg: state.user.loginSuccessMsg,
            error: state.user.error,
        };
    },
    {login}
)(Login);
