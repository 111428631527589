import user from "./user";
import general from "./general";
import offer from "./offer";
import dashboard from "./dashboard";

export const USER_ACTIONS = user;
export const GENERAL_ACTIONS = general;
export const OFFER_ACTIONS = offer;
export const DASHBOARD_ACTIONS = dashboard;

// Export All Actions.
export default {
    USER_ACTIONS: user,
    GENERAL_ACTIONS: general,
    OFFER_ACTIONS: offer,
    DASHBOARD_ACTIONS:dashboard
};