import {USER_ACTIONS} from "../actions";

export default function user(state = {}, action) {
    switch (action.type) {
        // Loop Example
        case USER_ACTIONS.USER_LOGIN: {
            let user = action.payload.data;
            let newState = {
                ...state,
                tempEmail: action.payload.email,
                loggedIn: true,
                loginFailed: false,
                details: user,
                user_id: user.id,
                email: user.email,
                role: user.__meta__.rolesCsv,
                access: user.access_token,
                loginSuccessMsg: action.payload.message,
                loginRequest: false,
                loginSuccess: true,
            };
            return newState;
        }
        case USER_ACTIONS.USER_LOGOUT: {
            return {...state, loggedIn: false, failed: false, details: {}};
            // return {}
        }
        case USER_ACTIONS.USER_LOGIN_FAILED: {
            return {
                ...state,
                loggedIn: false,
                error: action.error,
                loginFailed: true,
                loginRequest: false,
                loginSuccess: false,
            };
        }
        case USER_ACTIONS.USER_LOGIN_REQUEST: {
            return {
                ...state,
                loginRequest: true,
                loginFailed: false,
                loginSuccess: false,
            };
        }

        case USER_ACTIONS.REFRESH_TOKEN: {
            // TODO: Handle Other Fields (Possibility of changed values from other client);
            let user = action.payload.data;
            return {
                ...state,
                access: user.access_token,
            };
        }

        case USER_ACTIONS.USER_FORGOT_REQUEST: {
            return {
                ...state,
                forgotRequest: true,
                forgotFailed: false,
                forgotEmail: action.payload.email,
            };
        }
        case USER_ACTIONS.USER_FORGOT_FAILED: {
            return {
                ...state,
                forgotRequest: false,
                forgotFailed: true,
                error: action.error,
            };
        }
        case USER_ACTIONS.USER_FORGOT: {
            return {
                ...state,
                tempEmail: action.payload.email,
                fromForgotScreen: true,
                forgotRequest: false,
                forgotFailed: false,
                forgotSuccess: true,
                forgotMsg: action.payload.message,
            };
        }

        // case USER_ACTIONS.VERIFY_CODE_REQUEST: {
        //     return {...state, verifyCodeRequest: true, verifyCodeFailed: false, verifyCode: action.payload.code};
        // }
        // case USER_ACTIONS.VERIFY_CODE_FAILED: {
        //     return {...state, verifyCodeRequest: false, verifyCodeFailed: true, error: action.error};
        // }
        // case USER_ACTIONS.VERIFY_CODE: {
        //     return {
        //         ...state,
        //         verifyCodeRequest: false,
        //         verifyCodeFailed: false,
        //         verifyCodeSuccess: true,
        //         verifyCodeMsg: action.payload.message
        //     };
        // }

        case USER_ACTIONS.VERIFY_EMAIL_CODE_REQUEST: {
            return {
                ...state,
                verifyEmailCodeRequest: true,
                verifyEmailCodeFailed: false,
                verifyCode: action.payload.code,
            };
        }
        case USER_ACTIONS.VERIFY_EMAIL_CODE_FAILED: {
            return {
                ...state,
                verifyEmailCodeRequest: false,
                verifyEmailCodeFailed: true,
                error: action.error,
            };
        }
        case USER_ACTIONS.VERIFY_EMAIL_CODE: {
            return {
                ...state,
                details: action.payload.data,
                tempEmail: action.payload.email,
                code: action.payload.verification_code,
                verifyEmailCodeRequest: false,
                verifyEmailCodeFailed: false,
                verifyEmailCodeSuccess: true,
                verifyEmailCodeMsg: action.payload.message,
            };
        }

        case USER_ACTIONS.RESEND_OTP_REQUEST: {
            return {
                ...state,
                resendOtpRequest: true,
                resendOtpFailed: false,
                email: action.payload.email,
                resendOtpSuccess: false
            };
        }
        case USER_ACTIONS.RESEND_OTP_FAILED: {
            return {
                ...state,
                resendOtpRequest: false,
                resendOtpFailed: true,
                error: action.error,
                resendOtpSuccess: false
            };
        }
        case USER_ACTIONS.RESEND_OTP: {
            return {
                ...state,
                resendOtpRequest: false,
                resendOtpFailed: false,
                resendOtpSuccess: true,
                resendOtpMsg: action.payload.message,
            };
        }

        case USER_ACTIONS.RESET_PASSWORD_REQUEST: {
            return {
                ...state,
                resetPasswordRequest: true,
                resetPasswordFailed: false,
            };
        }
        case USER_ACTIONS.RESET_PASSWORD_FAILED: {
            return {
                ...state,
                resetPasswordRequest: false,
                resetPasswordFailed: true,
                error: action.error,
            };
        }
        case USER_ACTIONS.RESET_PASSWORD: {
            return {
                ...state,
                resetPasswordRequest: false,
                resetPasswordFailed: false,
                resetPasswordSuccess: true,
                resetPasswordMsg: action.payload.message,
            };
        }

        case USER_ACTIONS.USER_REGISTRATION_REQUEST: {
            return {
                ...state,
                userRegistrationRequest: true,
                userRegistrationFailed: false,
                userRegistrationSuccess: false,
            };
        }
        case USER_ACTIONS.USER_REGISTRATION_FAILED: {
            return {
                ...state,
                userRegistrationRequest: false,
                userRegistrationFailed: true,
                error: action.error,
                userRegistrationSuccess: false,
            };
        }
        case USER_ACTIONS.USER_REGISTRATION: {
            return {
                ...state,
                tempEmail: action.payload.email,
                userRegistrationRequest: false,
                userRegistrationFailed: false,
                userRegistrationSuccess: true,
                userRegistrationMsg: action.payload.message,
            };
        }

        //social login
        case USER_ACTIONS.SOCIAL_LOGIN_REQUEST: {
            return {
                ...state,
                socailLoginRequest: true,
                socailLoginFailed: false,
            };
        }
        case USER_ACTIONS.SOCIAL_LOGIN_FAILED: {
            return {
                ...state,
                socailLoginRequest: false,
                socailLoginFailed: true,
                error: action.error,
            };
        }
        case USER_ACTIONS.SOCIAL_LOGIN: {
            let {user} = action.payload.data;
            let newState = {
                ...state,
                loggedIn: true,
                failed: false,
                details: user.details,
                user_id: user.id,
                email: user.email,
                role: user.roles_csv,
                access: {
                    token: user.access_token,
                    expires: user.expires_in,
                    type: user.token_type,
                },
                request: false,

                socailLoginRequest: false,
                socailLoginFailed: false,
                socailLoginSuccess: true,
                socailLoginMsg: action.payload.message,
            };
            return newState;
        }
        //end

        //chnage password
        case USER_ACTIONS.CHANGE_PASSWORD: {
            return {
                ...state,
                passwordRequest: false,
                passwordRequestFailed: false,
                passwordSucces: true,
                passwordSuccessMsg: action.payload.message,
            };
        }
        case USER_ACTIONS.CHANGE_PASSWORD_FAILED: {
            return {
                ...state,
                error: action.error,
                passwordRequestFailed: true,
                passwordRequest: false,
            };
        }
        case USER_ACTIONS.CHANGE_PASSWORD_REQUEST: {
            return {...state, passwordRequest: true, passwordRequestFailed: false};
        }

        //update store
        case USER_ACTIONS.UPDATE_STORE_REQUEST: {
            return {
                ...state,
                updateStoreRequest: true,
                updateStoreFailed: false,
                updateStoreSuccess: false,
            };
        }
        case USER_ACTIONS.UPDATE_STORE: {
            return {
                ...state,
                updateStoreRequest: false,
                updateStoreFailed: false,
                updateStoreSuccess: true,
                updateStoreSuccessMsg: action.payload.message,
                details: action.payload.data,
            };
        }
        case USER_ACTIONS.UPDATE_STORE_FAILED: {
            return {
                ...state,
                updateStoreRequest: false,
                error: action.error,
                updateStoreFailed: true,
                updateStoreSuccess: false,
            };
        }

        //update user
        case USER_ACTIONS.UPDATE_USER_REQUEST: {
            return {
                ...state,
                updateUserRequest: true,
                updateUserFailed: false,
                updateUserSuccess: false,
            };
        }
        case USER_ACTIONS.UPDATE_USER: {
            return {
                ...state,
                updateUserRequest: false,
                updateUserFailed: false,
                updateUserSuccess: true,
                updateUserSuccessMsg: action.payload.message,
                details: action.payload.data,
            };
        }
        case USER_ACTIONS.UPDATE_USER_FAILED: {
            return {
                ...state,
                updateUserRequest: false,
                error: action.error,
                updateUserFailed: true,
                updateUserSuccess: false,
            };
        }

        //ME API
        case USER_ACTIONS.ME_REQUEST: {
            return {
                ...state,
                meRequest: true,
                meFailed: false,
                meSuccess: false,
            };
        }
        case USER_ACTIONS.ME: {
            return {
                ...state,
                meRequest: false,
                meFailed: false,
                meSuccess: true,
                meSuccessMsg: action.payload.message,
                details: action.payload.data,
            };
        }
        case USER_ACTIONS.ME_FAILED: {
            return {
                ...state,
                meRequest: false,
                error: action.error,
                meFailed: true,
                meSuccess: false,
            };
        }

        default:
            return state;
    }
}
