// export const BASE_URL = "http://127.0.0.1:3333/api/v1";
// export const BASE_URL = "https://ampd.tekstaging.com/api/v1";
export const BASE_URL = "https://admin.ampdinfluence.com/api/v1";
export const GOOGLE_API_KEY = "AIzaSyANqpbqOxMaZuvmhp7BE_8Y2om5fghSDQw";
export const STRIPE_KEY = "pk_test_2kwes8cuUU2RbXg6xmnsrnqs00j4rEjAIa";
export const ENDPOINTS = {
    LOGIN: "/store-login",
    GET_BUSINESS_TYPES: "/business-types",
    GET_TIMEZONES: "/timezones",
    REGISTER: "/store-registration",
    GET_COLORS: "/background-colors",
    OFFER: "/offers",
    REPUBLISH_OFFER: "/republish-offer",
    BARBER_OFFER: "/get-barber-offers",
    REFRESH_TOKEN: "/refresh-token",
    VERIFY_CODE: "/verify-otp",
    COUNTRIES: "/countries",
    CITIES: "/cities?state_id=",
    STATES: "/states?country_id=",
    RESEND_OTP: "/resend-otp",
    CHANGE_PASSWORD: "/change-password",
    DASHBOARD_DATA: "/dashboard-data",
    UPDATE_USER: "/update-user",
    NOTIFICATIONS: "/notifications",
    MARK_ALL_READ: "/marked-all-read",
    UNREAD_COUNT: "/get-unread-count",
    MARK_READ: "/mark-read",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    GET_PAGE: "/page"
};
export const DEFAULT_NOTIFICATION_SETTINGS = {
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 5000, //5000
    },
};
export const PAGINATION = {
    HOME_PAGE: 10,
};

export const ADMIN_EMAIL = {
    email: "info@ampd.com",
};

export const PAGES = {
    ABOUT: "about-us",
    PRIVACY_POLICY: "privacy-policy",
};
export const VALIDATIONS = {
    NAME: 25,
    OFFER_NAME: 20,
    EMAIL: 255,
    PASSWORD_MIN: 8,
    PASSWORD_MAX: 15,
    ADDRESS: 255,
    PHONE: 15,
    DESCRIPTION: 500,
    JOB_TITLE: 10,
};
export const OFFER_TYPES = {
    AMOUNT: 10,
    PERCENTAGE: 20,
};
export const OFFER_ACTIVITY_STATUS = {
    INACTIVE: 10,
    ACTIVE: 20,
};
export const OFFER_STATUSES = {
    DRAFT: 10,
    PUBLISHED: 20,
    EXPIRED: 30,
};
export const DATE_FORMAT_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
};
