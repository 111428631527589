import {DASHBOARD_ACTIONS} from "../actions";

export default function dashboard(state = {}, action) {
    switch (action.type) {

               //get countries
              case DASHBOARD_ACTIONS.DASHBOARD_REQUEST: {
                return {...state, dashboardRequest: true, dashboardFailed: false, dashboardSuccess: false};
            }
            case DASHBOARD_ACTIONS.DASHBOARD: {
                return {
                    ...state,
                    dashboardRequest: false,
                    dashboardFailed: false,
                    dashboard: action.payload.data,
                    dashboardSuccess: true,
                    dashboardMsg: action.payload.message

                };
    
            }
            case DASHBOARD_ACTIONS.DASHBOARD_FAILED: {
                return {
                    ...state,
                    dashboardRequest: false,
                    error: action.error,
                    dashboardFailed: true,
                    dashboardSuccess: false
                };
            }
default:
            return state;
    }
}