import React, {Component} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import PublishPopup from "../modal/PublishPopup";
import DiscardPopup from "../modal/DiscardPopup";
import ChangePassword from "../modal/ChangePassword";
import Carousel from "react-multi-carousel";
import NumberFormat from "react-number-format";
import {NavLink} from "react-router-dom";
import {
    getAllNotifications,
    getNotificationUnreadCount,
} from "../actions/general";
import connect from "react-redux/es/connect/connect";

class EditDraftOffer extends Component {
    constructor() {
        super();
        this.state = {
            showHide: false,
            showHide1: false,
            showHide2: false,
            offavail: "3.2",
            offreocc: "4.2",
            dislike: "3.0",
            nouser: "10",
            proitem: "Starbucks Triple Mocha",
            offamount: "25",
            offtitle: "Starbucks Triple Mocha",
        };
        this.BtnClick = this.BtnClick.bind(this);
    }

    async BtnClick(e) {
        e.preventDefault();
        document.getElementById("RedeemMessage").classList.toggle("active");
        document.getElementById("qr_code").classList.toggle("active");
    }

    handleModalShowHide() {
        this.setState({showHide: !this.state.showHide});
    }

    handleModalShowHide1() {
        this.setState({showHide1: !this.state.showHide1});
    }

    handleModalShowHide2() {
        this.setState({showHide2: !this.state.showHide2});
    }

    componentWillMount() {
        this.props.getAllNotifications(this.props.user.access.token);
        this.props.getNotificationUnreadCount(this.props.user.access.token);
    }

    handleSubmit(e) {
        e.preventDefault();
        let payload = {
            offavail: this.state.offavail,
            offreocc: this.state.offreocc,
            dislike: this.state.dislike,
            nouser: this.state.nouser,
            proitem: this.state.proitem,
            off_mount: this.state.offamount,
            offtitle: this.state.offtitle,
        };
        this.props.updateProfile(payload, this.props.user.access_token.idToken);
    }

    render() {
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: {max: 4000, min: 3000},
                items: 8,
            },
            desktop: {
                breakpoint: {max: 3000, min: 1024},
                items: 8,
            },
            tablet: {
                breakpoint: {max: 1024, min: 464},
                items: 6,
            },
            mobile: {
                breakpoint: {max: 464, min: 0},
                items: 6,
            },
        };
        return (
            <section className="main_container myoffer">
                <Container fluid className="bg_color paddingr-0">
                    <Row>
                        <Col md={12} className="m-auto">
                            <h2>Edit Draft Offer</h2>
                            <p className="subtxt">
                                Please fill in the information below to create a new offer.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container
                    fluid
                    className="scroll-container padding-top-20 paddingr-0 create_offer"
                >
                    <Form action="thanks" method="GET" className="offer_edit">
                        <Row>
                            <Col md={5} lg={5} xl={5} className="col-xxl-4">
                                <div className="div-form div-file active">
                                    <Form.Control type="file" className="input_file"/>
                                    <div className="input_file_img">
                                        <Image
                                            src={require("../../img/file.png")}
                                            alt="File Img"
                                            className="img-fluid"
                                        ></Image>
                                        <p>
                                            Upload Product Image <span>Browse</span>
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="bg_upload_file active"
                                    style={{background: "#F2BC74"}}
                                >
                                    <Image
                                        src={require("../../img/img2.png")}
                                        alt="File Img"
                                        className="img-fluid"
                                    ></Image>
                                </div>
                                <div className="color_pickers">
                                    <Carousel
                                        rswipeable={true}
                                        draggable={true}
                                        showDots={false}
                                        responsive={responsive}
                                        ssr={true} // means to render carousel on server-side.
                                        infinite={true}
                                        removeArrowOnDeviceType={["tablet", "dekstop", "mobile"]}
                                    >
                                        <div>
                                            <div
                                                className="item_color"
                                                style={{background: "#20AA81"}}
                                            >
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="item_color "
                                                style={{background: "#5D73E2"}}
                                            >
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="item_color active"
                                                style={{background: "#F2BC74"}}
                                            >
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="item_color"
                                                style={{background: "#9AE49F"}}
                                            >
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="item_color"
                                                style={{background: "#8A90FF"}}
                                            >
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="item_color"
                                                style={{background: "#B9B9B9"}}
                                            >
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="item_color"
                                                style={{background: "#333333"}}
                                            >
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="item_color"
                                                style={{background: "#8A90FF"}}
                                            >
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="item_color"
                                                style={{background: "#B9B9B9"}}
                                            >
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="item_color"
                                                style={{background: "#9AE49F"}}
                                            >
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                    </Carousel>
                                </div>

                                <div className="btn-plub">
                                    <NavLink to="/my-drafts" className="mb-3">
                                        Save Draft{" "}
                                    </NavLink>
                                    <div onClick={() => this.handleModalShowHide()}>
                                        Update Offer
                                    </div>
                                </div>
                            </Col>
                            <Col md={7} lg={7} xl={7} className="col-xxl-7">
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                type="text"
                                                className="input_style"
                                                name="offtitle"
                                                value={this.state.offtitle}
                                                onChange={(e) => {
                                                    this.setState({offtitle: e.target.value});
                                                }}
                                                minLength={2}
                                                maxLength={25}
                                            />
                                            <span className="floating-label">Offer Title</span>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <Form.Group className="div-form radio_style">
                                            <div className="form-check">
                                                <Form.Control
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                />
                                            </div>
                                            <div className="form-check">
                                                <Form.Control
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    checked
                                                />
                                            </div>
                                            <Form.Group className="div-form">
                                                <NumberFormat
                                                    format="####"
                                                    value={25}
                                                    className="input_style form-control"
                                                />
                                                <span className="floating-label">
                          Enter Offer Amount
                        </span>
                                            </Form.Group>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                type="text"
                                                name="proitem"
                                                className="input_style"
                                                value={this.state.proitem}
                                                onChange={(e) => {
                                                    this.setState({proitem: e.target.value});
                                                }}
                                                minLength={2}
                                                maxLength={25}
                                            />
                                            <span className="floating-label">Product Item</span>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <Form.Group className="div-form">
                                                <NumberFormat
                                                    format="####"
                                                    value={22}
                                                    className="input_style form-control"
                                                />
                                                <span className="floating-label">Number of uses</span>
                                            </Form.Group>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form new_csss">
                                            <Form.Control type="date" className="input_style"/>
                                            <span className="floating-label">Expiration Date</span>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <NumberFormat
                                                format="####"
                                                value={4.3}
                                                className="input_style form-control"
                                            />
                                            <span className="floating-label">
                        Dislike Counter (hrs)
                      </span>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <NumberFormat
                                                format="####"
                                                value={5.2}
                                                className="input_style form-control"
                                            />
                                            <span className="floating-label">
                        Offer Reoccurrence Time (hrs)
                      </span>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <NumberFormat
                                                format="####"
                                                value={3.2}
                                                className="input_style form-control"
                                            />
                                            <span className="floating-label">
                        Offer Avail Time (hrs)
                      </span>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={5} className="col-xxl-5">
                                        <Form.Group className="div-form input_files">
                                            <Form.Control type="file" className="input_file"/>
                                            <div className="inputfile_img">
                                                <p>Upload Your QR Code</p>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        xl={1}
                                        className="or_btn col-xxl-1"
                                    >
                                        <p>or</p>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={5} className="col-xxl-5">
                                        <div className="div-form input_msg" onClick={this.BtnClick}>
                                            <div>Redeem Message</div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <div className="qr_code" id="qr_code">
                                            <Image
                                                src={require("../../img/qrcode.png")}
                                                alt="File Img"
                                                className="img-fluid"
                                            ></Image>
                                        </div>
                                        <div className="div-form floating-label" id="RedeemMessage">
                      <textarea
                          className="floating-input div-text"
                          placeholder=" "
                      ></textarea>
                                            <span className="highlight"></span>
                                            <label>Redeem Message</label>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <PublishPopup
                        showHide={this.state.showHide}
                        handleModalShowHide={this.handleModalShowHide}
                    />
                    <DiscardPopup
                        showHide={this.state.showHide1}
                        handleModalShowHide={this.handleModalShowHide1}
                    />
                    <ChangePassword
                        showHide={this.state.showHide2}
                        handleModalShowHide={this.handleModalShowHide2}
                    />
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            notifications: state.general.notifications,
            unread_notifications: state.general.unread_notifications,
        };
    },
    {getAllNotifications, getNotificationUnreadCount}
)(EditDraftOffer);
