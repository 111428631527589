import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import {NavLink} from "react-router-dom";
import {removeActiveSideMenu} from "../helpers";
import connect from "react-redux/es/connect/connect";
import {DEFAULT_NOTIFICATION_SETTINGS} from "../constants";
import {updateUser} from "../actions/user";
import {store} from "react-notifications-component";
import {Col, Container, Row} from "react-bootstrap";
import Loader from "../components/Loader";

class SettingSidebar extends Component {
    constructor() {
        super();
        this.state = {
            emailToggle: 0,
        };
        this.emailToggle = this.emailToggle.bind(this);
    }

    emailToggle(event) {
        if (event.target.checked == true) {
            this.props.updateUser(
                {push_notifications: 1},
                this.props.user.access.token
            );
        }
        if (event.target.checked == false) {
            this.props.updateUser(
                {push_notifications: 0},
                this.props.user.access.token
            );
        }
    }

    async handleremovelink() {
        await removeActiveSideMenu();
    }

    render() {
        let user = this.props.user || null
        if (user == null) {
            return (
                <section className="main_container myoffer">
                    <Container fluid className="bg_color paddingr-0">
                        <Row>
                            <Col md={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
        return (
            <div>
                <section className="sidebar_panel" id="setting_panel">
                    <div className="header-box">
                        <h3>Settings</h3>
                    </div>
                    <ul>
                        <li>
                            <div className="title">Notifications</div>
                            <div className="action">
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        onChange={(e) => this.emailToggle(e)}
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked={user.details.push_notifications ? true : false}
                                    />
                                </div>
                            </div>
                        </li>
                        <li>
                            <NavLink
                                to="/privacy-policy"
                                onClick={() => this.handleremovelink()}
                            >
                                <div className="title">Privacy Policy</div>
                                <div className="action">
                                    <i className="fas fa-chevron-right"></i>
                                </div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/about" onClick={() => this.handleremovelink()}>
                                <div className="title">About Us</div>
                                <div className="action">
                                    <i className="fas fa-chevron-right"></i>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </section>
            </div>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            error: state.user.error,
            updateUserRequest: state.user.updateUserRequest,
            updateUserFailed: state.user.updateUserFailed,
            updateUserSuccess: state.user.updateUserSuccess,
            updateUserSuccessMsg: state.user.updateUserSuccessMsg,
        };
    },
    {updateUser}
)(SettingSidebar);
