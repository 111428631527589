import {push} from 'connected-react-router'

export function gotoHome() {
    return push("/");
}

export function gotoOffers() {
    return push("/my-offers");
}

export function gotoDraftOffers() {
    return push("/my-drafts");
}

export function gotoSearch() {
    return push("/search-result");
}

export function gotoVerifyResetCode() {
    return push("/verify-reset-code");
}

export function gotoResetPassword() {
    return push("/reset-password");
}

export function gotoLogin() {
    return push("/login");
}

export function gotoThanks() {
    return push("/thanks");
}

export function gotoVerify() {
    return push("/verify-otp");
}

export function gotoProfile(id) {
    return id !== undefined ? push("/profile/" + id) : push("/profile");
}

export function gotoNews(id) {
    return push("/news/" + id);
}

export function gotoEditProfile() {
    return push("/edit-profile/");
}


// TODO: Add Route Action to Redirect User to Reset Password Screen