import React, {Component} from 'react';
import {connect} from "react-redux";
import Loader from "./Loader";
import {Redirect} from 'react-router'

class Auth extends Component {
    componentWillMount() {
        if (!this.props.user.hasOwnProperty('loggedIn') || !this.props.user.loggedIn) {
            return window.location.href = "/login";
        }
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

export default connect(
    (state) => {
        return {
            user: state.user
        };
    },
    {}
)(Auth);