import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Image} from "react-bootstrap";
import {removeActiveSideMenu} from "../helpers";


class SidebarNavigation extends Component {

    constructor() {
        super();
        this.handleSetting = this.handleSetting.bind(this);
        this.handleProfile = this.handleProfile.bind(this);
        this.handleNotification = this.handleNotification.bind(this);
    };

    async handleSetting() {
        await removeActiveSideMenu()
        document.body.classList.toggle('body_class');
        document.getElementById("setting_panel").classList.toggle("active");
    }

    async handleProfile() {
        await removeActiveSideMenu()
        document.body.classList.toggle('body_class');
        document.getElementById("profile_panel").classList.toggle("active");
    }

    async handleNotification() {
        await removeActiveSideMenu()
        document.body.classList.toggle('body_class');
        document.getElementById("notification_panel").classList.toggle("active");
    }

    async handleremovelink() {
        await removeActiveSideMenu()
    }


    render() {
        let pathname = this.props.pathname || ""
        return (
            <section className="sidebar" id="sidebar">

                <ul className="sidebar_main">
                    <li className="menu_mobile">
                        <ul className="menu_list">
                            <li>
                                <div className="notification" onClick={this.handleNotification}>
                                    <i className="fas fa-bell"></i>
                                </div>
                            </li>
                            <li>
                                <div className="setting" onClick={this.handleSetting}>
                                    <i className="fas fa-cog"></i>
                                </div>
                            </li>
                            <li>
                                <div className="profile" onClick={this.handleProfile}>
                                    <div className="img_profile">
                                        <Image src={require('../../img/profile_img.png')} rounded
                                               alt="Profile Pic"/>
                                    </div>
                                    <div className="profile_name"> Abc Store</div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className={pathname == "/" ? "active" : ""}>
                        <NavLink to="/" onClick={() => this.handleremovelink()}>
                            <span className="icons">
                               <Image src={require('../../img/dashboard.png')} alt="Dashboard"
                                      className="img-fluid"></Image>
                            </span>
                            Dashboard
                        </NavLink>
                    </li>
                    <li className={pathname == "/my-offers" ? "active" : ""}>
                        <NavLink to="/my-offers" onClick={() => this.handleremovelink()}>
                            <span className="icons">
                              <Image src={require('../../img/offer.png')} alt="My Offer"
                                     className="img-fluid"></Image>
                            </span>
                            My Offers
                        </NavLink>
                    </li>
                    <li className={pathname == "/my-drafts" ? "active" : ""}>
                        <NavLink to="/my-drafts" onClick={() => this.handleremovelink()}>
                            <span className="icons">
                            <Image src={require('../../img/draft.png')} alt="My Drafts"
                                   className="img-fluid"></Image>
                        </span>
                            My Drafts
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="login" onClick={() => this.handleremovelink()}>
                           <span className="icons">
                            <Image src={require('../../img/logout.png')} alt="Logout"
                                   className="img-fluid"></Image>
                        </span>
                            Logout
                        </NavLink>
                    </li>
                </ul>
            </section>
        );
    }
}

export default SidebarNavigation;