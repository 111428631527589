import React, {Component} from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import connect from "react-redux/es/connect/connect";
import {getAllNotifications, getNotificationUnreadCount, getPage} from "../actions/general";
import {PAGES} from "../constants";
import {Markup} from "interweave";
import Loader from "../components/Loader";

class AboutUs extends Component {
    componentWillMount() {
        this.props.getPage(PAGES.ABOUT)
        this.props.getAllNotifications(this.props.user.access.token);
        this.props.getNotificationUnreadCount(this.props.user.access.token);
    }

    render() {
        let page = this.props.page || null
        if (page == null) {
            return (
                <section className="main_container myoffer">
                    <Container fluid className="bg_color paddingr-0">
                        <Row>
                            <Col md={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
        return (
            <section className="main_container">
                <Container fluid className="bg_color paddingr-0">
                    <Row>
                        <Col md={12} className="m-auto">
                            <h2>{page.title}</h2>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="scroll-container padding-top-20 paddingr-0 create_offer">
                    <Row>
                        <Col md={12}>
                            <div className="abt_section">
                                <Markup content={page.content}/>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            page: state.general.page || null
        };
    },
    {getPage, getAllNotifications, getNotificationUnreadCount}
)(AboutUs);