import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { AnimatedSwitch, spring } from "react-router-transition";

import Layout from "./app/pages/Layout";
import Home from "./app/pages/Home";
import ContactUs from "./app/pages/ContactUs";

import { history } from "./app/store";
import Login from "./app/pages/Login";
import ForgotPassword from "./app/pages/ForgotPassword";
import VerifyOtp from "./app/pages/VerifyOTP";
import ResetPassword from "./app/pages/ResetPassword";
import CreateAccount from "./app/pages/CreateAccount";
import Thanks from "./app/pages/Thanks";
import MyOffers from "./app/pages/MyOffer";
import MyDrafts from "./app/pages/MyDrafts";
import SearchResult from "./app/pages/SearchResult";
import OffersDetails from "./app/pages/OfferDetails";
import DraftDetails from "./app/pages/DraftDetails";
import EditProfile from "./app/pages/EditProfile";
import Profile from "./app/pages/Profile";
import CreateOffer from "./app/pages/CreateOffer";
import EditOffer from "./app/pages/EditOffer";
import AboutUs from "./app/pages/About";
import PrivacyPolicy from "./app/pages/PrivacyPolicy";
import EditDraftOffer from "./app/pages/EditDraftOffer";
import Auth from "./app/components/Auth";

class App extends Component {
  constructor(props) {
    super(props);
    this.fade = this.fade.bind(this);
    this.fadeMapStyles = this.fadeMapStyles.bind(this);
  }

  fadeTransitions = {
    atEnter: {
      opacity: 1,
    },
    atLeave: {
      opacity: this.fade(0),
    },
    atActive: {
      opacity: this.fade(1),
    },
  };

  renderWithLayout(component, extraAttr, ownProps) {
    return (
      <div>
        {React.createElement(
          component,
          !!ownProps ? { ...ownProps, ...extraAttr } : extraAttr
        )}
        {/*<Footer/>*/}
      </div>
    );
  }

  fade(val) {
    return spring(val, {
      stiffness: 250,
      damping: 46,
      precision: 0.1,
    });
  }

  fadeMapStyles(styles) {
    return {
      opacity: styles.opacity,
    };
  }

  render() {
    return (
      <ConnectedRouter history={history}>
        <AnimatedSwitch
          className="animatedSwitch"
          {...this.fadeTransitions}
          mapStyles={this.fadeMapStyles}
        >
          {/*All Routes that does not need to render layout.*/}
          <Route
            exact
            path={[
              "/login",
              "/forgot-password",
              "/verify-otp",
              "/reset-password",
              "/create-account",
              "/thanks",
            ]}
            render={(location) => {
              return (
                <AnimatedSwitch
                  className="animatedSwitch"
                  {...this.fadeTransitions}
                  runOnMount={location.pathname === "/"}
                  mapStyles={this.fadeMapStyles}
                >
                  <Route
                    exact
                    path="/login"
                    component={this.renderWithLayout.bind(this, Login)}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    component={this.renderWithLayout.bind(this, ForgotPassword)}
                  />
                  <Route
                    exact
                    path="/verify-otp"
                    component={this.renderWithLayout.bind(this, VerifyOtp)}
                  />
                  <Route
                    exact
                    path="/reset-password"
                    component={this.renderWithLayout.bind(this, ResetPassword)}
                  />
                  <Route
                    exact
                    path="/create-account"
                    component={this.renderWithLayout.bind(this, CreateAccount)}
                  />
                  <Route
                    exact
                    path="/thanks"
                    component={this.renderWithLayout.bind(this, Thanks)}
                  />
                </AnimatedSwitch>
              );
            }}
          />

          {/*All Routes that does need to render layout.*/}
          <Route
            render={({ location }) => (
              <Auth location={location}>
                <Layout location={location}>
                  {/* <AnimatedSwitch
                    className="animatedSwitch"
                    {...this.fadeTransitions}
                    runOnMount={location.pathname === "/"}
                    mapStyles={this.fadeMapStyles}
                  > */}
                  <Route
                    exact
                    path="/"
                    component={this.renderWithLayout.bind(this, Home)}
                  />
                  <Route
                    exact
                    path="/my-offers"
                    component={this.renderWithLayout.bind(this, MyOffers)}
                  />
                  <Route
                    exact
                    path="/my-drafts"
                    component={this.renderWithLayout.bind(this, MyDrafts)}
                  />
                  <Route
                    exact
                    path="/search-result"
                    component={this.renderWithLayout.bind(this, SearchResult)}
                  />
                  <Route
                    exact
                    path="/offer-details"
                    component={this.renderWithLayout.bind(this, OffersDetails)}
                  />
                  <Route
                    exact
                    path="/offer/:id"
                    component={this.renderWithLayout.bind(this, OffersDetails)}
                  />
                  <Route
                    exact
                    path="/draft-offer/:id"
                    component={this.renderWithLayout.bind(this, DraftDetails)}
                  />
                  <Route
                    exact
                    path="/contact"
                    component={this.renderWithLayout.bind(this, ContactUs)}
                  />
                  <Route
                    exact
                    path="/edit-profile"
                    component={this.renderWithLayout.bind(this, EditProfile)}
                  />
                  <Route
                    exact
                    path="/profile"
                    component={this.renderWithLayout.bind(this, Profile)}
                  />
                  <Route
                    exact
                    path="/create-offer"
                    component={this.renderWithLayout.bind(this, CreateOffer)}
                  />
                  <Route
                    exact
                    path="/edit-offer/:id"
                    component={this.renderWithLayout.bind(this, EditOffer)}
                  />
                  <Route
                    exact
                    path="/edit-draft-offer/:id"
                    component={this.renderWithLayout.bind(this, EditDraftOffer)}
                  />
                  <Route
                    exact
                    path="/about"
                    component={this.renderWithLayout.bind(this, AboutUs)}
                  />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={this.renderWithLayout.bind(this, PrivacyPolicy)}
                  />
                  {/* </AnimatedSwitch> */}
                </Layout>
              </Auth>
            )}
          />
        </AnimatedSwitch>

        {/*<Route component={NotFound}/>*/}
      </ConnectedRouter>
    );
  }
}

export default connect()(App);
