import {OFFER_ACTIONS} from "../actions";

const _ = require('lodash');
export default function offer(state = {}, action) {
    switch (action.type) {

        //create offer
        case OFFER_ACTIONS.CREATE_OFFER_REQUEST: {
            return {...state, createOfferRequest: true, createOfferFailed: false, createOfferSuccess: false};
        }
        case OFFER_ACTIONS.CREATE_OFFER: {
            return {
                ...state,
                createOfferRequest: false,
                createOfferFailed: false,
                offer: action.payload.data.data,
                createOfferSuccess: true,
                createOfferSuccessMsg: action.payload.message
            };

        }
        case OFFER_ACTIONS.CREATE_OFFER_FAILED: {
            return {
                ...state,
                createOfferRequest: false,
                error: action.error,
                createOfferFailed: true,
                createOfferSuccess: false
            };
        }
        //get offers
        case OFFER_ACTIONS.GET_OFFERS_REQUEST: {
            if (action.fresh_call) {
                let NewState = {
                    ...state,
                    getOffersRequest: true,
                    getOffersFailed: false,
                    getOffersSuccess: false,
                    offers: {lastPage: 1, data: []}
                };
                return NewState;
            }
            let NewState = {
                ...state,
                getOffersRequest: true,
                getOffersFailed: false,
                getOffersSuccess: false
            };
            return NewState;
        }
        case OFFER_ACTIONS.GET_OFFERS: {
            let NewState = {
                ...state,
                getOffersRequest: false,
                getOffersFailed: false,
                getOffersSuccess: true
            };
            if (action.payload.pagination) {
                let offers = state.offers ? state.offers.data : [];
                let {data, lastPage} = action.payload.data.data
                let arr = offers.concat(data)
                NewState.offers = {lastPage: lastPage, data: _.uniqBy(arr, 'id')};
            } else {
                NewState.offers = action.payload.data.data
            }

            return NewState;

        }
        case OFFER_ACTIONS.GET_OFFERS_FAILED: {
            return {
                ...state,
                getOffersRequest: false,
                error: action.error,
                getOffersFailed: true,
                getOffersSuccess: false
            };
        }
        //get offer details
        case OFFER_ACTIONS.GET_OFFER_REQUEST: {
            return {
                ...state,
                getOfferRequest: true,
                getOfferFailed: false,
                getOfferSuccess: false,
                offer_details: null
            };
        }
        case OFFER_ACTIONS.GET_OFFER: {
            return {
                ...state,
                getOfferRequest: false,
                getOfferFailed: false,
                getOfferSuccess: true,
                offer_details: action.payload.hasOwnProperty('data') ? action.payload.data : {}
            };


        }
        case OFFER_ACTIONS.GET_OFFER_FAILED: {
            return {
                ...state,
                getOfferRequest: false,
                error: action.error,
                getOfferFailed: true,
                getOfferSuccess: false
            };
        }
        //delete offer
        case OFFER_ACTIONS.DELETE_OFFER_REQUEST: {
            return {
                ...state,
                deleteOfferRequest: true,
                deleteOfferFailed: false,
                deleteOfferSuccess: false
            };
        }
        case OFFER_ACTIONS.DELETE_OFFER: {
            return {
                ...state,
                deleteOfferRequest: false,
                deleteOfferFailed: false,
                deleteOfferSuccess: true,
                deleteOfferSuccessMsg: action.payload.message,
                offer_details: null
            };


        }
        case OFFER_ACTIONS.DELETE_OFFER_FAILED: {
            return {
                ...state,
                deleteOfferRequest: false,
                error: action.error,
                deleteOfferFailed: true,
                deleteOfferSuccess: false
            };
        }
        //update offer
        case OFFER_ACTIONS.UPDATE_OFFER_REQUEST: {
            return {
                ...state,
                updateOfferRequest: true,
                updateOfferFailed: false,
                updateOfferSuccess: false
            };
        }
        case OFFER_ACTIONS.UPDATE_OFFER: {
            return {
                ...state,
                updateOfferRequest: false,
                updateOfferFailed: false,
                updateOfferSuccess: true,
                updateOfferSuccessMsg: action.payload.message,
                offer_details: action.payload.data
            };


        }
        case OFFER_ACTIONS.UPDATE_OFFER_FAILED: {
            return {
                ...state,
                updateOfferRequest: false,
                error: action.error,
                updateOfferFailed: true,
                updateOfferSuccess: false
            };
        }
        //republish offer
        case OFFER_ACTIONS.REPUBLISH_OFFER_REQUEST: {
            return {
                ...state,
                republishOfferRequest: true,
                republishOfferFailed: false,
                republishOfferSuccess: false
            };
        }
        case OFFER_ACTIONS.REPUBLISH_OFFER: {
            return {
                ...state,
                republishOfferRequest: false,
                republishOfferFailed: false,
                republishOfferSuccess: true,
                republishOfferSuccessMsg: action.payload.message
            };


        }
        case OFFER_ACTIONS.REPUBLISH_OFFER_FAILED: {
            return {
                ...state,
                republishOfferRequest: false,
                error: action.error,
                republishOfferFailed: true,
                republishOfferSuccess: false
            };
        }
        default:
            return state;
    }
}