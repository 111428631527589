import {DATE_FORMAT_OPTIONS} from "./constants";

export function getDateFromCreatedAt(date) {
    return new Date(date).toLocaleDateString("en-US", DATE_FORMAT_OPTIONS);
}

export function getFormatedDate(date) {
    if (date) {
        return new Date(date).toLocaleDateString("en-us", DATE_FORMAT_OPTIONS);
    }
    return "N/A";
}

export function scrollToElement(ref) {
    if (ref != null) {
        window.scroll({left: 0, top: ref.offsetTop, behavior: "smooth"});
    }
}

export function removeActiveSideMenu() {
    // document.getElementById("OverlyID").classList.remove("active");
    document.getElementById("profile_panel").classList.remove("active");
    document.getElementById("setting_panel").classList.remove("active");
    document.getElementById("notification_panel").classList.remove("active");
    document.getElementById("sidebar").classList.remove("active");
    document.body.classList.remove("body_class");
}

export function validate(input) {
    let errors = {};
    let isValid = true;

    if (typeof input["email"] !== "undefined") {
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (input["email"].length > 0 && !pattern.test(input["email"])) {
            isValid = false;
            errors["email"] = "Please enter valid email address.";
        }
    }

    if (typeof input["password"] !== "undefined") {
        // var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~^.#$@!%&*=?])[A-Za-z\d`~^.#$@!%&*=?]{8,30}$/);
        var pattern = new RegExp(/^.{8,15}$/);
        if (input["password"].length > 0 && !pattern.test(input["password"])) {
            isValid = false;
            errors["password"] = "Password must be Maximum 15 digits.";
        }
        if (input["password"].length < 15 && !pattern.test(input["password"])) {
            isValid = false;
            errors["password"] = "Password must be atleast 8 digits.";
        }
    }

    if (typeof input["current_password"] !== "undefined") {
        var pattern = new RegExp(/^.{8,15}$/);
        if (
            input["current_password"].length > 0 &&
            !pattern.test(input["current_password"])
        ) {
            isValid = false;
            errors["current_password"] = "Password must be Maximum 15 digits.";
        }
        if (
            input["current_password"].length < 15 &&
            !pattern.test(input["current_password"])
        ) {
            isValid = false;
            errors["current_password"] = "Password must be atleast 8 digits.";
        }

    }
    //   if (typeof input["newpassword"] !== "undefined") {
    //     var pattern = new RegExp(
    //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~^.#$@!%&*=?])[A-Za-z\d`~^.#$@!%&*=?]{8,30}$/
    //     );
    //     if (
    //       input["newpassword"].length > 0 &&
    //       !pattern.test(input["newpassword"])
    //     ) {
    //       isValid = false;
    //       errors["newpassword"] =
    //         "Password must be atleast 8 digits and contain atleast one uppercase letter, one lowercase letter, one number, one symbol.";
    //     }
    //   }
    //   if (typeof input["cpassword"] !== "undefined") {
    //     if (
    //       input["cpassword"].length > 0 &&
    //       input["cpassword"] != input["password"]
    //     ) {
    //       isValid = false;
    //       errors["cpassword"] = "Passwords do not match.";
    //     }
    //   }
    if (typeof input["rpassword"] !== "undefined") {
        if (
            input["rpassword"].length > 0 &&
            input["rpassword"] != input["password"]
        ) {
            isValid = false;
            errors["rpassword"] = "Passwords do not match.";
        }
    }

    if (typeof input["otp"] !== "undefined") {
        if (input["otp"].length < 4) {
            isValid = false;
            errors["otp"] = "Please enter a valid OTP.";
        }
    }

    if (typeof input["phone"] !== "undefined") {
        /*var pattern = new RegExp(
            /^(?!0+$)[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,9}$/im
        );*/
        var pattern = new RegExp(
            /^.{10,}$/
        );
        if (input["phone"].length > 0 && !pattern.test(input["phone"])) {
            isValid = false;
            errors["phone"] = "Please enter valid phone Number.";
        }
    }
    return {
        is_valid: isValid,
        errors: errors,
    };
}

export function getCity(addressArray) {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && "locality" === addressArray[i].types[0]) {
            city = addressArray[i].long_name;
            return city;
        }
    }
}

export function getAddress(addressArray) {
    let route = "";
    let street_number = "";
    let subpremise = "";
    let address = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && "route" === addressArray[i].types[0]) {
            route = addressArray[i].long_name;
        }
        if (
            addressArray[i].types[0] &&
            "street_number" === addressArray[i].types[0]
        ) {
            street_number = addressArray[i].long_name;
        }
        if (addressArray[i].types[0] && "subpremise" === addressArray[i].types[0]) {
            subpremise = addressArray[i].long_name;
        }
    }
    if (street_number && route) {
        address = street_number + " " + route + " " + subpremise;
    }
    return address;
}

export function getPostalCode(addressArray) {
    let zipcode = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (
            addressArray[i].types[0] &&
            "postal_code" === addressArray[i].types[0]
        ) {
            zipcode = addressArray[i].long_name;
            return zipcode;
        }
    }
}

export function getCountry(addressArray) {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if ("country" == addressArray[i].types[j]) {
                    country = addressArray[i].long_name;
                    return country;
                }
            }
        }
    }
}

export function getCountryShort(addressArray) {
    let country_short = "";
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if ("country" == addressArray[i].types[j]) {
                    country_short = addressArray[i].short_name;
                    return country_short;
                }
            }
        }
    }
}

export function getStateLong(addressArray) {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
        for (let i = 0; i < addressArray.length; i++) {
            if (
                addressArray[i].types[0] &&
                "administrative_area_level_1" === addressArray[i].types[0]
            ) {
                state = addressArray[i].long_name;
                return state;
            }
        }
    }
}

export function getState(addressArray) {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
        for (let i = 0; i < addressArray.length; i++) {
            if (
                addressArray[i].types[0] &&
                "administrative_area_level_1" === addressArray[i].types[0]
            ) {
                state = addressArray[i].short_name;
                return state;
            }
        }
    }
}

export function todayDate() {
    let today_date = new Date();
    let month = today_date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    let day =
        today_date.getDate() > 9
            ? today_date.getDate()
            : "0" + today_date.getDate();
    today_date = today_date.getFullYear() + "-" + month + "-" + day;
    return today_date;
}


export function nextDate(days = 1) {
    let today_date = new Date();
    today_date.setDate(today_date.getDate() + days);
    let month = today_date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    let day =
        today_date.getDate() > 9
            ? today_date.getDate()
            : "0" + today_date.getDate();
    today_date = today_date.getFullYear() + "-" + month + "-" + day;
    return today_date;
}

export function secondsToTime(inputSeconds) {
    var sec_num = parseInt(inputSeconds, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes;
}
