import React, {Component} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Loader from "../components/Loader";
import {
    getAllNotifications,
    getNotificationUnreadCount,
} from "../actions/general";
import NumberFormat from "react-number-format";

import {getMe} from "../actions/user";

class Profile extends Component {
    componentWillMount() {
        this.props.getMe(this.props.user.access.token);
        this.props.getAllNotifications(this.props.user.access.token);
        this.props.getNotificationUnreadCount(this.props.user.access.token);
    }

    render() {
        let user = this.props.user || null;
        if (user == null || user.details == null) {
            return (
                <section className="main_container ">
                    <ScrollToTopOnMount/>
                    <Container fluid className="bg_color paddingr-0 pt-lg-5">
                        <Row>
                            <Col xs={12} md={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }

        return (
            <section className="main_container">
                <Container fluid className="bg_color paddingr-0">
                    <Row>
                        <Col xs={6} md={6} className="m-auto">
                            <h2>My Profile</h2>
                        </Col>
                        <Col xs={6} md={6} className="m-auto">
                            <div className="dash_btn">
                                <NavLink to="edit-profile" className="edit">
                                    Edit Profile
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container
                    fluid
                    className="scroll-container padding-top-20 paddingr-0 profile"
                >
                    <Row>
                        <Col md={4} lg={4} xl={4} className="col-xxl-3">
                            <div className="profile_imges">
                                <Image
                                    src={user.details.image_url}
                                    alt="Logo"
                                    className="img-fluid"
                                    onError={require("../../img/avatar.png")}
                                ></Image>
                            </div>
                        </Col>
                        <Col md={8} lg={8} xl={8} className="col-xxl-7 mt-auto mb-auto">
                            <ul className="list_value margin-top-50">
                                <li>
                                    User Name: <span>{user.details.full_name}</span>
                                </li>
                                <li>
                                    Job Title: <span>{user.details.job_title}</span>
                                </li>
                                <li>
                                    Email Address: <span>{user.email}</span>
                                </li>
                                <li>
                                    Store Name:{" "}
                                    <span>
                    {user.details.store ? user.details.store.name : ""}
                  </span>
                                </li>
                                <li>
                                    Store Number:
                                    <span>
                    <NumberFormat
                        className="phone-number profile-input"
                        required
                        format="(###) ###-####"
                        disabled
                        value={user.details.phone ? user.details.phone : "-"}
                    />
                  </span>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md={10} lg={10} xl={10} className="col-xxl-8">
                            <Row className="mb-2">
                                <Col xs={12} sm={12} md={3}>
                                    <label className="label-profiles">About Store:</label>
                                </Col>
                                <Col xs={12} sm={12} md={9}>
                                    <div className="scroll-container-text">
                                        <p className="text-black text-start">{user.details.hasOwnProperty('store') ? user.details.store.about : "-"}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="py-5">
                        <Col md={10} lg={10} xl={8}>
                            <Row className="mb-2">
                                <Col xs={12} sm={12} md={3}>
                                    <label className="label-profiles">Location:</label>
                                </Col>
                                <Col xs={12} sm={12} md={9}>
                                    <p className="text-black text-start">{user.details.address}</p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col xs={12} sm={12} md={3}>
                                    <label className="label-profiles">City:</label>
                                </Col>
                                <Col xs={12} sm={12} md={9}>
                                    <p className="text-black text-start">{user.details.city}</p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col xs={12} sm={12} md={3}>
                                    <label className="label-profiles">State:</label>
                                </Col>
                                <Col xs={12} sm={12} md={9}>
                                    <p className="text-black text-start">{user.details.state}</p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col xs={12} sm={12} md={3}>
                                    <label className="label-profiles">Country:</label>
                                </Col>
                                <Col xs={12} sm={12} md={9}>
                                    <p className="text-black text-start">{user.details.country}</p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col xs={12} sm={12} md={3}>
                                    <label className="label-profiles">Store Timing:</label>
                                </Col>
                                <Col xs={12} sm={12} md={9}>
                                    <p className="text-black text-start">{user.details.hasOwnProperty('store') ? user.details.store.opening_time : "N/A"} - {user.details.hasOwnProperty('store') ? user.details.store.closing_time : "N/A"}</p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col xs={12} sm={12} md={3}>
                                    <label className="label-profiles">Business Type:</label>
                                </Col>
                                <Col xs={12} sm={12} md={9}>
                                    <p className="text-black text-start">{user.details.hasOwnProperty('store') ? user.details.store.business_type.name : "N/A"}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            user: state.user,
            notifications: state.general.notifications,
            unread_notifications: state.general.unread_notifications,
        };
    },
    {getAllNotifications, getNotificationUnreadCount, getMe}
)(Profile);
