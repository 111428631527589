import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {logout} from "../actions/user";

class LogoutPopup extends Component {
    constructor(props) {
        super(props)
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout(e) {
        e.preventDefault()
        this.props.handleLogoutModal()
        this.props.logout()
    }

    render() {
        return (
            <Modal show={this.props.showHide} centered>
                <Modal.Body className="bg_white">
                    <h3>Are You Sure</h3>
                    <p>Are you sure you want to logout?</p>
                    <div className="btn-save">
                        <NavLink to="/" onClick={this.handleLogout}>Yes</NavLink>
                    </div>
                    <div className="cancel_style">
                        <NavLink to="#" onClick={(e) => this.props.handleLogoutModal(e)}>No</NavLink>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            error: state.user.error
        };
    },
    {logout}
)(LogoutPopup);