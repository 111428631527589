import React, {Component} from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";

class Loader extends Component {
    render() {

        return (
            <div className="sweet-loading">
                <PropagateLoader
                    size={15}
                    color={"#2491eb"}
                />
            </div>);
    }
}

Loader.propTypes = {};

export default Loader;
