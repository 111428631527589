import {GENERAL_ACTIONS} from "../actions";

export default function general(state = {}, action) {
    switch (action.type) {

        //contact us
        case GENERAL_ACTIONS.CONTACT_US_REQUEST: {
            return {...state, contactRequest: true, contactFailed: false};
        }
        case GENERAL_ACTIONS.CONTACT_US: {
            return {
                ...state,
                contactRequest: false,
                contactFailed: false,
                contactSucces: true,
                contactSuccessMsg: action.payload.message
            };

        }
        case GENERAL_ACTIONS.CONTACT_US_FAILED: {
            return {...state, contactRequest: false, error: action.error, contactFailed: true};
        }

        //get settings
        case GENERAL_ACTIONS.GET_SETTINGS_REQUEST: {
            return {...state, request: true, failed: false};
        }
        case GENERAL_ACTIONS.GET_SETTINGS: {
            return {
                ...state,
                request: false,
                failed: false,
                settings: action.payload.data.data[0]
            };

        }
        case GENERAL_ACTIONS.GET_SETTINGS_FAILED: {
            return {...state, request: false, error: action.error, failed: true};
        }

        //get page
        case GENERAL_ACTIONS.GET_PAGE_REQUEST: {
            return {...state, request: true, failed: false};
        }
        case GENERAL_ACTIONS.GET_PAGE: {
            return {
                ...state,
                request: false,
                failed: false,
                page: action.payload.data
            };
        }
        case GENERAL_ACTIONS.GET_PAGE_FAILED: {
            return {...state, request: false, error: action.error, failed: true};
        }
        //get business types
        case GENERAL_ACTIONS.GET_BUSINESS_TYPES_REQUEST: {
            return {...state, businessTypeRequest: true, businessTypeFailed: false, businessTypeSuccess: false};
        }
        case GENERAL_ACTIONS.GET_BUSINESS_TYPES: {
            return {
                ...state,
                businessTypeRequest: false,
                businessTypeFailed: false,
                business_types: action.payload.data.data,
                businessTypeSuccess: true
            };

        }
        case GENERAL_ACTIONS.GET_BUSINESS_TYPES_FAILED: {
            return {
                ...state,
                businessTypeRequest: false,
                error: action.error,
                businessTypeFailed: true,
                businessTypeSuccess: false
            };
        }
        //get timezones
        case GENERAL_ACTIONS.GET_TIMEZONES_REQUEST: {
            return {...state, timeZoneRequest: true, timeZoneFailed: false, timeZoneSuccess: false};
        }
        case GENERAL_ACTIONS.GET_TIMEZONES: {
            return {
                ...state,
                timeZoneRequest: false,
                timeZoneFailed: false,
                timezones: action.payload.data.data,
                timeZoneSuccess: true
            };

        }
        case GENERAL_ACTIONS.GET_TIMEZONES_FAILED: {
            return {
                ...state,
                timeZoneRequest: false,
                error: action.error,
                timeZoneFailed: true,
                timeZoneSuccess: false
            };
        }
        //get colors
        case GENERAL_ACTIONS.GET_COLORS_REQUEST: {
            return {...state, colorsRequest: true, colorsFailed: false, colorsSuccess: false};
        }
        case GENERAL_ACTIONS.GET_COLORS: {
            return {
                ...state,
                colorsRequest: false,
                colorsFailed: false,
                colors: action.payload.data.data,
                colorsSuccess: true
            };

        }
        case GENERAL_ACTIONS.GET_COLORS_FAILED: {
            return {
                ...state,
                colorsRequest: false,
                error: action.error,
                colorsFailed: true,
                colorsSuccess: false
            };
        }

        //get all notifications
        case GENERAL_ACTIONS.GET_NOTIFICATIONS_REQUEST: {
            return {...state, notificationsRequest: true, notificationsFailed: false, notificationsSuccess: false};
        }
        case GENERAL_ACTIONS.GET_NOTIFICATIONS: {
            return {
                ...state,
                notificationsRequest: false,
                notificationsFailed: false,
                notifications: action.payload.data,
                notificationsSuccess: true
            };

        }
        case GENERAL_ACTIONS.GET_NOTIFICATIONS_FAILED: {
            return {
                ...state,
                notificationsRequest: false,
                error: action.error,
                notificationsFailed: true,
                notificationsSuccess: false
            };
        }

        //get unread notifications count
        case GENERAL_ACTIONS.GET_NOTIFICATION_UNREAD_REQUEST: {
            return {
                ...state,
                unread_notificationsRequest: true,
                unread_notificationsFailed: false,
                unread_notificationsSuccess: false
            };
        }
        case GENERAL_ACTIONS.GET_NOTIFICATION_UNREAD: {
            return {
                ...state,
                unread_notificationsRequest: false,
                unread_notificationsFailed: false,
                unread_notifications: action.payload.data,
                unread_notificationsSuccess: true
            };

        }
        case GENERAL_ACTIONS.GET_NOTIFICATION_UNREAD_FAILED: {
            return {
                ...state,
                unread_notificationsRequest: false,
                error: action.error,
                unread_notificationsFailed: true,
                unread_notificationsSuccess: false
            };
        }

        //mark all notifications as read
        case GENERAL_ACTIONS.MARK_ALL_READ_REQUEST: {
            return {...state, markAllReadRequest: true, markAllReadFailed: false, markAllReadSuccess: false};
        }
        case GENERAL_ACTIONS.MARK_ALL_READ: {
            return {
                ...state,
                markAllReadRequest: false,
                markAllReadFailed: false,
                mark_all_read: action.payload.data,
                markAllReadSuccess: true
            };

        }
        case GENERAL_ACTIONS.MARK_ALL_READ_FAILED: {
            return {
                ...state,
                markAllReadRequest: false,
                error: action.error,
                markAllReadFailed: true,
                markAllReadSuccess: false
            };
        }

        //mark notification as read
        case GENERAL_ACTIONS.MARK_READ_REQUEST: {
            return {...state, markReadRequest: true, markReadFailed: false, markReadSuccess: false};
        }
        case GENERAL_ACTIONS.MARK_READ: {
            return {
                ...state,
                markReadRequest: false,
                markReadFailed: false,
                mark_read: action.payload.data,
                markReadSuccess: true
            };

        }
        case GENERAL_ACTIONS.MARK_READ_FAILED: {
            return {
                ...state,
                markReadRequest: false,
                error: action.error,
                markReadFailed: true,
                markReadSuccess: false
            };
        }

        //get countries
        case GENERAL_ACTIONS.GET_COUNTRIES_REQUEST: {
            return {...state, countriesRequest: true, countriesFailed: false, countriesSuccess: false};
        }
        case GENERAL_ACTIONS.GET_COUNTRIES: {
            return {
                ...state,
                countriesRequest: false,
                countriesFailed: false,
                countries: action.payload.data.data,
                countriesSuccess: true
            };

        }
        case GENERAL_ACTIONS.GET_COUNTRIES_FAILED: {
            return {
                ...state,
                countriesRequest: false,
                error: action.error,
                countriesFailed: true,
                countriesSuccess: false
            };
        }

        //get states
        case GENERAL_ACTIONS.GET_STATES_REQUEST: {
            return {...state, statesRequest: true, statesFailed: false, statesSuccess: false};
        }
        case GENERAL_ACTIONS.GET_STATES: {
            return {
                ...state,
                statesRequest: false,
                statesFailed: false,
                states: action.payload.data.data,
                statesSuccess: true
            };

        }
        case GENERAL_ACTIONS.GET_STATES_FAILED: {
            return {
                ...state,
                statesRequest: false,
                error: action.error,
                statesFailed: true,
                statesSuccess: false
            };
        }


        //get cities
        case GENERAL_ACTIONS.GET_CITIES_REQUEST: {
            return {...state, citiesRequest: true, citiesFailed: false, citiesSuccess: false};
        }
        case GENERAL_ACTIONS.GET_CITIES: {
            return {
                ...state,
                citiesRequest: false,
                citiesFailed: false,
                cities: action.payload.data.data,
                citiesSuccess: true
            };

        }
        case GENERAL_ACTIONS.GET_CITIES_FAILED: {
            return {
                ...state,
                citiesRequest: false,
                error: action.error,
                citiesFailed: true,
                citiesSuccess: false
            };
        }


        default:
            return state;
    }
}