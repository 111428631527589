import React, {Component} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {NavLink} from "react-router-dom";
import PublishPopup from "../modal/PublishPopup";
import DiscardPopup from "../modal/DiscardPopup";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import {IoMdClose} from "react-icons/io";
import NumberFormat from "react-number-format";
import connect from "react-redux/es/connect/connect";
import {getColors} from "../actions/general";
import Loader from "../components/Loader";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {
    DEFAULT_NOTIFICATION_SETTINGS,
    OFFER_STATUSES,
    OFFER_TYPES,
    VALIDATIONS,
} from "../constants";
import {createOffer} from "../actions/offer";
import {store} from "react-notifications-component";
import {
    getAllNotifications,
    getNotificationUnreadCount,
} from "../actions/general";
import {nextDate} from "../helpers";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 8,
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 8,
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 6,
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 6,
    },
};

class CreateOffer extends Component {
    constructor() {
        super();
        this.state = {
            showHide: false,
            showHide1: false,
            file: null,
            qr_code_file: null,
            type: OFFER_TYPES.AMOUNT,
            redeem: false,
            imageType: false,
            percentageError: false,
            watermark_image_file: null,
        };
        this.BtnClick = this.BtnClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleQRChange = this.handleQRChange.bind(this);
        this.handleWaterMark = this.handleWaterMark.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.setColor = this.setColor.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitFormClick = this.submitFormClick.bind(this);
        this.handleModalShowHide = this.handleModalShowHide.bind(this);
        this.handleModalShowHide1 = this.handleModalShowHide1.bind(this);
        this.handleDraft = this.handleDraft.bind(this);
    }

    setColor(color) {
        this.setState({color: color});
    }

    handleChange(event) {

        if (!event.target.files[0].type.match(/image.*/)) {
            this.setState({imageType: true});
        } else {
            this.setState({
                file: URL.createObjectURL(event.target.files[0]),
                imageType: false,
            });
        }
    }

    handleQRChange(event) {
        if (event.target.files[0]) {
            this.setState({
                qr_code_file: URL.createObjectURL(event.target.files[0]),
            });
        }
    }

    handleWaterMark(event) {
        if (event.target.files[0]) {
            this.setState({
                watermark_image_file: URL.createObjectURL(event.target.files[0]),
            });
        }
    }

    handleRemove(event) {
        this.setState({
            file: null,
        });
    }

    async BtnClick(e) {
        e.preventDefault();
        let textarea = document.getElementById("RedeemMessage");
        if (textarea) {
            textarea.classList.toggle("active");
        }
        let file = document.getElementById("qr_code");
        if (file) {
            file.classList.toggle("active");
        }
    }

    handleModalShowHide() {
        let form = document.getElementById("offer-form");
        if (form.checkValidity()) {
            //working here
            // console.log("on submit Image Type:", this.state.imageType);
            // console.log("on submit watermark:", this.state.watermark_image_file);
            if (!this.state.redeem && this.state.qr_code_file == null) {
                store.removeAllNotifications();
                store.addNotification({
                    ...DEFAULT_NOTIFICATION_SETTINGS,
                    title: "Error",
                    message: "Select either QR code or Redeem message",
                    type: "danger",
                });
                return false;
            }
            /*if (this.state.imageType === false && this.state.watermark_image_file === null) {
                // console.log("in condition Image Type:", this.state.imageType);
                // console.log("in condition watermark:", this.state.watermark_image_file);
                store.removeAllNotifications();
                store.addNotification({
                    ...DEFAULT_NOTIFICATION_SETTINGS,
                    title: "Error",
                    message: "Please select Offer image and Watermark!",
                    type: "danger",
                });
                return false;
            }*/
            if (!this.state.file) {
                store.removeAllNotifications();
                store.addNotification({
                    ...DEFAULT_NOTIFICATION_SETTINGS,
                    title: "Error",
                    message: "Please select Offer image",
                    type: "danger",
                });
                return false;
            }
            this.setState({showHide: !this.state.showHide});
        } else {
            form.reportValidity();
        }
    }


    handleModalShowHide1(status) {
        this.setState({showHide1: !this.state.showHide1});
        if (status) {
            return this.props.history.push("/my-offers");
        }
    }

    submitFormClick() {
        this.handleSubmit(null);
    }

    handleDraft() {
        let form = document.getElementById("offer-form");
        let formData = new FormData(form);
        formData.append("background_color", this.state.color);
        formData.append(
            "status",
            this.state.showHide ? OFFER_STATUSES.PUBLISHED : OFFER_STATUSES.DRAFT
        );
        this.props.createOffer(formData, this.props.user.access.token);
    }

    handleSubmit(e) {
        if (e) {
            e.preventDefault();
        }
        let form = document.getElementById("offer-form");
        let formData = new FormData(form);
        if (form.checkValidity()) {
            /*if (this.state.imageType === false && this.state.watermark_image_file === null) {
                // console.log("in condition Image Type:", this.state.imageType);
                // console.log("in condition watermark:", this.state.watermark_image_file);
                store.removeAllNotifications();
                store.addNotification({
                    ...DEFAULT_NOTIFICATION_SETTINGS,
                    title: "Error",
                    message: "Please select Offer image and Watermark!",
                    type: "danger",
                });
                return false;
            }*/
            if (!this.state.redeem && this.state.qr_code_file === null) {
                store.removeAllNotifications();
                store.addNotification({
                    ...DEFAULT_NOTIFICATION_SETTINGS,
                    title: "Error",
                    message: "Select either QR code or Redeem message",
                    type: "danger",
                });
                return false;
            }
            if (this.state.file == null) {
                store.removeAllNotifications();
                store.addNotification({
                    ...DEFAULT_NOTIFICATION_SETTINGS,
                    title: "Error",
                    message: "Please select Offer image",
                    type: "danger",
                });
                return false;
            }

            // formData.append("background_color", this.state.color);
            // formData.append("background_color", "#1d6cc1");
            formData.append(
                "status",
                this.state.showHide ? OFFER_STATUSES.PUBLISHED : OFFER_STATUSES.DRAFT
            );
            this.props.createOffer(formData, this.props.user.access.token);
            this.setState({showHide: false});
        } else {
            form.reportValidity();
        }
    }

    checkPercentage(event, type) {
        if (type === 20 && event.target.value > 100) {
            this.setState({percentageError: true});
        } else {
            this.setState({percentageError: false});
        }
    }

    componentWillMount() {
        this.props.getColors();
        this.props.getAllNotifications(this.props.user.access.token);
        this.props.getNotificationUnreadCount(this.props.user.access.token);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.colors && nextProps.colors) {
            let colors = nextProps.colors || null;
            if (colors && colors.length > 0) {
                this.setState({color: colors[0].color});
            }
        }
        if (!this.props.createOfferSuccess && nextProps.createOfferSuccess) {
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Success",
                message: nextProps.createOfferSuccessMsg,
                type: "success",
            });
        }
        if (!this.props.createOfferFailed && nextProps.createOfferFailed) {
            store.removeAllNotifications();
            store.addNotification({
                ...DEFAULT_NOTIFICATION_SETTINGS,
                title: "Error",
                message: nextProps.error.message,
                type: "danger",
            });
        }
    }

    render() {
        let colors = this.props.colors || null;
        if (colors == null) {
            return (
                <section className="main_container myoffer">
                    <Container fluid className="bg_color paddingr-0">
                        <Row>
                            <Col md={12} className="m-auto">
                                <Loader/>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
        let today_date = nextDate();
        if (!this.state.color) {
            this.setState({color: colors[0].color});
        }
        let colorElement = colors.map((color, key) => {
            return (
                <div key={key}>
                    <div
                        className={
                            this.state.color == color.color
                                ? "item_color active"
                                : "item_color"
                        }
                        style={{background: color.color}}
                        onClick={() => {
                            this.setColor(color.color);
                        }}
                    >
                        <i className="fas fa-check"></i>
                    </div>
                </div>
            );
        });

        return (
            <section className="main_container myoffer createOfferContainer">
                <ScrollToTopOnMount/>
                <Container fluid className="bg_color paddingr-0">
                    <Row>
                        <Col md={12} className="m-auto">
                            <h2>Create a New Offer</h2>
                            <p className="subtxt">
                                Please fill in the information below to create a new offer.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container
                    fluid
                    className="scroll-container padding-top-20 paddingr-0 create_offer"
                >
                    <Form handleSubmit={this.handleSubmit} id="offer-form">
                        <Row className="pb-5">
                            <Col md={5} lg={5} xl={5} className="col-xxl-4">
                                <div
                                    className={
                                        this.state.file
                                            ? "div-form div-file active"
                                            : "div-form div-file "
                                    }
                                >
                                    <Form.Control
                                        type="file"
                                        className="input_file"
                                        accept="image/png, image/gif, image/jpeg"
                                        onChange={this.handleChange}
                                        name="image"
                                    />
                                    <div className="input_file_img">
                                        <Image
                                            src={require("../../img/file.png")}
                                            alt="File Img"
                                            className="img-fluid"
                                        ></Image>
                                        <p>
                                            Upload Product Image <span>Browse</span>
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        this.state.file ? "bg-watermark bg_upload_file active" : "bg_upload_file"
                                    }
                                    style={{
                                        backgroundColor: this.state.color,
                                        backgroundImage: "url('" + this.state.watermark_image_file + "')"
                                    }}
                                >
                                    <Image
                                        src={this.state.file}
                                        alt="File Img"
                                        className="img-fluid"
                                    ></Image>
                                    <div className="closed_btn" onClick={this.handleRemove}>
                                        <IoMdClose/>
                                    </div>
                                </div>

                                {/*<div
                                    className={
                                        this.state.file ? "color_pickers active" : "color_pickers"
                                    }
                                >
                                    <Carousel
                                        rswipeable={true}
                                        draggable={true}
                                        showDots={false}
                                        responsive={responsive}
                                        ssr={true} // means to render carousel on server-side.
                                        infinite={true}
                                        removeArrowOnDeviceType={["tablet", "dekstop", "mobile"]}
                                    >
                                        {colorElement}
                                    </Carousel>
                                </div>*/}
                                {this.props.createOfferRequest ? (
                                    <Loader/>
                                ) : (
                                    <div>
                                        <div className="btn-plub">
                                            <div onClick={() => this.handleModalShowHide()}>
                                                Publish Offer
                                            </div>
                                        </div>
                                        <div className="two-btn mt-3 discard_style">
                                            <NavLink
                                                to="my-drafts"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.submitFormClick();
                                                }}
                                            >
                                                Save Draft
                                            </NavLink>
                                            <div
                                                className="btn_style"
                                                onClick={() => this.handleModalShowHide1()}
                                            >
                                                Discard Offer
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Col>
                            <Col md={7} lg={7} xl={7} className="col-xxl-7">
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                type="text"
                                                className="input_style"
                                                name="title"
                                                minLength={4}
                                                maxLength={VALIDATIONS.OFFER_NAME}
                                                required
                                            />
                                            <span className="floating-label">Offer Title</span>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <Form.Group className="div-form radio_style">
                                            <div className="form-check">
                                                <Form.Control
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="type"
                                                    value={OFFER_TYPES.AMOUNT}
                                                    checked={
                                                        this.state.type == OFFER_TYPES.AMOUNT ? true : false
                                                    }
                                                    onClick={() => {
                                                        this.setState({type: OFFER_TYPES.AMOUNT});
                                                    }}
                                                />
                                            </div>
                                            <div className="form-check">
                                                <Form.Control
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="type"
                                                    value={OFFER_TYPES.PERCENTAGE}
                                                    checked={
                                                        this.state.type == OFFER_TYPES.PERCENTAGE
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        this.setState({type: OFFER_TYPES.PERCENTAGE});
                                                    }}
                                                />
                                            </div>
                                            <Form.Group className="div-form">
                                                <NumberFormat
                                                    className="input_style form-control"
                                                    decimalSeparator="."
                                                    displayType="input"
                                                    type="text"
                                                    maxLength={
                                                        this.state.type == OFFER_TYPES.AMOUNT ? "11" : "3"
                                                    }
                                                    onChange={(e) =>
                                                        this.checkPercentage(e, this.state.type)
                                                    }
                                                    name="value"
                                                    required
                                                />
                                                <span className="floating-label">
                          Enter Offer&nbsp;
                                                    {this.state.type == OFFER_TYPES.AMOUNT
                                                        ? "Amount"
                                                        : "Percentage"}
                        </span>
                                            </Form.Group>
                                        </Form.Group>
                                    </Col>
                                    {this.state.percentageError && (
                                        <span className="percentage-error">
                      Percentage can not be more than 100%.
                    </span>
                                    )}
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <Form.Control
                                                type="text"
                                                className="input_style"
                                                name="product_name"
                                                required
                                                maxLength={VALIDATIONS.NAME}
                                            />
                                            <span className="floating-label">Product Item</span>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <NumberFormat
                                                format="####"
                                                className="input_style form-control"
                                                name="number_of_uses"
                                                required
                                            />
                                            <span className="floating-label">Number of Uses</span>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form new_csss">
                                            <Form.Control
                                                type="date"
                                                className="input_style"
                                                name="expire_at"
                                                min={today_date}
                                                required
                                            />
                                            <span className="floating-label">Expiration Date</span>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <NumberFormat format="####"
                                                          className="input_style form-control" name="dislike_time"
                                                          required/>
                                            <span className="floating-label">Dislike Counter (hrs)</span>
                                        </Form.Group>
                                    </Col> */}
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <NumberFormat
                                                format="####"
                                                className="input_style form-control"
                                                name="recurrence_time"
                                                required
                                            />
                                            <span className="floating-label">
                        Offer Reoccurrence Time (hrs)
                      </span>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="div-form">
                                            <NumberFormat
                                                format="####"
                                                className="input_style form-control"
                                                name="avail_time"
                                                required
                                            />
                                            <span className="floating-label">
                        Offer Avail Time (hrs)
                      </span>
                                        </Form.Group>
                                    </Col>
                                    {/*<Col xs={12} md={6}>
                                        <Form.Group className="div-form input_files">
                                            <Form.Control
                                                type="file"
                                                accept="image/png, image/gif, image/jpeg"
                                                className="input_file"
                                                name="watermark_image"
                                                onChange={this.handleWaterMark}
                                            />
                                            <div className="inputfile_img">
                                                <p>Upload WaterMark</p>
                                            </div>
                                        </Form.Group>
                                    </Col>*/}
                                    <Col xs={12} md={12} sm={12}>
                                        <div
                                            className="div-form floating-label active"
                                            id="OfferDescription"
                                        >
                        <textarea
                            className="floating-input div-text"
                            name="description"
                            placeholder=" "
                            maxLength={VALIDATIONS.DESCRIPTION}
                        ></textarea>
                                            <span className="highlight"></span>
                                            <label>Offer Description</label>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={5} className="col-xxl-5">
                                        <Form.Group className="div-form input_files">
                                            <Form.Control
                                                type="file"
                                                accept="image/png, image/gif, image/jpeg"
                                                className="input_file"
                                                name="qr_code"
                                                onChange={this.handleQRChange}
                                                onClick={() => {
                                                    this.setState({redeem: false});
                                                }}
                                            />
                                            <div className="inputfile_img">
                                                <p>Upload Your QR Code</p>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        xl={1}
                                        className="or_btn col-xxl-1"
                                    >
                                        <p>or</p>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} xl={5} className="col-xxl-5">
                                        <div
                                            className="div-form input_msg"
                                            onClick={() => {
                                                this.setState({redeem: true});
                                            }}
                                        >
                                            <div>Redeem Message</div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        {!this.state.redeem && this.state.qr_code_file ? (
                                            <div className="qr_code" id="qr_code">
                                                <Image
                                                    src={this.state.qr_code_file}
                                                    alt="File Img"
                                                    className="img-fluid"
                                                ></Image>
                                            </div>
                                        ) : null}
                                        {this.state.redeem ? (
                                            <div
                                                className="div-form floating-label active"
                                                id="RedeemMessage"
                                            >
                        <textarea
                            className="floating-input div-text"
                            name="redeem_message"
                            placeholder=" "
                            maxLength={VALIDATIONS.DESCRIPTION}
                        ></textarea>
                                                <span className="highlight"></span>
                                                <label>Redeem Message</label>
                                            </div>
                                        ) : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <PublishPopup
                        showHide={this.state.showHide}
                        submitFormClick={this.submitFormClick}
                        handleModalShowHide={this.handleModalShowHide}
                    />
                    <DiscardPopup
                        showHide={this.state.showHide1}
                        handleModalShowHide={this.handleModalShowHide1}
                    />
                </Container>
            </section>
        );
    }
}

export default connect(
    (state, ownProps) => {
        return {
            colors: state.general.colors,
            user: state.user,
            createOfferRequest: state.offer.createOfferRequest,
            createOfferFailed: state.offer.createOfferFailed,
            createOfferSuccess: state.offer.createOfferSuccess,
            createOfferSuccessMsg: state.offer.createOfferSuccessMsg,
            error: state.offer.error,
            notifications: state.general.notifications,
            unread_notifications: state.general.unread_notifications,
        };
    },
    {getColors, createOffer, getAllNotifications, getNotificationUnreadCount}
)(CreateOffer);
